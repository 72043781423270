import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container, Row, Col, Hidden } from "react-grid-system";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import mapRating from "../util/mapRating";
import { hydrate } from "../util/person";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  BooleanParam,
  withDefault,
} from "use-query-params";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";

import SearchInput from "../components/SearchInput";
import StateSelector from "../components/StateSelector";
import YearSelector from "../components/YearSelector";
import PartySelector from "../components/PartySelector";
import FederalSelector from "../components/FederalSelector";
import PersonRow from "../components/PersonRow";
import Loading from "../icons/loading";
import Select from "../components/Select";
import ShareBar from "../components/ShareBar";
import Footer from "../components/Footer";
import usePageTitle from "../util/usePageTitle";
import Tooltip from "../components/Tooltip";
import ZipcodeLookup from "../components/ZipcodeLookup";
import { cacheClient } from "../data/client";

import { FixedSizeList as List } from "react-window";
import useDimensions from "../util/useDimensions";


const FIND_PEOPLE_QUERY = gql`
  query findPeople(
    $state: String
    $district: String
    $limit: Int = 10
    $search: String
    $year: Int
    $party: String
    $chamber: String
    $from: String
    $ordering: String
    $direction: String
    $minRating: float8,
    $maxRating: float8
  ) {
    count: ratings_searchPeopleCount2(
      args: {
        search: $search
        year: $year
        chamber: $chamber
        party: $party
        district: $district
        state: $state
        from: $from,
        minRating: $minRating,
        maxRating: $maxRating
      }
    ) {
      count
    }

    ratings_people: ratings_searchPeople2(
      args: {
        search: $search
        year: $year
        chamber: $chamber
        party: $party
        district: $district
        state: $state
        from: $from
        limit: $limit
        ordering: $ordering
        direction: $direction,
        minRating: $minRating,
        maxRating: $maxRating
      }
    ) {
      id
      name
      firstName
      middleName
      lastName
      imageUrl
      thumbnailUrl
      lifetimeRating

      yearsRated: acuRatings_aggregate(where: { rating: { _is_null: false } }) {
        aggregate {
          count
        }
      }

      history(
        where: { year: { _eq: $year } }
        order_by: { year: desc_nulls_last }
        limit: 2
      ) {
        party
        chamber
        district
        year
        from
        state
      }

      acuRatings(
        where: { year: { _lte: $year }, rating: { _is_null: false } }
        order_by: { year: desc_nulls_last }
        limit: 2
      ) {
        rating
        year
      }

      acuLifetimeRatings(
        where: { year: { _lte: $year }, rating: { _is_null: false } }
        order_by: { year: desc_nulls_last }
        limit: 2
      ) {
        rating
        year
      }

      voteCounts(where: { year: { _eq: $year } }) {
        count
        year
      }

      sponsorCounts(where: { year: { _eq: $year } }, limit: 0) {
        count
        year
      }
    }
  }
`;

const Wrapper = styled("div")`
  margin: 0 auto;
  max-width: 1200px;
  h1 {
    font-size: 32px;
    font-family: "Helvetica Neue";
    margin-top: 0;
  }
`;

const Form = styled(Row)`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;

  padding: 16px;
  > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Results = styled("div")`
  max-width: 1200px;
  margin-top: 16px;
  background: white;
  padding: 24px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  h2 {
    margin-top: 0;
  }
`;

const Options = styled("div")`
  margin-left: auto;
  margin-right: auto;

  p:first-child {
    font-weight: bold;
    color: #636e72;
  }
  > p {
    color: #636e72;
    display: inline-block;
    margin-right: 32px;

    span {
      color: #636e72;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const NoResults = styled("div")`
  font-style: italic;
  margin: 36px 20px;
`;

const LoadingWrapper = styled("div")`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  /* padding: 25vh 0 25vh; */
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const LoadMore = styled("div")`
  text-align: center;
  margin-top: 16px;
  margin-bottom: -8px;
  svg {
    margin: 0 auto;
  }
  span {
    text-decoration: underline;
    margin-top: 16px;
    margin-bottom: 16px;
    display: inline-block;
    cursor: pointer;
  }
`;

const FlexRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const sortOptions = [
  {
    label: "Lifetime Rating",
    value: "lifetimeRating",
    ASC: "Low to high",
    DESC: "High to low",
    defaultSort: "DESC",
  },
  {
    label: "Current Rating",
    value: "rating",
    ASC: "Low to high",
    DESC: "High to low",
    defaultSort: "DESC",
  },
  {
    label: "Last Name",
    value: "lastName",
    ASC: "A to Z",
    DESC: "Z to A",
    defaultSort: "ASC",
  },
  {
    label: "Years Rated",
    value: "yearsRated",
    ASC: "Low to high",
    DESC: "High to low",
    defaultSort: "DESC",
  },
  {
    label: "State",
    value: "state",
    ASC: "A to Z",
    DESC: "Z to A",
    defaultSort: "ASC",
  },
];

const SortSelectionWrapper = styled(Select)`
  width: ${(props) => props.width || `180px`};
`;

function SortSelector({ options = [], selected, onSelect }) {
  return (
    <SortSelectionWrapper
      options={options}
      value={selected}
      onChange={onSelect}
      style={{ control: { width: 300 } }}
    />
  );
}

const LOW_TO_HIGH = "ASC";
const HIGH_TO_LOW = "DESC";

const SortGroup = styled("div")`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    margin-right: 16px;
  }
`;

const SortLabel = styled("div")`
  display: inline-block;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`;

const chamberOptions = [
  {
    label: "All chambers",
    value: null,
  },
  {
    label: "House",
    value: "H",
  },
  {
    label: "Senate",
    value: "S",
  },
  {
    label: "Assembly",
    value: "A",
  },
];

const chamberMap = {
  H: "house",
  S: "senate",
  A: "assembly",
};

const limitOptions = [
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "250", value: 250 },
  { label: "500", value: 500 },
  { label: "1000", value: 1000 },
  // { label: "All", value: 999999 },
];

const awardOptions = [
  {
    label: "All awards",
    value: null,
  },
  {
    label: "Excellence",
    value: "excellence",
  },
  {
    label: "Achievement",
    value: "achievement",
  },
  {
    label: "Centrist",
    value: "centrist",
  },
  {
    label: "Radical Left",
    value: "radical-left",
  },
];

function awardToRatings(award, isLifetime=false) {
  switch (award) {
    case 'excellence':
      return { minRating: isLifetime ? 90 : 89.5, maxRating: 100 };
    case 'achievement':
      return { minRating: isLifetime ? 80 : 79.5, maxRating: isLifetime ? 89.9999999999 : 89.499999999 };
    case 'centrist':
    return { minRating: 40, maxRating: 60 };
    case 'radical-left':
      return { minRating: 0, maxRating: 4 };
    default:
      return null;
  }
}

const PeopleHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;

  > h3 {
    &:nth-child(1) {
      margin-left: 20px;
      width: 60px;
      margin-right: 16px;
    }
    &:nth-child(2) {
      @media (max-width: 950px) {
        flex: 1;
      }
      margin-left: 60px;
      width: 30%;
      min-width: 255px;
    }

    &:nth-child(3) {
      @media (max-width: 950px) {
        display: none;
      }
      margin-left: 20px;

      min-width: 100px;
      width: 20%;
      flex: 1;
    }

    &:nth-child(4) {
      @media (max-width: 800px) {
        display: none;
      }
      /* margin-right: 75px; */
      margin-right: 0;
      width: 15%;
    }
  }
`;

const HeaderText = styled("h3")`
  margin: 0;
  text-align: ${(props) => props.align || "left"};
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #2d3436;
`;

function PeopleHeaderRow({ year }) {
  return (
    <PeopleHeader>
      <HeaderText>Rank</HeaderText>

      <HeaderText>Lawmaker Name</HeaderText>

      <HeaderText>{year ? year : "Lifetime"} Votes</HeaderText>

      <HeaderText>{year ? year : "Lifetime"} Rating</HeaderText>
    </PeopleHeader>
  );
}

const Center = styled("div")`
  display: flex;
  justify-content: center;
`;

function getFromAndState({ state, search, level }) {
  // Complex logic to handle the draw from both the federal and state pools
  let fromQ = null; // The origin state
  let stateQ = null; // The origin state if they are a state lawmaker, otherwise 'US' for federal

  if (state == "US") {
    // Only search federal, if they selected that in the main box
    stateQ = "US";
    fromQ = null;
  } else if (search) {
    // If they provided a search term, they might be looking for federal OR state, so look for both
    fromQ = state;

    // If they explicitly specified 'state', limit it to state
    if (level === "state") {
      stateQ = state;

      // If they explicitly specified 'federal', limit it to federal
    } else if (level === "federal") {
      stateQ = "US";

      // Otherwise, search both levels
    } else {
      stateQ = null;
    }

    // They picked a state. Show them state, federal, or both, depending on what they picked
  } else {
    if (level === "state") {
      stateQ = state;
      fromQ = null;
    } else if (level === "federal") {
      stateQ = "US";
      fromQ = state;
    } else if (level === "both" || level == null) {
      stateQ = null;
      fromQ = state;
    }
  }

  return {
    from: fromQ,
    state: stateQ,
  }
}

export default function People(props) {

  const {width, height } = useDimensions();
  const [zipInfo, setZipInfo] = useState(null);
  const [zipcode, setZipcode] = useQueryParam("zipcode", StringParam);
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [state, setState] = useQueryParam("state", StringParam);
  
  // We allow string for year to support using "latest", but we use === elsewhere, so we need numbers as numbers. :)
  let [year, setYear] = useQueryParam("year", StringParam);
  if (!isNaN(+year)) year = +year || null;

  const [party, setParty] = useQueryParam("party", StringParam);
  const [award, setAward] = useQueryParam("award", StringParam);
  const [level, setLevel] = useQueryParam(
    "level",
    withDefault(StringParam, "both")
  );
  const [chamber, setChamber] = useQueryParam("chamber", StringParam);
  const [district, setDistrict] = useQueryParam("district", StringParam);
  const [orderBy, setOrderBy] = useQueryParam(
    "orderBy",
    withDefault(StringParam, "lifetimeRating")
  );
  const [orderByDirection, setOrderByDirection] = useQueryParam(
    "direction",
    withDefault(StringParam, "DESC")
  );

  const [limit, setLimit] = useQueryParam(
    "limit",
    withDefault(NumberParam, 50)
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingZip, setLoadingZip] = useState(false);
  
  const [renderProgress, setRenderProgress] = useState(1);

  usePageTitle("Lawmakers");

  function updateZipInfo(info) {
    if (info) {
      setSearch(null);
      setParty(null);
      setLevel("both");
      setChamber(null);
      setDistrict(null);
      setLimit(500);
      setZipInfo(info);

      // To avoid some weird race condition where it switches back and forth and ultimately back to whatever the value was
      setTimeout(() => setState(info.state), 1);
    } else {
      setZipInfo(null);
    }
  }

  const filteredSortOptions = sortOptions.filter((o) => {
    if (year) {
      if (o.value === "rating") {
        o.label = `${year} rating`;
      }
      return true;
    } else if (o.value === "rating") {
      // Technically not necessary, since it won't display if a year isn't selected
      o.label = "Current rating";
    }
    return o.value != "rating";
  });

  const selectedOrder =
    filteredSortOptions.find((o) => {
      if (!orderBy) return o;
      return o.value == orderBy;
    }) || filteredSortOptions[0];

  function useCacheIfShould() {
    if (
      search
      || district
      || party
    ) {
      return null;
    }

    if (state != 'US' && state != null) {
      return null;
    };

    return cacheClient;
  }

  const { data, loading, error } = useQuery(FIND_PEOPLE_QUERY, {
    variables: {
      limit,
      search: search ? `%${search}%` : null,
      year: year !== 'latest' ? year : null,
      party,
      district: district || null,
      ordering: selectedOrder?.value,
      direction: orderByDirection || null,
      chamber: chamberMap[chamber],
      ...getFromAndState({ state, search, level }),
      ...awardToRatings(award, !year),
    },
    client: useCacheIfShould(),
  });

  // Progressively render this list if we're dealing with 500 or more
  // useEffect(() => {
  //   if (limit < 500) return;

  //   if (loading) {
  //     setRenderProgress(0);
  //   } else if (renderProgress < 1) {
  //     const timeout = setTimeout(() => {
  //       setRenderProgress(renderProgress + .5);
  //     }, 1000);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [loading, renderProgress, setRenderProgress]);

  useEffect(() => {
    setLoadingMore(false);
  }, [search, state, year, party, data]);

  const { ratings_people: people = [], count } = data || {};

  let filteredPeople = people.map(p => ({ ...p, decimal: !year }));
  let filteredCount = count;

  let maxYear = null;
  if (zipInfo) {
    function personHasDistrict(person) {
      return person.history.find(
        (h) =>
          (h.chamber === "senate" && h.state === "US") ||
          ((!year || year == h.year) &&
            h.state === "US" &&
            zipInfo.federalDistrict.includes(h.district)) ||
          (h.state !== "US" && zipInfo.stateDistricts.includes(h.district))
      );
    }

    filteredPeople = filteredPeople.filter(personHasDistrict);

    console.log({ filteredPeopleWithDistrict: filteredPeople, year });

    if (year === 'latest') {
      maxYear = getMaxYear(filteredPeople);
      filteredPeople = filteredPeople.filter(n => n.history.find(h => h.year == maxYear));
    }

    filteredCount = [{ count: filteredPeople.length }];
  }

  useEffect(() => {
    if (zipInfo && maxYear) {
      setYear(maxYear);
    }
  }, [maxYear, zipInfo])

  filteredPeople.forEach((p, i) => {
    const ranking =
      selectedOrder?.defaultSort === orderByDirection
        ? i + 1
        : data.count[0].count - i;
    hydrate(p, year, ranking);
  });

  if (orderByDirection === "DESC") {
    for (let i = 0; i < filteredPeople.length; i++) {
      const p = filteredPeople[i];
      const lastPerson = filteredPeople[i - 1];
      if (areEqual(p, lastPerson)) {
        p.ranking = lastPerson.ranking;
      }
    }
  } else {
    for (let i = filteredPeople.length - 1; i >= 0; i--) {
      const p = filteredPeople[i];
      const lastPerson = filteredPeople[i + 1];
      if (areEqual(p, lastPerson)) {
        p.ranking = lastPerson.ranking;
      }
    }
  }
  // filteredPeople.forEach((p, i) => {
  //   const lastPerson = filteredPeople[i - 1];
  //   if (areEqual(p, lastPerson)) {
  //     p.ranking = lastPerson.ranking;
  //   }
  // });

  function areEqual(personA, personB) {
    if (!personA || !personB) {
      return false;
    }
    return (
      personA.rating === personB.rating //&&
      // Math.round(+personA.lifetimeRating) ===
      // Math.round(+personB.lifetimeRating) &&
      // personA.votes === personB.votes
    );
  }

  function getResultCount() {
    if (loading || loadingZip) return "Loading results";
    if (!filteredCount || !filteredCount.length) return "No results";
    const n = filteredCount[0].count;
    return `${n} result${n === 1 ? "" : "s"}`;
  }

  function renderResults() {
    if ((loading || loadingZip) && !loadingMore) {
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      );
    }

    function Row({ index, style }) {
      return <PersonRow {...filteredPeople[index]} year={year} style={style} />;
    }

    const toRender = filteredPeople || [];
    // const toRender = filteredPeople.slice(0, filteredPeople.length * renderProgress);

    const showDecimal = !!award || !year;

    return (
      <Results>
        <h2>Search results</h2>

        <PeopleHeaderRow year={year} />

        {/* {filteredPeople?.length > 100 &&
          <List
            style={{ marginBottom: 16 }}
            height={height * .75}
            itemCount={filteredPeople.length}
            itemSize={80}
            width={Math.min(width - 160, 1090)}
          >
            {Row}
          </List>
        }

        {filteredPeople?.length && filteredPeople?.length <= 100 &&
          filteredPeople.map(p => <PersonRow {...p} year={year} />)
        } */}


        { filteredPeople?.length > 0 &&
          toRender.map(p => <PersonRow key={p.id} {...p} year={year} decimal={showDecimal} />)
        }

        {!(filteredPeople && filteredPeople.length) && (
          <NoResults>No results</NoResults>
        )}

        <Center>
          <SortLabel>
            Show:
            <SortSelectionWrapper
              width={150}
              options={limitOptions}
              value={limitOptions.find((o) => o.value === limit)}
              onChange={(o) => setLimit(o.value)}
            />
          </SortLabel>
        </Center>
      </Results>
    );
  }

  const orderByOptions = [HIGH_TO_LOW, LOW_TO_HIGH].map((key) => ({
    label: selectedOrder[key],
    value: key,
  }));
  const selectedOption = orderByOptions.find(
    (o) => o.value === orderByDirection
  );
  const isState = state != null && state !== "US";

  return (
    <>
      <Wrapper>
        <ScrollToTopOnMount />
        <FlexRow>
          <h1>Lawmakers</h1>
          <ShareBar snippet="Check out these legislator's CPAC ratings" />
        </FlexRow>
        <Form>
          <SearchInput
            placeholder="John Smith"
            value={search}
            onValue={setSearch}
            debounce={500}
            width={150}
          />
          <SearchInput
            placeholder="District"
            value={district}
            onValue={setDistrict}
            debounce={500}
            width={66}
            hideSearchIcon
          />
          <StateSelector value={state} onChange={setState} />
          <PartySelector value={party} onChange={setParty} />
          <Select
            placeholder="All chambers"
            value={chamberOptions.find((c) => !chamber || chamber === c.value)}
            options={chamberOptions}
            width={150}
            onChange={(c) => setChamber(c.value)}
          />
          <Select
            placeholder="Award"
            value={awardOptions.find((a) => !award || award === a.value)}
            options={awardOptions}
            width={150}
            onChange={(a) => setAward(a.value)}
          />
          <YearSelector value={year} onChange={setYear} />
        </Form>
        <Options>
          <SortGroup>
            <SortLabel>
              <p>{getResultCount()}</p>
            </SortLabel>

            <SortLabel>
              Sort by:
              {/* <SortSelector options={sortOptions.filter(o => isFiltering || o.name != 'Years Rated')} selected={filteredOrderBy} onSelect={setOrderBy} /> */}
              <SortSelector
                options={filteredSortOptions}
                selected={selectedOrder}
                onSelect={(o) => setOrderBy(o.value)}
              />
            </SortLabel>

            <SortLabel>
              Order by:
              <SortSelectionWrapper
                width={150}
                options={orderByOptions}
                // value={{ label: orderByOption[direction], value: direction }}
                value={selectedOption}
                onChange={(o) => setOrderByDirection(o.value)}
              />
            </SortLabel>

            <SortLabel>
              Show:
              <SortSelectionWrapper
                width={100}
                options={limitOptions}
                value={limitOptions.find((o) => o.value === limit)}
                onChange={(o) => setLimit(o.value)}
              />
            </SortLabel>

            {isState && (
              <Tooltip
                title="Looking for federal lawmakers only?"
                showFirst={true}
              >
                <FederalSelector value={level} onChange={setLevel} />
              </Tooltip>
            )}

            <ZipcodeLookup
              value={zipcode}
              onChange={setZipcode}
              onResults={updateZipInfo}
              width={100}
              onLoading={setLoadingZip}
            />
          </SortGroup>
        </Options>
        {renderResults()}
      </Wrapper>
      <Footer />
    </>
  );
}

function getLifetimeRating(person) {
  const acuRating = (person.acuLifetimeRatings || [])[0];

  if (acuRating) {
    return mapRating(acuRating.rating, true);
  }
  return mapRating(person.lifetimeRating);
}

function getMaxYear(people) {
  let maxYear = null;
  for (const person of people) {
    for (const history of person.history) {
      maxYear = maxYear ? Math.max(history.year, maxYear) : history.year;
    }
  }
  return maxYear;
}
