import React, { useEffect, useState } from "react";
import Select from "react-select";
import styled from "styled-components";

import states from "../util/stateList.json";

const Wrapper = styled(Select)`
  width: 180px;

  > div {
    border-radius: 0;
    border-color: #bdbdbd;
  }

  div {
    border-color: #bdbdbd;
  }
`;

function last(arry) {
  return arry[arry.length - 1];
}

function getInitialOption(value) {
  if (typeof value === "string") {
    return states.find((s) => s.value === value) || states[0];
  }
  return states[0];
}

// TODO: Handle value from props
export default function StateSelector(props) {
  const [state, setState] = useState(getInitialOption(props.value));

  useEffect(() => {
    if (props.value !== state) {
      setState(getInitialOption(props.value));
    }
  }, [props.value]);

  function onChange(newState) {
    const { value } = newState || states[0];

    if (props.onChange) {
      props.onChange(value);
    }
    setState(newState || states[0]);
  }

  return (
    <Wrapper
      {...props}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      options={states}
      simpleValue
      clearable={false}
      name="selected-state"
      value={{ ...state, value: state.value || '' }}
      onChange={onChange}
      disabled={true}
    />
  );
}
