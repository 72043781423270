import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import gql from 'graphql-tag';
import { useQuery } from "@apollo/client";
import mapRating from '../../util/mapRating';
import CloseIcon from '../../icons/close';

import { getRole } from '../../util/person';
import formatDistrict from '../../util/formatDistrictName';
import toPostgresArray from '../../util/toPostgresArray';
import threshold from '../../util/threshold';

import Loading from '../../icons/loading';
import ProfileImage from '../../components/ProfileImage';


const GET_PERFORMANCE_QUERY = gql`
query getComparisonDetail(
  $id: String!
  $issues: _text
) {

  ratings_people(limit: 1, where: { id: { _eq: $id } }) {
    id
    firstName
    lastName
    thumbnailUrl
    history {
      chamber
      year
      from
      state
      party
      year
    }
    acuLifetimeRatings(limit: 1, where: { rating: { _is_null: false } }, order_by: { year: desc_nulls_last }) {
      rating
    }
  }

  performance: ratings_getPersonPerformance(args: {
    peopleId: $id,
    categories: $issues
  }) {
    peopleId
    rating
    year
    acuVotes
    votes
    total
  }

}
`;



const Column = styled('div')`
  /* max-width: 20%; */
  flex: 1;
  background-color: white;
  margin: 10px;

  &:first-child {
    margin-left: 0;
  }


  &:last-child {
    margin-right: 0;
  }

  padding: 16px 8px 0;

  &>p {
    padding: 0 16px;
    color: #636E72;
  }
`;

const RepColumn = styled(Column)``;

const BlankColumn = styled('div')`
  padding: 24px 16px 0;
  flex: 1;
`;

const ColumnLabel = styled(Column)`
  padding: 24px 8px 0;
  background: none;
  padding-top: 100px;
  min-width: 140px;
  /* min-width: 140px; */
  flex-grow: 0;

  &>p {
    /* text-align: right; */
    /* border: 1px solid red; */
    padding: 25px 0;
    margin: 0;
    color: #2D3436;
  }
`;

const Separator = styled('hr')`
  border: 1px solid #F0F3F5;
  margin: ${props => `${props.padding || 24}px 0`};
`;

const RepColumnsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`;


// Make it a component with a non-fixed parent, that keeps the size of the original...?
const RepColumnHeaderWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  margin: 0 8px;

  p {
    margin: 0;

    &:first-child {
      margin-bottom: 8px;
      color: #2D3436;
      /* white-space: nowrap; */
    }

    &:last-child {
      color: #636E72;
    }
  }

  position: relative;

  svg {
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
    background-color: white;
    border-radius: 100%;
    z-index: 0;
  }

  >span {
    display: block;
    position: absolute;
    top: -13px;
    right: -13px;
    cursor: pointer;
    background-color: white;
    border-radius: 100%;
    z-index: 0;
    width: 22px;
    height: 22px;
    text-align: center;
    line-height: 19px;
    font-size: 18px;
  }
`;

const ProfileImageFlush = styled(ProfileImage).attrs(props => ({ style: { cursor: props.pointerOnPicture ? 'pointer' : 'default', } }))`
  margin-left: 0;
`;

// TODO: Pull this out and re-use
const LoadingWrapper = styled('div')`
  height: ${props => props.height}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

function LoadingBlock({ height }) {
  return (
    <LoadingWrapper height={height}>
      <Loading />
    </LoadingWrapper>
  )
}

function RepColumHeader(props) {

  const {
    firstName,
    lastName,
    name,
    state,
    body,
    party,
    thumbnailUrl,
    hideClose,
    lifetimeRating,
    rating,
    pointerOnPicture,
  } = props;

  // TODO: Use earliest history is year is before the person's time
  const history = ((props.history || []).find(h => !props.year || h.year === props.year) || props.history[0] || {});

  const role = getRole(props, props.year);

  let chamber = '';
  try {
    chamber = history.chamber[0].toUpperCase() + history.chamber.slice(1);
  } catch (e) {
    chamber = '';
  }

  const district = formatDistrict(history.district, false);

  const first = (firstName + lastName).length > 16 ? `` : firstName;

  return (
    <RepColumnHeaderWrapper onClick={props.onClick} className={props.className}>
      {/* <RepImage image={imageUrl} /> */}
      <ProfileImageFlush src={thumbnailUrl} rating={rating || lifetimeRating} pointerOnPicture />
      <div>
        <p><Link to={`/people/${props.id}`}>{role}. {first} {lastName} ({history.party})</Link></p>
        <p>{history.state} {chamber} {history.state === 'US' ? `– ${history.from} ` : ''}{district ? `– ${district}` : ''}</p>
      </div>
      {!hideClose && <CloseIcon onClick={props.onClose} />}
    </RepColumnHeaderWrapper>
  )
}

const RepRating = styled('p').attrs(props => ({ style: { bold: props.bold ? 900 : 'normal' }}))``;



// TODO: Extract and re-use
function useLawmakerData({ id, year, issues }) {
  const { data, loading, error } = useQuery(GET_PERFORMANCE_QUERY, {
    variables: {
      id,
      issues: toPostgresArray(issues),
    }
  });

  if (loading || error) {
    return {
      data,
      loading,
      error,
    };
  }

  const current = (data.performance.find(r => !year || r.year === year) || {});

  let {
    rating,
    votes = 'n/a',
    acuVotes,
  } = current;

  if (current.year) {
    const history = (data.ratings_people[0].history || []).find(h => h.year === current.year) || {};
    const meets = threshold(current.votes / current.total, history.state, history.chamber || history.role, current.year, id);
    if (!meets) {
      rating = 'n/a';
    }
  }

  const {
    allTimeRatingSum,
    allTimeRatingCount,
    allTimeAcuVotes,
    allTimeVotes,
    // allTimeTotal,
  } = data.performance.reduce((acc, n) => {

    const history = (data.ratings_people[0].history || []).find(h => h.year === n.year) || {};
    const meets = threshold(n.votes / n.total, history.state, history.chamber || history.role, n.year, id);

    if (meets) {
      acc.allTimeRatingSum += (n.rating || 0);
      acc.allTimeRatingCount++;
    }

    acc.allTimeAcuVotes += (n.acuVotes || 0);
    acc.allTimeVotes += (n.votes || 0);
    acc.allTimeTotal += (n.total || 0);
    return acc;
  }, {
    allTimeRatingSum: 0,
    allTimeRatingCount: 0,
    allTimeAcuVotes: 0,
    allTimeVotes: 0,
    allTimeTotal: 0,
  });

  // TODO: Combine yearly ratings if no issues are selected, don't pool all votes
  let allTimeRating = null;
  if (issues?.length) {
    allTimeRating = 100 * allTimeAcuVotes / allTimeVotes;
  } else {
    allTimeRating = allTimeRatingSum / allTimeRatingCount;
  }

  const lifetimeRating = (data?.ratings_people[0].acuLifetimeRatings[0] || {}).rating;

  return {

    // TODO: Add year-appropriate history here (grab all then find nearest)
    data: {

      // TODO: Use standard clamping function
      rating: mapRatingOrNA(rating),
      votes,
      acuVotes,
      allTimeVotes,
      // TODO: Use standard clamping function
      allTimeRating: mapRatingOrNA(allTimeRating, !issues.length),
      lifetimeRating,
      maxes: {},
      ...data?.ratings_people[0],
    },
    loading,
    error,
  };
}

function mapRatingOrNA(rating, long = false) {
  if (isNaN(+rating)) {
    return 'n/a';
  }

  if (long) {

    const fixed = (+rating).toFixed(2);

    switch (fixed) {
      case '100.00':
      case '0.00':
        return fixed.split('.')[0];
      default:
        return fixed;
    }
  }

  return Math.round(rating);
}


export default function RepDataColumn(props) {

  const { data, loading, error } = useLawmakerData(props);

  if (error) {
    return (
      <RepColumn>
        <p>Error: {error.message || JSON.stringify(error)}</p>
      </RepColumn>
    )
  }

  if (loading || !data) {
    return (
      <RepColumn>
        <LoadingBlock height={300} />
      </RepColumn>
    );
  }

  let {
    rating,
    votes,
    allTimeRating,
    allTimeVotes,
    lifetimeRating,
    maxes,
  } = data;

  const formattedRating = rating != 'n/a' ? `${rating}%` : rating;
  const formattedAllTimeRating = allTimeRating != 'n/a' ? `${allTimeRating}%` : allTimeRating;

  return (
    <RepColumn>

      <RepColumHeader {...data} onClose={props.onClose} rating={lifetimeRating} />

      <Separator />
      <RepRating bold={rating == maxes.rating}>{formattedRating}</RepRating>

      <Separator />
      <RepRating bold={votes == maxes.votes}>{votes}</RepRating>

      <Separator />
      <RepRating bold={allTimeRating == maxes.allTimeRating}>{formattedAllTimeRating}</RepRating>

      <Separator />
      <RepRating bold={allTimeVotes == maxes.allTimeVotes}>{allTimeVotes}</RepRating>

    </RepColumn>
  );
}
