import React from 'react';
import styled from 'styled-components';

import ArrowImage from '../../images/arrow.svg';
import RatingLegendImage from '../../images/rating-legend.svg';
import StateMap from './StateMap';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Loading from './Loading';

const GET_IMPORTANT_RIGHT_NOW = gql`
query {
  ratings_importantRightNow {
    id
    title
    description
    date
    url
  }
}
`;

const Link = styled('a')`
  text-decoration: none;
`;

const Card = styled('div')`
  background-color: white;
  padding: 16px;
  p { 
    
  }
`;

const Wrapper = styled('div')`
  padding: 16px;
  background-color: white;
`;

const ImportantRightNowWrapper = styled('div')`
  padding: 16px;
  background-color: white;

  h3 {
    margin-top: 0;
  }
`;

const ImportantItemWrapper = styled('div')`
  display: flex;
  flex-direction: row;

  &>div {
    margin-right: 16px;
  }

  h5 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #2D3436;
    margin: 0 0 8px;
  }

  p {
    color: #636E72;
    line-height: 1.25;
    margin: 0;
  }
`;

export default function ImportantRightNow(props) {

  const { data, loading, error } = useQuery(GET_IMPORTANT_RIGHT_NOW);

  if (error) {
    return (
      <ImportantRightNowWrapper>
        <h3>Important Right Now</h3>
        <em>Important issues unavailable right now. Please check again later.</em>
      </ImportantRightNowWrapper>
    );
  }

  if (loading || !data) {
    return (
      <ImportantRightNowWrapper>
        <h3>Important Right Now</h3>
        <Loading />
      </ImportantRightNowWrapper>
    );
  }


  const items = (data.ratings_importantRightNow || []).map(ImportantItem);

  function ImportantItem({ title, description, url, date }) {
    return (
      <Card>
        <Link href={url} target="_blank">
          <ImportantItemWrapper>
            <div>
              <h5>{title}</h5>
              <p>{new Date(date).toLocaleDateString()} – {description}</p>
            </div>
            <img src={ArrowImage} />
          </ImportantItemWrapper>
        </Link>
      </Card>
    );
  }

  return (
    <ImportantRightNowWrapper>
      <h3>Important Right Now</h3>
      {items}
    </ImportantRightNowWrapper>
  );
}
