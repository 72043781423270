import React, { useState, useEffect } from "react";
import { geoCentroid } from "d3-geo";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
  ZoomableGroup,
} from "react-simple-maps";
import StateBorder from './StateBorder';
import { scaleLinear } from "d3-scale";
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";
import geo from './geographies.json';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Link, withRouter } from 'react-router-dom';

import Loading from './Loading';
import colorScale from '../util/colorScale';
import useStateRatings from "../data/useStateRatings";



const stateName = [
  { "id": "AL", "val": "01", "name": "Alabama" },
  { "id": "AK", "val": "02", "name": "Alaska" },
  { "id": "AS", "val": "60", "name": "American Samoa" },
  { "id": "AZ", "val": "04", "name": "" },
  { "id": "AR", "val": "05", "name": "" },
  { "id": "CA", "val": "06", "name": "" },
  { "id": "CO", "val": "08", "name": "" },
  { "id": "CT", "val": "09", "name": "" },
  { "id": "DE", "val": "10", "name": "" },
  { "id": "DC", "val": "11", "name": "" },
  { "id": "FL", "val": "12", "name": "" },
  { "id": "FM", "val": "64", "name": "" },
  { "id": "GA", "val": "13", "name": "" },
  { "id": "GU", "val": "66", "name": "" },
  { "id": "HI", "val": "15", "name": "" },
  { "id": "ID", "val": "16", "name": "" },
  { "id": "IL", "val": "17", "name": "" },
  { "id": "IN", "val": "18", "name": "" },
  { "id": "IA", "val": "19", "name": "" },
  { "id": "KS", "val": "20", "name": "" },
  { "id": "KY", "val": "21", "name": "" },
  { "id": "LA", "val": "22", "name": "" },
  { "id": "ME", "val": "23", "name": "" },
  { "id": "MH", "val": "68", "name": "" },
  { "id": "MD", "val": "24", "name": "" },
  { "id": "MA", "val": "25", "name": "" },
  { "id": "MI", "val": "26", "name": "" },
  { "id": "MN", "val": "27", "name": "" },
  { "id": "MS", "val": "28", "name": "" },
  { "id": "MO", "val": "29", "name": "" },
  { "id": "MT", "val": "30", "name": "" },
  { "id": "NE", "val": "31", "name": "" },
  { "id": "NV", "val": "32", "name": "" },
  { "id": "NH", "val": "33", "name": "" },
  { "id": "NJ", "val": "34", "name": "" },
  { "id": "NM", "val": "35", "name": "" },
  { "id": "NY", "val": "36", "name": "" },
  { "id": "NC", "val": "37", "name": "" },
  { "id": "ND", "val": "38", "name": "" },
  { "id": "MP", "val": "69", "name": "" },
  { "id": "OH", "val": "39", "name": "" },
  { "id": "OK", "val": "40", "name": "" },
  { "id": "OR", "val": "41", "name": "" },
  { "id": "PW", "val": "70", "name": "" },
  { "id": "PA", "val": "42", "name": "" },
  { "id": "PR", "val": "72", "name": "" },
  { "id": "RI", "val": "44", "name": "" },
  { "id": "SC", "val": "45", "name": "" },
  { "id": "SD", "val": "46", "name": "" },
  { "id": "TN", "val": "47", "name": "" },
  { "id": "TX", "val": "48", "name": "" },
  { "id": "UM", "val": "74", "name": "" },
  { "id": "UT", "val": "49", "name": "" },
  { "id": "VT", "val": "50", "name": "" },
  { "id": "VA", "val": "51", "name": "" },
  { "id": "VI", "val": "78", "name": "" },
  { "id": "WA", "val": "53", "name": "" },
  { "id": "WV", "val": "54", "name": "" },
  { "id": "WI", "val": "55", "name": "" },
  { "id": "WY", "val": "56", "name": "" }
];

const offsets = {
  VT: [50, -12],
  NH: [34, 6],
  MA: [30, -1],
  RI: [28, 10],
  CT: [35, 25],
  NJ: [34, 15],
  DE: [33, 15],
  MD: [47, 35],
  DC: [49, 60],
};

const centroidOffsets = {
  FL: [.7, 0],
  MI: [.4, -1.2],
  LA: [-.7, 0],
  ID: [0, -1],
  CA: [-.7, 0],
  KY: [.6, -.2],
  TN: [0, -.2],
  NY: [.4, -.2],
  OH: [-.2, -.3],
  IA: [-.1, -.2],
  MN: [-.3, 0],
  NE: [0, -.2],
  KS: [0, -.1],
  TX: [.2, 0],
  WA: [.4, -.2],
  WV: [-.2, -.3],
  VA: [.5, 0],
};


const Container = styled('div')`
  position: relative;
  flex: 1;
`;


const LoadingPanel = styled(Loading)`
  flex: 1;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const GeographyWithOpacity = styled(Geography)`

  opacity: ${props => props.fade ? .2 : 1};

  &:hover {
    opacity: ${props => props.fade ? .88 : .6667};
  }
`;

const Tooltip = styled('div')`

  h4 {
    color: white !important;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    position: relative;

    span {
      color: white;
      font-size: 16px;
      font-weight: 800;

      &:first-of-type {
        font-size: 10px;
        font-weight: 100;
        position: relative;
        top: -2px;
        padding: 0 3px;
      }
    }
  }

  p {
    font-style: italic;
    color: white !important;
    margin: 4px 0;
    white-space: nowrap;

    span {
      color: white;
      padding-right: 2px;
    }
  }

  div {
    div:first-child {
      margin-right: 6px;
    }
    div:last-child {
      margin-left: 6px;
    }
  }
`;



const MapChart = withRouter((props) => {

  const [tooltipContent, setTooltipContent] = useState(null);

  const { year, issues, group} = props;
  const { data, loading, error } = useStateRatings({ year, issues, group, useCache: props.useCache });

  const stateRatings = data || {};

  function selectState(state) {
    if (props.onSelect) {
      props.onSelect(state);
    } else {
      props.history.push(`/states/${state}`);
    }
  }

  function handleClick(state, e) {
    if ((e.metaKey || e.ctrlKey) && props.onAmendSelect) {
      props.onAmendSelect(state);

    } else if (props.onSelect) {
      props.onSelect(state);
    } else {
      props.history.push(`/states/${state}`);
    }
  }




  // if (loading) {
  //   return <LoadingPanel />
  // }

  function getRatingForState(state) {
    const rating = stateRatings[state];
    if (rating) {
      return rating;
    }
    return {};
  }

  function getRatingText(state, name) {
    const { rating, supported, opposed, senate, house, assembly, outcome, votes } = stateRatings[state] || {};
    if (typeof rating === 'number') {
      const conservativeScore = (100 * outcome / votes).toFixed();
      return (
        <Tooltip>
          <h4>{name} <span>&bull;</span> <span>{rating.toFixed(0)}%</span></h4>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            { notNull(senate, house, assembly) && <div style={{ flex: 1 }}>
              {senate && <p><span>{state == 'NE' ? 'Unicameral' : 'Senate'}: {senate}%</span></p>}
              {house && <p><span>House: {house}%</span></p>}
              {assembly && <p><span>Assembly: {assembly}%</span></p>}
            </div> }
            <div style={{ flex: 1 }}>
              <p><span>{supported}</span> supported</p>
              <p><span>{opposed}</span> opposed</p>
            </div>
          </div>
          { !isNaN(conservativeScore) && <p><span>{conservativeScore}</span>% conservative outcomes</p>}
        </Tooltip>
      );
    }
    return (
      <Tooltip>
        <h4>{name}</h4>
        <p>no data</p>
      </Tooltip>
    );
  }

  function getColor(rating) {
    if (rating === '' || rating == null) {
      // return '#f0f0f0';
      // return '#e0e0e0';
      return '#DFE6E9';
    }
    return colorScale(rating);
  }

  function clearTooltip() {
    setTooltipContent(null);
  }

  function shouldFade(abbr) {
    if (!props.states) {
      return false;
    }

    if (getRatingForState(abbr) === '') {
      return false;
    }

    if (props.states.find(s => s.value === null || s.abbr === abbr)) {
      return false;
    }

    return true;
  }

  function renderGeography(geo) {
    const { abbr, name } = geo.properties;
    const rating = getRatingForState(abbr);
    const ratingText = getRatingText(abbr, name);
    const fade = shouldFade(abbr);
    let conservativeScore = 100 * rating.outcome / rating.votes;
    if (isNaN(conservativeScore)) {
      conservativeScore = null;
    }
    return (
      // <Link to={`/states/${abbr}`}>
      <GeographyWithOpacity
        fade={fade || undefined}
        key={geo.rsmKey}
        geography={geo}
        fill={getColor(rating.rating)}
        // fill={getColor(conservativeScore)}
        onMouseEnter={() => setTooltipContent(ratingText)}
        onMouseLeave={clearTooltip}
        onClick={(e) => handleClick(abbr, e)}
      />
      // </Link>
    );
  }

  function renderAnnotation(geo) {
    const centroid = geoCentroid(geo);
    const cur = stateName.find(s => s.val === geo.id);

    const centroidOffset = centroidOffsets[cur.id] || [0, 0];
    centroid[0] += centroidOffset[0];
    centroid[1] += centroidOffset[1];

    if (!cur || centroid[0] <= -160 || centroid[0] > -67 || !offsets[cur.id]) {
      return <g key={geo.rsmKey + "-name"} />
    }

    const offset = offsets[cur.id] || [0, 0];
    const ratingText = getRatingText(cur.id);

    return (
      <g key={geo.rsmKey + "-name"}>
        {/* <Link to={`/states/${cur.id}`}> */}
        <Annotation
          subject={centroid}
          dx={offset[0]}
          dy={offset[1]}
          onMouseEnter={() => setTooltipContent(ratingText)}
          onMouseLeave={clearTooltip}
          onClick={() => selectState(cur.id)}
        >
          <text x={4} fontSize={16} alignmentBaseline="middle" fillOpacity={.667} fontWeight="900">{cur.id}</text>
        </Annotation>
        {/* </Link> */}
      </g>
    );
  }

  function renderGeographies({ geographies }) {
    return (
      <>
        {geographies.map(renderGeography)}
        {geographies.map(renderAnnotation)}
      </>
    );
  }

  return (
    <Container>
      <ComposableMap projection="geoAlbersUsa" className={props.className} data-tip="" data-for="state-map-tooltip" projectionConfig={{ scale: 1000 }} height={540}>
        {/* <ZoomableGroup center={[-96, 38]}> */}
        <Geographies geography={geo}>
          {renderGeographies}
        </Geographies>
        {/* </ZoomableGroup> */}
      </ComposableMap>
      <ReactTooltip id="state-map-tooltip">{tooltipContent}</ReactTooltip>
      {loading && <LoadingPanel />}
    </Container>
  );
});

export default styled(MapChart)`
  path[role="geography"]{
    outline: none;
    cursor: pointer;
    stroke: white;

    &:hover {
      /* fill-opacity: .667; */
    }
  }

  text {
    user-select: none;
    /* pointer-events: none; */
    cursor: pointer;
  }
`;

function notNull(...args) {
  for (const arg of args) {
    if (arg !== null || typeof arg !== 'undefined') {
      return true;
    };
  }
  return false;
}