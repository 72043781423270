import * as d3 from 'd3';

// const color = d3.scaleLinear().range(["#0984E3", "#B5B5B5", "#D63031"]);
// color.domain([0, 50, 100]);
export const colors = ['#0984e3', '#cc4748'];

const colorScale = d3.scaleLinear()
  .domain([0, 100])
  .range(colors);

const scale = n => Math.min(100, Math.max(0, n / .6 - 20));


export default num => colorScale(scale(num));