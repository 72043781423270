import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg width="53" height="53" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg" {...props} fill="#CC4748">
    <path d="M26.5 0C11.8658 0 0 11.8658 0 26.5C0 41.1342 11.8658 53 26.5 53C41.1342 53 53 41.1342 53 26.5C53 11.8658 41.1342 0 26.5 0ZM37.9459 17.8461L25.4885 35.1184C25.3144 35.3614 25.0849 35.5594 24.8189 35.696C24.553 35.8327 24.2584 35.9039 23.9594 35.9039C23.6605 35.9039 23.3658 35.8327 23.0999 35.696C22.834 35.5594 22.6045 35.3614 22.4304 35.1184L15.0541 24.897C14.8294 24.5835 15.0541 24.1458 15.4386 24.1458H18.2128C18.8162 24.1458 19.39 24.4356 19.7449 24.9325L23.9565 30.7767L33.2551 17.8816C33.61 17.3906 34.1779 17.0949 34.7872 17.0949H37.5614C37.9459 17.0949 38.1707 17.5326 37.9459 17.8461Z" fill="#CC4748" />
  </Svg>

);
