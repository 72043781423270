import React from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { QueryParamProvider } from "use-query-params";

import client from "./data/client";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Landing from "./pages/Landing";
import IssuesPage from "./pages/Issues/index";
import PersonPage from "./pages/Person";
import PeopleListPage from "./pages/People";
import BillsPage from "./pages/Bills";
import BillPage from "./pages/Bill";
import StatesPage from "./pages/States";
import StatePage from "./pages/State";
import ComparisonPage from "./pages/Comparison/index";
import AboutPage from "./pages/About";
import AwardsPage from "./pages/AwardPage";

import useBackground from "./util/useBackground";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* max-width: 1000px; */
  margin: 0 auto;
  background-color: #f7f9fa;
  /* padding: 0 16px; */
`;

const Content = styled("div")`
  flex: 1;
  position: relative;
  padding: 36px 48px;

  .box-shadow {
    border-radius: 24px;
    box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 6%);
  }
`;

export default (props) => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Container>
            <Header />

            <Content>
              <Switch>
                <Route path="/" exact>
                  <Landing key="landing" />
                </Route>

                <Route path="/people/:id" exact>
                  <PersonPage key="person-page" />
                </Route>

                <Route path="/people" exact>
                  <PeopleListPage key="people-list-page" />
                </Route>

                <Route path="/bills/:id" exact>
                  <BillPage key="bill-detail-page" />
                </Route>

                <Route path="/bills" exact>
                  <BillsPage key="bills-list-page" />
                </Route>

                <Route path="/issues">
                  <IssuesPage key="issues-page" />
                </Route>

                <Route path="/congress">
                  <StatePage key="state-page" federal={true} />
                </Route>

                <Route path="/states/:id">
                  <StatePage key="state-page" />
                </Route>

                <Route path="/states">
                  <StatesPage key="states-page" />
                </Route>

                <Route path="/comparison">
                  <ComparisonPage key="comparison-page" />
                </Route>

                <Route path="/awards">
                  <AwardsPage key="awards-page" />
                </Route>
              </Switch>
            </Content>
          </Container>
        </QueryParamProvider>
      </Router>
    </ApolloProvider>
  );
};
