import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Input from './Input';


const InputWithClearWrapper = styled('div')`
  position: relative;

  input {
    width: 100%;
    box-sizing: border-box;
  }

  span {
    color: #999999;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 12px;
    line-height: 11px;
  }
`;


export default function ClearableInput(props) {

  const [value, setValue] = useState(props.value || '');

  useEffect(() => {
    if (typeof props.value !== 'undefined') {
      setValue(props.value || '');
    }
  }, [props.value]);

  const onChange = e => {
    const { value: val } = e.target;
    setValue(val);
    props.onChange && props.onChange(e);
  };

  const onClick = () => {
    props.onChange && props.onChange({ target: { value: '' } });
    setValue('');
  }

  return (
    <InputWithClearWrapper>
      <Input {...props} value={value} onChange={onChange} />
      {value ? <span onClick={onClick}>x</span> : null}
    </InputWithClearWrapper>
  );
}
