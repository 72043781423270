import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useQueryParam, NumberParam, StringParam, withDefault } from 'use-query-params';
import Tooltip from 'react-tooltip';


import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import PersonOverview from '../components/PersonOverview';
import PersonPerformance from '../components/PersonPerformance';
import BillsList from '../components/BillsList';
import YearSelector from '../components/YearSelector';
import ShareBar from '../components/ShareBar';
import Link from '../components/Link';
import LeftChevron from '../icons/left-chevron';
import CompareIcon from '../icons/compare';
import Footer from '../components/Footer';
import { getRole } from '../util/person';
import usePageTitle from '../util/usePageTitle';

import ExcellenceAward from '../../images/award-excellence.png';
import AchievementAward from '../../images/award-achievement.png';

const Award = styled('img')`
  height: 42px;
  margin-top: -10px;
  margin-bottom: -7px;
  margin-left: 12px;
`;


const GET_PERSON_QUERY = gql`
query getPersonQuery(
  $id: String!
) {
  ratings_people(where: { id: { _eq: $id }}) {
    id
    name
    role

    history {
      year
      chamber
      district
      state
    }

    years: votes(distinct_on: year, order_by: { year: desc_nulls_last }) {
      label: year,
      value: year,
    }

    acuRatings(limit: 1, order_by: { year: desc_nulls_last }) {
      rating,
      year
    }
  }
}
`;

const Wrapper = styled('div')`
  /* margin: auto; */
  max-width: 1200px;
  margin: 0 auto;
`;

const Name = styled('h1')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #2D3436;
  margin: 24px 0 32px 0;
`;

const Separator = styled('div')`
  height: 24px;
`;

const BackLink = styled(Link)`
  color: #2D3436;
  font-size: 16px;
  display: inline-block;
  
  svg {
    margin-bottom: -1px;
    margin-right: 2px;
  }
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  >div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 24px 0 32px 0;
    /* width: 120px; */

    >a {
      margin-bottom: 3px;
      text-decoration: underline;
    }

    >* {
      margin-left: 16px;
    }
  }
`;

const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CompareButton = styled(Link)`
  font-size: 16px;
  background-color: #D63031;
  color: white;
  text-decoration: none !important;
  margin: 0 !important;
  padding: 8px 16px 10px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: 2px;
  }
`;

const ErrorBox = styled('div')`
`;

function getAward(person) {
  if (!person || !person.acuRatings) {
    return null;
  }

  const {rating, year} = person?.acuRatings[0] || {};

  if (typeof rating !== 'number') {
    return null;
  }

  if (rating >= 89.5) {
    return <Award
      src={ExcellenceAward}
      data-tip={`${year} Award for Conservative Excellence: 90% or above rating`}
      data-for="award-tooltip"
    />;

  } else if (rating >= 79.5) {
    return <Award
      src={AchievementAward}
      data-tip={`${year} Award for Conservative Achievement: 80% to 89% rating`}
      data-for="award-tooltip"
    />;
  }
  return null;
}

export default withRouter(function Person(props) {

  const { id } = props.match.params;
  // const [year, setYear] = useState(null);
  const [year, setYear] = useQueryParam('year', NumberParam);
  const [selectedIssues, setSelectedIssues] = useState([]);

  const { data, loading, error } = useQuery(GET_PERSON_QUERY, {
    variables: { id }
  });

  const { ratings_people: [person] } = data || { ratings_people: [{}] };

  if (!person.name) {
    usePageTitle('Lawmaker Detail');
  } else {
    usePageTitle(`${getRole(person, year)}. ${person.name}`);
  }

  useEffect(() => {
    Tooltip.rebuild();
  });

  if (loading && !person) {
    return (
      <Wrapper>
        <p>Loading...</p>
      </Wrapper>
    )
  }

  if (!person) {
    return (
      <Wrapper>
        <TitleWrapper>
          <Name>Person not found</Name>
        </TitleWrapper>
        <ErrorBox>
          <p>We couldn't locate the selected lawmaker.</p>
          <p>ID: {id}</p>
        </ErrorBox>

        <Footer pin />

      </Wrapper>
    )
  }

  const role = getRole(person, year);
  const title = person.name ? `${role}. ${person.name}` : ''
  return (
    <Wrapper>

      <ScrollToTopOnMount />

      {/* TODO: Replace with left chevron */}
      <FlexRow>
        <BackLink href="/people"><LeftChevron fill="#2D3436" /> Back to search results</BackLink>
        <ShareBar snippet={`Check out ${role}. ${person.name}'s rating from the CPAC`} />
      </FlexRow>

      <TitleWrapper>
        <Name>{title} {getAward(person)}</Name>
        <div>

          <CompareButton href={`/comparison?reps=${person.id}`}><CompareIcon fill="white" height="20" /> Compare to other lawmakers</CompareButton>
          <YearSelector onChange={setYear} years={person.years} value={year} />
        </div>
      </TitleWrapper>

      <PersonOverview id={id} year={year} onSelectIssues={setSelectedIssues} />
      <Separator />
      <PersonPerformance id={id} year={year} onYearSelect={setYear} onSelectIssues={setSelectedIssues} issues={selectedIssues} />
      <Separator />

      <BillsList title="Bill activity" year={year} peopleId={id} issues={selectedIssues} />

      <Footer />

      <Tooltip id="award-tooltip" multiline />
    </Wrapper>
  )
});
