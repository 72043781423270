import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { onError } from "@apollo/client/link/error";


console.log({
  mainHost: `Using host: ${process.env.HASURA_HOST}`,
  cacheHost: `Using cache host: ${process.env.CACHE_HOST}`,
  hostname: location.hostname,
  NODE_ENV: process.env.NODE_ENV,
});

const cache = new InMemoryCache();

const mainLink = new BatchHttpLink({
  uri: `${process.env.HASURA_HOST}/v1/graphql`,
  headers: {
    'X-Hasura-User-Id': '-1',
    'X-Hasura-Role': 'anonymous',
  },
});

export default new ApolloClient({
  cache,
  link: mainLink,
});

const cacheLink = new HttpLink({
  uri: `${process.env.CACHE_HOST}/v1/graphql`,
  headers: {
    'X-Hasura-User-Id': -1,
    'X-Hasura-Role': 'anonymous',
  }
});

const cacheDownLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  // Probably want to log these to 
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.warn(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  // Fallback to main endpoint if the cache is down or broken
  // @ts-ignore
  if (networkError?.statusCode === 502) {
    cacheClient.setLink(mainLink);
    return forward(operation);
  }
});

export const cacheClient = new ApolloClient({
  link: from([cacheDownLink, cacheLink]),
  cache, // Same cache
});




