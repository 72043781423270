import React from 'react';
import styled from 'styled-components';
import ReactTooltip from "react-tooltip";
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Visible, Hidden } from 'react-grid-system';
import { useQuery } from '@apollo/client';

import ArrowImage from '../../images/arrow.svg';
import RatingLegendImage from '../../images/rating-legend.svg';
import Alert from '../components/Alert';
import MapWidget from '../components/Map';
import ImportantRightNow from '../components/ImportantRightNow';
import Leaderboard from '../components/Leaderboard';
import IssuesToWatch from '../components/IssuesToWatch';
import Footer from '../components/Footer';
import StateBorder from '../components/StateBorder';
import MapLegend from '../components/MapLegend';
import LandingFeature from '../components/LandingFeature';
import usePageTitle from '../util/usePageTitle';
import FindLegislatorsByZip from '../components/FindLegislatorsByZip';


const GET_STATE_QUERY = gql`
query (
  $state: String!
  $year: Int
) {
  
  lifetimeRating: ratings_stateRatings_aggregate(
    where: { state: { _eq: $state }, year: { _eq: $year }  }
  ) {
    aggregate {
      avg {
        rating
      }
      sum {
        total
      }
    }
  }
}
`;


const Wrapper = styled(Container)`
  margin: 0 auto !important;
  max-width: 1600px !important;
  padding: 0 !important;
  /* margin-left: 0 !important; */
`;

const Tagline = styled('h1')`
  margin-top: -12px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: normal;
  /* font-style: italic; */
  background-color: white;
  padding: 24px;
  text-align: center;
`;

const CapitolWrapper = styled('div')`
  position: relative;
  background-color: white;
  padding: 24px;
  padding-top: 6px;
  padding-bottom: 39px;
  margin-bottom: 24px;

  object {
    margin-bottom: 12px;
  }
`;

const Spacer = styled('div')`
  margin: 24px;
`;

const Disclaimer = styled('p')`
  color: #B2BEC3;
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 12px;
`;

const CapitolClick = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: pointer;
`;


const Tooltip = styled('div')`
  
  h4 {
    color: white !important;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    position: relative;

    span {
      color: white;
      font-size: 16px;
      font-weight: 800;

      &:first-of-type {
        font-size: 10px;
        font-weight: 100;
        position: relative;
        top: -2px;
        padding: 0 3px;
      }
    }
  }

  p {
    font-style: italic;
    color: white !important;
    margin: 4px 0;

    span {
      color: white;
      padding-right: 2px;
    }
  }
`;


function useFederalRating(year) {
  const { data, loading, error } = useQuery(GET_STATE_QUERY, {
    variables: {
      state: 'US',
      year,
    },
  });

  try {
    // return data.lifetimeRating.aggregate.avg.rating;
    return {
      rating: data.lifetimeRating.aggregate.avg.rating,
      total: data.lifetimeRating.aggregate.sum.total,
    }
  } catch (e) {
    return null;
  }
}


function FunctionalTooltip(props) {
  const id = '' + (Math.random() * 10e10 | 0);
  return (
    <div>
      <ReactTooltip id={id}>
        <p>{props.content}</p>
      </ReactTooltip>
      <div data-tip data-for={id}>
        {props.children}
      </div>
    </div>
  )
}

function getFederalRatingText(rating) {

  if (rating) {

    const supported = Math.floor((rating.total * rating.rating) / 100);
    const opposed = rating.total - supported;

    return (
      <Tooltip data-for="congress-tooltip">
        <h4>Congress <span>&bull;</span> <span>{ rating && rating.rating && rating.rating.toFixed(0)}%</span></h4>
        <p><span>{supported}</span> supported</p>
        <p><span>{opposed}</span> opposed</p>
      </Tooltip>
    );
  }
  return (
    <Tooltip data-for="congress-tooltip">
      <h4>Congress</h4>
      <p>no data</p>
    </Tooltip>
  );
}

export default props => {

  // TODO: Determine latest year from DB?
  const year = 2019;

  const history = useHistory();
  const federalRating = useFederalRating(year);

  usePageTitle('Overview');

  return (
    <Wrapper>

      {/* <Alert /> */}
      <FindLegislatorsByZip />


      {/* Two-column layout for large screens */}

      <Row>
        <Col lg={6}>

          <FunctionalTooltip content={getFederalRatingText(federalRating)}>
            <CapitolWrapper className="box-shadow">
              <h3>U.S. Congress</h3>
              <StateBorder state="US" rating={federalRating?.rating} />
              <MapLegend />
              <Disclaimer>*Indicates an average of voting records of members within legislative bodies</Disclaimer>
              <CapitolClick onClick={() => history.push(`/congress`)} />
              { }
              {/* <ReactTooltip id="congress-tooltip"> */}
              {/* {getFederalRatingText(federalRating)} */}
              {/* </ReactTooltip> */}
            </CapitolWrapper>
          </FunctionalTooltip>

        </Col>
        <Col>

          <MapWidget useCache={true} year={year} />
          {/* <Spacer />

            <Row>
              <Col xl={12}>
                <ImportantRightNow />
                <Spacer />
              </Col>
              <Col xl={12}>
                <IssuesToWatch limit={6} />
              </Col>
          </Row> */}

        </Col>
      </Row>

      <Footer width="1600" />

    </Wrapper >
  )
};