import AK from './AK.svg';
import AL from './AL.svg';
import AZ from './AZ.svg';
import AR from './AR.svg';
import CA from './CA.svg';
import CO from './CO.svg';
import CT from './CT.svg';
import DE from './DE.svg';
import FL from './FL.svg';
import GA from './GA.svg';
import HI from './HI.svg';
import ID from './ID.svg';
import IL from './IL.svg';
import IN from './IN.svg';
import IA from './IA.svg';
import KS from './KS.svg';
import KY from './KY.svg';
import LA from './LA.svg';
import ME from './ME.svg';
import MD from './MD.svg';
import MA from './MA.svg';
import MI from './MI.svg';
import MN from './MN.svg';
import MS from './MS.svg';
import MO from './MO.svg';
import MT from './MT.svg';
import NE from './NE.svg';
import NV from './NV.svg';
import NH from './NH.svg';
import NJ from './NJ.svg';
import NM from './NM.svg';
import NY from './NY.svg';
import NC from './NC.svg';
import ND from './ND.svg';
import OH from './OH.svg';
import OK from './OK.svg';
import OR from './OR.svg';
import PA from './PA.svg';
import RI from './RI.svg';
import SC from './SC.svg';
import SD from './SD.svg';
import TN from './TN.svg';
import TX from './TX.svg';
import UT from './UT.svg';
import US from './US.svg';
import VT from './VT.svg';
import VA from './VA.svg';
import WA from './WA.svg';
import WV from './WV.svg';
import WI from './WI.svg';
import WY from './WY.svg';

export default {
  AK,
  AL,
  AZ,
  AR,
  CA,
  CO,
  CT,
  DE,
  FL,
  GA,
  HI,
  ID,
  IL,
  IN,
  IA,
  KS,
  KY,
  LA,
  ME,
  MD,
  MA,
  MI,
  MN,
  MS,
  MO,
  MT,
  NE,
  NV,
  NH,
  NJ,
  NM,
  NY,
  NC,
  ND,
  OH,
  OK,
  OR,
  PA,
  RI,
  SC,
  SD,
  TN,
  TX,
  UT,
  US,
  VT,
  VA,
  WA,
  WV,
  WI,
  WY,
}

