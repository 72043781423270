import mapRating from './mapRating';

/**
 * @typedef Person
 * @property {string}   name
 * @property {string}   firstName
 * @property {string}   lastName
 * @property {string}   role
 * @property {History[]} history
 */

/**
 * @typedef History
 * @property {string} state
 * @property {string} from
 * @property {string} party
 * @property {string} chamber
 * @property {number} year
 */

/**
 * 
 * @param {Person} person 
 * @param {number} year
 * @returns {History}
 */
export function getHistory(person, year) {
  if (!person || !person.history) return {};

  return person.history.reduce((acc, n) => {
    if (!acc) {
      return n;
    }

    if (n.year === year) {
      return n;
    }

    if (acc.year === year) {
      return acc;
    }

    if (n.year > acc.year) {
      return n;
    }

    return acc;

  }, null);
}


const delegateStates = {
  VA: 'Del',
};

const assemblyStates = {
  NJ: 'Asm',
};


export function getRole(person, year) {

  if (!person || !person.history) {
    return '';
  }

  const history = getHistory(person, year);
  
  if (!history) {
    return '';
  }
  
  switch ((history.chamber || '').toLowerCase()) {
    case 'senate':
      return 'Sen';
    case 'assembly':
      return 'Asm';

    // TODO: Add delegates
    case 'house':
      return delegateStates[history.state] || assemblyStates[history.state] || 'Rep';
    case '':
      return person.role || '';
  }
}

/**
 * 
 * @param {Person} person 
 * @returns {string}
 */
export function getLifetimeRating(person) {
  const acuRating = (person.acuLifetimeRatings || [])[0];

  if (acuRating) {
    return mapRating(acuRating.rating, true);
  }
  return mapRating(person.lifetimeRating);
}


export function hydrate(person, year, ranking,) {

  person.includeCompare = true;

  if (person?.history?.length) {
    Object.assign(person, person.history[0]);
  }

  // TODO(Joshua): Add years rated and years of service to the people model
  if (person?.yearsRated?.aggregate) {
    person.yearsRated = person.yearsRated.aggregate.count || null;
  }

  if (ranking) {
    person.ranking = ranking;
  }

  person.lifetimeRating = getLifetimeRating(person);

  if (year) {
    person.rating = mapRating((person.acuRatings.find(r => r.year === year) || {}).rating, person.decimal);
    person.ratingDetail = person.rating ? `${year} rating` : null;

  } else {
    person.rating = mapRating(person.lifetimeRating, true);
    person.ratingDetail = `Lifetime rating`;
    person.decimal = true;
  }
  
  // TODO: Resolve discrepancy
  if (!person.ratings && person.acuRatings) {
    person.ratings = person.acuRatings;
  }

  if (year && person.ratings.length > 1) {
    const [, prev] = person.ratings;
    let difference = person.rating - prev?.rating;

    if (!isNaN(difference)) {

      if (difference == 0) {
        person.ratingDetail = `No change from ${prev.year}`;
      } else {
        const isFraction = Math.abs(difference) < 1;
        const sign = difference >= 0 ? '+' : '';
        const val = difference.toFixed(isFraction ? 2 : 0);
        person.ratingDetail = `${sign}${val} from ${prev.year}`;
      }
    }
  }

  person.role = getRole(person, year);

  person.votes = person.voteCounts.reduce((acc, n) => acc + n.count, 0);
  person.sponsorships = person.sponsorCounts.reduce((acc, n) => acc + n.count, 0);
}