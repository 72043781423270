import axios from 'axios';

const HASURA_HOST = process.env.HASURA_HOST;
const HASURA_KEY = process.env.HASURA_KEY;

export default async function query(sql) {
  const response = await axios({
    url: `${HASURA_HOST}/v1/query`,
    method: 'POST',
    headers: {
      // 'x-hasura-admin-secret': HASURA_KEY,
      'X-Hasura-User-Id': -1,
      'X-Hasura-Role': 'anonymous',
    },
    data: {
      "type": "bulk",
      "args": [{
        "type": "run_sql",
        "args": {
          sql,
          "cascade": false,
          "read_only": true
        }
      }], 
    }
  });

  // console.log('query data', response.data);

  const { data: [{ result }] } = response;

  const [headers, ...rows] = result;

  

  return rows.reduce((acc, cols) => {
    const row = {};
    headers.forEach((header, i) => {

      const value = cols[i];

      if (value === 't') {
        row[header] = true;
      } else if (value === 'f') {
        row[header] = false;
      } else if (value === 'NULL') {
        row[header] = null;
      } else if (isNaN(+value)) {
        row[header] = value;
      } else {
        row[header] = +value;
      }
    });
    acc.push(row);
    return acc;
  }, []);
}