export default function getDistrict(district, includeLabel=true) {
  if (district === null || typeof district === 'undefined' || district === 'undefined' || district == '') {
    return null;
  }
  if (!isNaN(+district)) {
    return `${district}${getOrdinal(district)}${includeLabel ? ' District' : ''}`;
  }
  return `${includeLabel ? 'District ' : ''} ${district}`;
}

function getOrdinal(district) {
  const num = +district;
  if (num > 9 && num < 20) {
    return 'th';
  }
  
  const lastDigit = (''+district).slice(-1);
  switch (lastDigit) {
    case '0':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
      return 'th';
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return '';
  }
}