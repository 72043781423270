module.exports = [
  {
    "id": "53",
    "abbr": "All",
    "name": "States & Federal",
    "value": null
  },
  {
    "id": "51",
    "abbr": "US",
    "name": "Federal",
    "value": "US"
  },
  {
    "id": "1",
    "abbr": "AL",
    "name": "Alabama",
    "value": "AL"
  },
  {
    "id": "2",
    "abbr": "AK",
    "name": "Alaska",
    "value": "AK"
  },
  {
    "id": "3",
    "abbr": "AZ",
    "name": "Arizona",
    "value": "AZ"
  },
  {
    "id": "4",
    "abbr": "AR",
    "name": "Arkansas",
    "value": "AR"
  },
  {
    "id": "5",
    "abbr": "CA",
    "name": "California",
    "value": "CA"
  },
  {
    "id": "6",
    "abbr": "CO",
    "name": "Colorado",
    "value": "CO"
  },
  {
    "id": "7",
    "abbr": "CT",
    "name": "Connecticut",
    "value": "CT"
  },
  {
    "id": "8",
    "abbr": "DE",
    "name": "Delaware",
    "value": "DE"
  },
  {
    "id": "9",
    "abbr": "FL",
    "name": "Florida",
    "value": "FL"
  },
  {
    "id": "10",
    "abbr": "GA",
    "name": "Georgia",
    "value": "GA"
  },
  {
    "id": "11",
    "abbr": "HI",
    "name": "Hawaii",
    "value": "HI"
  },
  {
    "id": "12",
    "abbr": "ID",
    "name": "Idaho",
    "value": "ID"
  },
  {
    "id": "13",
    "abbr": "IL",
    "name": "Illinois",
    "value": "IL"
  },
  {
    "id": "14",
    "abbr": "IN",
    "name": "Indiana",
    "value": "IN"
  },
  {
    "id": "15",
    "abbr": "IA",
    "name": "Iowa",
    "value": "IA"
  },
  {
    "id": "16",
    "abbr": "KS",
    "name": "Kansas",
    "value": "KS"
  },
  {
    "id": "17",
    "abbr": "KY",
    "name": "Kentucky",
    "value": "KY"
  },
  {
    "id": "18",
    "abbr": "LA",
    "name": "Louisiana",
    "value": "LA"
  },
  {
    "id": "19",
    "abbr": "ME",
    "name": "Maine",
    "value": "ME"
  },
  {
    "id": "20",
    "abbr": "MD",
    "name": "Maryland",
    "value": "MD"
  },
  {
    "id": "21",
    "abbr": "MA",
    "name": "Massachusetts",
    "value": "MA"
  },
  {
    "id": "22",
    "abbr": "MI",
    "name": "Michigan",
    "value": "MI"
  },
  {
    "id": "23",
    "abbr": "MN",
    "name": "Minnesota",
    "value": "MN"
  },
  {
    "id": "24",
    "abbr": "MS",
    "name": "Mississippi",
    "value": "MS"
  },
  {
    "id": "25",
    "abbr": "MO",
    "name": "Missouri",
    "value": "MO"
  },
  {
    "id": "26",
    "abbr": "MT",
    "name": "Montana",
    "value": "MT"
  },
  {
    "id": "27",
    "abbr": "NE",
    "name": "Nebraska",
    "value": "NE"
  },
  {
    "id": "28",
    "abbr": "NV",
    "name": "Nevada",
    "value": "NV"
  },
  {
    "id": "29",
    "abbr": "NH",
    "name": "New Hampshire",
    "value": "NH"
  },
  {
    "id": "30",
    "abbr": "NJ",
    "name": "New Jersey",
    "value": "NJ"
  },
  {
    "id": "31",
    "abbr": "NM",
    "name": "New Mexico",
    "value": "NM"
  },
  {
    "id": "32",
    "abbr": "NY",
    "name": "New York",
    "value": "NY"
  },
  {
    "id": "33",
    "abbr": "NC",
    "name": "North Carolina",
    "value": "NC"
  },
  {
    "id": "34",
    "abbr": "ND",
    "name": "North Dakota",
    "value": "ND"
  },
  {
    "id": "35",
    "abbr": "OH",
    "name": "Ohio",
    "value": "OH"
  },
  {
    "id": "36",
    "abbr": "OK",
    "name": "Oklahoma",
    "value": "OK"
  },
  {
    "id": "37",
    "abbr": "OR",
    "name": "Oregon",
    "value": "OR"
  },
  {
    "id": "38",
    "abbr": "PA",
    "name": "Pennsylvania",
    "value": "PA"
  },
  {
    "id": "39",
    "abbr": "RI",
    "name": "Rhode Island",
    "value": "RI"
  },
  {
    "id": "40",
    "abbr": "SC",
    "name": "South Carolina",
    "value": "SC"
  },
  {
    "id": "41",
    "abbr": "SD",
    "name": "South Dakota",
    "value": "SD"
  },
  {
    "id": "42",
    "abbr": "TN",
    "name": "Tennessee",
    "value": "TN"
  },
  {
    "id": "43",
    "abbr": "TX",
    "name": "Texas",
    "value": "TX"
  },
  {
    "id": "44",
    "abbr": "UT",
    "name": "Utah",
    "value": "UT"
  },
  {
    "id": "45",
    "abbr": "VT",
    "name": "Vermont",
    "value": "VT"
  },
  {
    "id": "46",
    "abbr": "VA",
    "name": "Virginia",
    "value": "VA"
  },
  {
    "id": "47",
    "abbr": "WA",
    "name": "Washington",
    "value": "WA"
  },
  {
    "id": "48",
    "abbr": "WV",
    "name": "West Virginia",
    "value": "WV"
  },
  {
    "id": "49",
    "abbr": "WI",
    "name": "Wisconsin",
    "value": "WI"
  },
  {
    "id": "50",
    "abbr": "WY",
    "name": "Wyoming",
    "value": "WY"
  }
];