import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import Twitter from '../../images/twitter.svg';
import Facebook from '../../images/facebook.svg';
import Email from '../../images/email.svg';
import LinkedIn from '../../images/linkedin.svg';
import CopyIcon from '../../images/copy.svg';
import CopiedIcon from '../../images/copied.svg';


const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const levels = {
  1: 'rgba(152, 27, 27, 1);',
  2: 'rgba(184, 46, 46, 1);',
  3: 'rgba(202, 73, 73, 1);',
  4: 'rgba(221, 95, 95, 1);',
};

const IconWrapper = styled('a')`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: ${props => levels[props.level] || levels[1]};
  padding: 6px;
  display: flex;
  box-sizing: border-box;
  
  div {
    flex: 1;
    background-image: ${props => `url('${props['src']}')`};
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
`;

function Icon(props) {
  return (
    <IconWrapper {...props}>
      <div />
    </IconWrapper>
  );
}

const Url = styled('input')`
  font-size: 16px;
  padding: 6px;
  margin: 4px 8px;
  border: none;
  /* font-family: EB Garamond; */
`;

const Copy = styled('a')`
  margin-top: 3px;
  display: inline-block;
  cursor: pointer;
  img {
    height: 30px;
  }
`;


const copyToClipboard = () => document.execCommand('copy');

function openLink(url, service, width=300, height=400) {
  return () => {
    const { height: windowHeight, width: windowWidth } = window.screen;
    
    const top = (windowHeight - height) / 2;
    const left = (windowWidth - width) / 2;
    
    const title = service ? `Share on ${service}` : 'Share';
    
    window.open(url, title, `noopener=true,noreferrer=true,width=${width},height=${height},left=${left},top=${top}`);
    // window.open(url, title, `noopener=true,noreferrer=true,width=400,height=${windowHeight},centerscreen=1,chrome=yes`);
  };
}

export default function StyleBar({ type, id, snippet='Check out these CPAC ratings' }) {
  const inputRef = useRef();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => setCopied(false), 5000);
  }, [copied]);

  const url = window.location.href;
  // const url = `http://54.186.40.78:1337/stories/${type}/${id}`;
  const encodedUrl = encodeURIComponent(url);
  const encodedSnippet = encodeURIComponent(snippet);

  const twitterUrl = `https://twitter.com/intent/tweet?ref_src=twsrc%5Etfw&text=${encodedSnippet}&tw_p=tweetbutton&url=${encodedUrl}&via=CPAC`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=${encodedUrl}&display=popup&ref=plugin&src=share_button`;
  const emailUrl = `mailto:?subject=${snippet}&body=${encodeURIComponent(snippet + '\n' + url)}`;
  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=&summary=${encodedSnippet}&source=`;

  function selectInput() {
    inputRef.current.select();
  }
    
  function copyUrl() {
    inputRef.current.select();
    copyToClipboard();
    setCopied(true);
    inputRef.current.blur();
  }  

  return (
    <Container>
      <Icon src={Twitter} level="1" title="Share this story on Twitter" onClick={openLink(twitterUrl, 'Twitter', 400, 230)} target="_blank" />
      <Icon src={Facebook} level="2"  title="Share this story on FaceBook" onClick={openLink(facebookUrl, 'Facebook', 550, 500)} target="_blank" />
      <Icon src={LinkedIn} level="3" title="Share via LinkedIn" onClick={openLink(linkedInUrl, 'LinkedIn')}/>
      <Icon src={Email} level="4" title="Share via Email" onClick={openLink(emailUrl, 'Email', 800, 600)} />
      <Url value={url} onFocus={selectInput} onMouseUp={selectInput} onClick={selectInput} ref={inputRef} onChange={()=>{}} />
      <Copy onClick={copyUrl}>
        <img src={copied ? CopiedIcon : CopyIcon } />
      </Copy>
    </Container>
  );
}