import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';





export default styled((props) => {

  const style = {
    // margin: 0,
    // background: '#fff',
    display: 'block',
    width: props.size || 50,
    height: props.size || 50,
    opacity: .5,
  };

  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={style} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="32" strokeWidth="5" stroke="#636e72" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round" transform="rotate(28.7287 50 50)">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
    </Svg>
  );
})``;
