import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Search from '../icons/search';
import Cross from '../icons/cross';


const Wrapper = styled('div')`
  position: relative;
  width: ${props => props.width || 300}px;

  svg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: 9px;
    pointer-events: none;
  }
`;

const Input = styled('input')`
  padding: 9px 8px 7px 10px;
  border: 1px solid #BDBDBD;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #444444;
  width: 100%;
  box-sizing: border-box;
`;

const CrossIcon = styled(Cross)`
  height: 16px;
  width: 16px;
  padding: 11px;
  cursor: pointer;
  pointer-events: inherit !important;
  margin: 0 !important;
`;

// export default Input;

export default function SearchInput(props) {

  const [value, setValue] = useState(props.value || '');
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {

    clearTimeout(timeoutId);

    const timeout = setTimeout(() => {

      if (value !== props.value) {
        // Set to null to get useQueryParam to remove the empty param
        props.onValue && props.onValue(value || null);
        props.onChange && props.onChange(value || null);
      }
    }, props.debounce || 100);

    setTimeoutId(timeout);

  }, [value]);

  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value])

  function clearInput() {
    setValue('');
  }

  return (
    <Wrapper className={props.className} style={props.style} width={props.width}>
      <Input
        placeholder={props.placeholder}
        value={value || ''}
        onChange={e => setValue(e.target.value)}
        autoFocus={!!props.autoFocus}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyDown={props.onKeyDown}
      />
      {value ? <CrossIcon onClick={clearInput} fill="#BDBDBD" /> : props.hideSearchIcon ? null : <Search fill="#BDBDBD" />}
    </Wrapper>
  );
};