import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Wrapper = styled(Select)`
  width: ${props => props.fullWidth ? 'auto' : '120px'};
  z-index: 100;

  > div {
    border-radius: 0;
    border-color: #BDBDBD;
  }
  
  div {
    border-color: #BDBDBD;
  }
`;


// const currentYear = new Date().getFullYear();
const currentYear = 2022;

const allYears = [{
  label: 'All years',
  value: ''
}];

// TODO: Make this static, back to 1971
for (let i = 0; currentYear - i > 1970; i++) {
  allYears.push({
    label: '' + (currentYear - i),
    value: '' + (currentYear - i)
  });
}


// const years = [{
//   label: 'All Years',
//   value: null
// },{
//   label: '2018',
//   value: 2018
// },{
//   label: '2017',
//   value: 2017
// }];

function getInitialValue(value, years) {
  if (typeof value === 'number' || typeof value === 'string') {
    return years.find(y => y.value == value) || years[0] || null;
  }
  return years[0] || null;
}

function getYears(props) {
  if (Array.isArray(props.years)) {
    return [allYears[0], ...props.years];
  }
  return allYears;
}

export default function YearSelector(props) {

  const years = getYears(props);

  const initialValue = getInitialValue(props.value, years);
  const ref = useRef(initialValue.value || null);
  const [year, setYear] = useState(initialValue);

  useEffect(() => {
    const value = (year || years[0]).value || null;

    // Need to make sure we don't send a new value out if it's not different
    // On a lot of pages, we set the history based on this, so we could be
    // screwing up the back button
    if (props.onChange && ref.current != value) {

      let timeout;
      timeout = setTimeout(() => {

        ref.current = value;
        props.onChange(value);

      }, 1);

      return () => clearTimeout(timeout);
    }
  }, [year]);

  // Seems to not cause a loop, which is nice. (It's use in the rating graph,
  // which seemed like it'd cause a loop...
  useEffect(() => {
    if (props.value !== year) {
      setYear(getInitialValue(props.value, years));
    }
  }, [props.value]);

  function onChange(year) {
    setYear(year || years[0]);
  }

  return (
    <Wrapper
      {...props}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      options={years}
      simpleValue
      clearable={false}
      name="selected-year"
      value={year}
      onChange={onChange}
    />
  );
}
