export default function mapRating(rating, long=false) {
  if (rating == null || typeof rating === 'undefined' || isNaN(+rating)) {
    return null;
  }

  if (long) {
    return parseFloat(rating).toFixed(2);
  }
  
  return Math.round(rating);

  if (rating === null || isNaN(+rating)) {
    return null;
  }
  return Math.round((rating + 1) * 50);
};