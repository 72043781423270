import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import ReactTooltip from "react-tooltip";

import mapRating from "../util/mapRating";
import formatDistrictName from "../util/formatDistrictName";

import Link from "./Link";
import RatingBar from "./RatingBar";
import PDFLink from "./PDFLink";
import LoadingBlock from "./LoadingBlock";
import RatingWidget from "./RatingWidget";
import ScrollableContainer from "./ScrollableContainer";
import stateAbbrMap from "../util/stateAbbrMap";
import partyMap from "../util/partyMap";
import { getRole } from "../util/person";

import colorScale from "../util/colorScale";
import { groupsById } from "../data/getIssues";
import { COLORS, STRONGEST_WEAKEST_THRESHOLDS } from "../../constants.json";
import threshold from "../util/threshold";

const GET_PERSON_QUERY = gql`
  query personOverviewQuery($id: String!, $year: Int) {
    ratings_people(where: { id: { _eq: $id } }) {
      name
      lastName
      party
      id
      role
      state
      from
      imageUrl
      lifetimeRating
      district

      history {
        year
        chamber
        district
        party
      }

      committees {
        name
        year
      }

      ratings: acuRatings(
        where: { year: { _lte: $year } }
        order_by: { year: desc_nulls_last }
        limit: 2
      ) {
        year
        votes
        acuVotes
        rating
        total
      }

      acuLifetimeRatings(order_by: { year: desc_nulls_last }) {
        rating
        year
      }

      issueRatings: issueRatings {
        categoryId
        category
        group
        year
        rating
        total
        acuVotes
        votes
      }

      years: votes(distinct_on: year, order_by: { year: desc_nulls_last }, limit: 1) {
        latest: year
      }
    }
  }
`;

const PersonOverviewWrapper = styled("div")`
  background: white;
  /* padding: 24px; */
  position: relative;

  h2 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2d3436;
  }
`;

const OverviewWrapper = styled("div")`
  margin: 16px 0;
`;

const Stats = styled("p")`
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  /* or 21px */
  color: #636e72;
  margin: ${(props) => (props.noPadding ? "0" : `16px 0 16px 0`)};
`;

const PicContainer = styled("div")`
  /* max-height: 300px; */
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const RatingBadge = styled("div")`
  background-color: ${(props) =>
    props.rating === null ? COLORS.GRAY : colorScale(props.rating)};
  position: absolute;
  top: -16px;
  left: -16px;
  padding: 6px 12px 8px;
  border-radius: 1000px;
  line-height: 1;
  color: white;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  font-size: 24px;

  p {
    text-align: center;
    color: white;
    margin: 0;
  }

  p:nth-of-type(2) {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  span {
    color: white;
    padding-left: 2px;
    font-size: 20px;
  }
`;

const StrengthsAndWeaknesses = styled("div")`
  padding-top: 32px;
  padding-bottom: 32px;

  h3 {
    margin-top: 24px;
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-family: "Helvetica Neue";
    font-size: 14px;
    color: #636e72;
    margin-bottom: 8px;
  }
`;

const IssueItem = styled("li")`
  cursor: pointer;
  text-decoration: underline;
`;

const Tooltip = styled(ReactTooltip)`
  padding: 4px 8px !important;
  margin: 0;

  p {
    color: white;
    margin: 0;
    line-height: 1.5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: white;
      margin: 4px;
    }
  }
`;

function getTitle(role) {
  switch (role) {
    case "Sen":
      return "Senator";
    case "Rep":
      return "Representative";
    default:
      return "";
  }
}

function getRating(ratings, year) {
  const match = ratings.reduce(
    (acc, n) => {
      if (year) {
        if (acc.year == year) {
          return acc;
        } else if (n.year == year) {
          return n;
        }
      }

      if (acc.year > n.year) {
        return acc;
      }

      return n;
    },
    { rating: null, year: 0 }
  );

  return match.rating;
}

function getPerson(data, year) {
  if (!data) {
    return {};
  }

  const person = data.ratings_people[0];

  const { strongIssues, weakIssues } = getStrongAndWeak(
    person.issueRatings,
    person.party,
    person.state
  );

  const district = formatDistrictName(person.district)
    ? `- ${formatDistrictName(person.district)}`
    : "";

  const acuLifetimeRating = getRating(person.acuLifetimeRatings, year);

  return {
    ...person,
    position: getTitle(getRole(person)),
    stats: `${stateAbbrMap(person)} - ${partyMap(person.party)} ${district}`,
    lifetimeRating: mapRating(acuLifetimeRating, true),
    image:
      person.imageUrl ||
      "https://theprobatepro.com/wp-content/uploads/2015/02/FACEHOLDER.jpeg",
    strongIssues,
    weakIssues,
  };
}

const CommitteesWrapper = styled(ScrollableContainer)`
  h4 {
    font-size: 15px;
    color: #636e72;
    margin: 0;
  }

  ul {
    font-family: Roboto, sans-serif;
    margin-top: 4px;
    margin-bottom: 8px;
    padding-left: 24px;

    li {
      font-size: 14px;
      font-family: Roboto, sans-serif;
      color: #636e72;
      line-height: 1.5;
    }
  }

  /* margin-bottom: 16px; */
`;

function Committees({ committees = [] }) {
  if (committees.length === 0) {
    return null;
  }

  const byYear = committees.reduce((acc, c) => {
    acc[c.year] = acc[c.year] || [];
    acc[c.year].push(c.name);
    return acc;
  }, {});

  return (
    <>
      <h3>Committees</h3>
      <CommitteesWrapper height={180}>
        {Object.keys(byYear)
          .sort((a, b) => b - a)
          .map((year) => (
            <div>
              <h4>{year}</h4>
              <ul>
                {byYear[year].map((c) => (
                  <li>{c} Committee</li>
                ))}
              </ul>
            </div>
          ))}
      </CommitteesWrapper>
    </>
  );
}

function getRatedYears(person) {
  try {
    const { count } = person.lifetimeRating_aggregate.aggregate;
    return `Rated for ${count} year${count === 1 ? "" : "s"}`;
  } catch (e) {
    return "";
  }
}

// TODO: Don't display nulls; grab latest year actually rated. (But, show null if year is selected.)
function renderCurrentRating(ratings = [], year, history, id) {
  const [current, previous] = ratings;
  if (!current || (year && current.year !== year)) {
    return <RatingWidget style={{ maxWidth: 125 }} />;
  }

  const h = history.find((h) => h.year === current.year);
  if (
    h &&
    !threshold(current.votes / current.total, h.state, h.role, h.year, id)
  ) {
    return (
      <RatingWidget
        style={{ maxWidth: 125 }}
        title={`${current.year} rating`}
      />
    );
  }

  const difference = current.rating - previous?.rating;
  const sign = difference > 0 ? "+" : "";
  const trimmed =
    Math.abs(difference) >= 1 ? difference.toFixed(0) : difference.toFixed(2);
  const change =
    !previous || isNaN(difference)
      ? `No previous rating`
      : `${sign}${trimmed} from ${previous?.year}`;

  return (
    <RatingWidget
      {...current}
      title={`${current.year} rating`}
      detail={change}
      style={{ maxWidth: 125 }}
    />
  );
}

function renderLifetimeRating(ratings = [], year) {
  const current = ratings.find((n) => n.rating !== null);
  if (!current) {
    return <RatingWidget title="Lifetime Rating" style={{ maxWidth: 125 }} />;
  }

  const previous = ratings.find((n) => {
    return (
      n.rating !== null && (!year || n.year <= year) && n.year < current.year
    );
  });

  const difference = current.rating - previous?.rating;
  const sign = difference > 0 ? "+" : "";
  const trimmed =
    Math.abs(difference) >= 1 ? difference.toFixed(0) : difference.toFixed(2);
  const change =
    !previous || isNaN(difference)
      ? `No previous rating`
      : `${sign}${trimmed} from ${previous?.year}`;

  return (
    <RatingWidget
      {...current}
      title="Lifetime Rating"
      detail={change}
      style={{ maxWidth: 125 }}
      decimal
    />
  );
}

export default function PersonOverview(props) {
  const { data, loading, error } = useQuery(GET_PERSON_QUERY, {
    variables: {
      id: props.id,
      year: props.year,
    },
  });

  function selectIssues(issues) {
    if (props.onSelectIssues) {
      props.onSelectIssues(issues);
    }
  }

  if (loading) {
    const image =
      data && data.ratings_people && data.ratings_people[0]?.imageUrl; // || "https://theprobatepro.com/wp-content/uploads/2015/02/FACEHOLDER.jpeg";

    return (
      <PersonOverviewWrapper>
        <Row justify="between">
          <Col md={3}>
            <PicContainer>
              {/* <RatingBadge rating={person.lifetimeRating}>
              <p>{person.lifetimeRating}<span>%</span></p>
              {person.lifetimeRank && <p>Rank {person.lifetimeRank}</p>}
            </RatingBadge> */}
              <img src={image} />
            </PicContainer>
          </Col>

          <Col md={5}>
            <LoadingBlock height={355} />
          </Col>

          <Col md={4}></Col>
        </Row>
      </PersonOverviewWrapper>
    );
  }

  if (error) {
    return <p>Something went wrong. Please try again later.</p>;
  }

  const person = getPerson(data, props.year);

  // use current year by default if person has no associated votes on record
  const latest_year = person.years.length > 0 ? person.years[0].latest : null;

  return (
    <PersonOverviewWrapper>
      {latest_year && <PDFLink year={latest_year} id={person.state} />}

      <Row justify="between">
        <Col md={3}>
          <PicContainer>
            {/* <RatingBadge rating={person.lifetimeRating}>
              <p>{person.lifetimeRating}<span>%</span></p>
              {person.lifetimeRank && <p>Rank {person.lifetimeRank}</p>}
            </RatingBadge> */}
            <img src={person.image} />
          </PicContainer>
        </Col>

        <Col md={5}>
          <OverviewWrapper>
            <h2>Overview</h2>

            <Row>
              <Col>
                {renderCurrentRating(
                  person.ratings,
                  props.year,
                  person.history,
                  person.id
                )}
              </Col>
              <Col>
                {renderLifetimeRating(person.acuLifetimeRatings, props.year)}
              </Col>
            </Row>

            <Stats>{person.stats}</Stats>
            {/* <Stats noPadding>{person.bio}</Stats> */}
            <Stats>{getRatedYears(person)}</Stats>
            <Committees committees={person.committees} />

            {/* <Link href="#">More about {person.position} {person.lastName} &gt;</Link> */}
          </OverviewWrapper>
        </Col>

        <Col md={4}>
          <StrengthsAndWeaknesses>
            <h3>Strongest issues</h3>
            <IssueList
              issues={person.strongIssues}
              onSelectIssues={selectIssues}
            />

            <h3>Weakest issues</h3>
            <IssueList
              issues={person.weakIssues}
              onSelectIssues={selectIssues}
            />
          </StrengthsAndWeaknesses>
        </Col>
      </Row>
      <Tooltip
        multiline={true}
        getContent={getTooltipContent}
        id="person-overview-tooltip"
      />
    </PersonOverviewWrapper>
  );
}

function getTooltipContent(data) {
  if (!data) return null;
  // const [rating, total, rank] = data.split(',');
  const { rating, votes, rank, acuVotes, allTimeAcuVotes, allTimeVotes } =
    JSON.parse(data);

  return (
    <div>
      <p>
        <span>Rating:</span>
        <span>{fix(rating, true)}%</span>
      </p>
      <p>
        <span>Most recent votes:</span>
        <span>
          {acuVotes} / {votes}
        </span>
      </p>
      <p>
        <span>All time votes:</span>
        <span>
          {allTimeAcuVotes} / {allTimeVotes}
        </span>
      </p>
    </div>
  );
}

function IssueList({ issues, onSelectIssues = (issue) => {} }) {
  let issueElements = null;

  if (issues.length === 0) {
    issueElements = (
      <li>
        <em>n/a</em>
      </li>
    );
  } else {
    issueElements = issues.map((issue) => (
      <IssueItem
        onClick={() => onSelectIssues(issue.issues)}
        data-tip={JSON.stringify(issue)}
        data-for="person-overview-tooltip"
      >
        {issue.groupName}
      </IssueItem>
    ));
  }

  return <ul>{issueElements}</ul>;
}

// TODO: Factor out
function fix(n, decimal = false) {
  try {
    if (decimal) {
      return n.toFixed(2).replace(/(\.0)?0$/, "");
    }
    return Math.floor(n)
      .toFixed(0)
      .replace(/(\.0)?0$/, "");
  } catch (e) {
    return n;
  }
}

// A category must receive this many votes to be considered for inclusion
const STRONG_WEAK_THRESHOLD = 10;

// "Strong" categories for R's have a rating of 80 or better
const R_STRONG_RATING_THRESHOLD = 80;

// "Weak" categories have a rating of less than 65
const R_WEAK_RATING_THRESHOLD = 65;

// D's have much lower expectations
const D_STRONG_RATING_THRESHOLD = 20;
const D_WEAK_RATING_THRESHOLD = 10;

// How many votes to consider, starting with the most recent year
const STRONG_WEAK_VOTES_TO_CONSIDER = 20;

// How many to include in each section
const STRONG_WEAK_COUNT = 5;

// TODO: Filter by year, include min rated bill count filter
// TODO: Factor out and share between this and State overview
// function getStrongAndWeak(issueRatings) {

//   console.log({ issueRatings, groupsById });

//   const aggregates = issueRatings.reduce((acc, n) => {
//     acc[n.group] = (acc[n.group] || {
//       votes: 0,
//       acuVotes: 0,
//       groupName: groupsById[n.group],
//       categoryIds: {}
//     });

//     acc[n.group].categoryIds[n.categoryId] = true;
//     acc[n.group].votes += n.votes;
//     acc[n.group].acuVotes += n.acuVotes;

//     return acc;
//   }, {});

//   // TODO: Only pull the most recent ~20ish votes

//   const sorted = Object.keys(aggregates).map(key => {
//     const n = aggregates[key];
//     return {
//       group: key,
//       groupName: n.groupName,
//       rating: 100 * n.acuVotes / n.votes,
//       votes: n.votes,
//       acuVotes: n.acuVotes,
//       rank: n.acuVotes,
//       issues: Object.keys(n.categoryIds),
//     }
//   }).sort((a, b) => b.rank - a.rank);

//   return {
//     strongIssues: sorted.filter(n => n.votes >= STRONG_WEAK_LIMIT && n.rating >= R_STRONG_RATING_THRESHOLD).slice(0, 5), //.map(n => n.category),
//     weakIssues: sorted.filter(n => n.votes >= STRONG_WEAK_LIMIT && n.rating < R_WEAK_RATING_THRESHOLD).slice(-5), //.map(n => n.category),
//   }
// }

function getStrongAndWeak(issueRatings, party, state) {
  // Default to Democrat levels
  party = "DR".includes(party) ? party : "D";

  // const STRONG_RATING_THRESHOLD = party === 'R' ? R_STRONG_RATING_THRESHOLD : D_STRONG_RATING_THRESHOLD;
  // const WEAK_RATING_THRESHOLD = party === 'R' ? R_WEAK_RATING_THRESHOLD : D_WEAK_RATING_THRESHOLD;

  const STRONG_RATING_THRESHOLD =
    STRONGEST_WEAKEST_THRESHOLDS.STRONG[`${state}:${party}`];
  const WEAK_RATING_THRESHOLD =
    STRONGEST_WEAKEST_THRESHOLDS.WEAK[`${state}:${party}`];

  const aggregates = issueRatings
    .slice()
    .sort((a, b) => b.year - a.year)
    .reduce((acc, n) => {
      acc[n.group] = acc[n.group] || {
        allTimeAcuVotes: 0,
        allTimeVotes: 0,
        votes: 0,
        acuVotes: 0,
        groupName: groupsById[n.group],
        categoryIds: {},
      };

      if (acc[n.group].votes <= STRONG_WEAK_VOTES_TO_CONSIDER) {
        acc[n.group].categoryIds[n.categoryId] = true;
        acc[n.group].votes += n.votes;
        acc[n.group].acuVotes += n.acuVotes;
      }
 
      acc[n.group].allTimeAcuVotes += n.acuVotes;
      acc[n.group].allTimeVotes += n.votes;

      return acc;
    }, {});

  // TODO: Only pull the most recent ~20ish votes

  const sorted = Object.keys(aggregates)
    .map((key) => {
      const n = aggregates[key];
      return {
        allTimeAcuVotes: n.allTimeAcuVotes,
        allTimeVotes: n.allTimeVotes,
        group: key,
        groupName: n.groupName,
        rating: (100 * n.acuVotes) / n.votes,
        votes: n.votes,
        acuVotes: n.acuVotes,
        // rank: n.acuVotes,
        rank: (100 * n.acuVotes) / n.votes,
        issues: Object.keys(n.categoryIds),
      };
    })
    .sort((a, b) => {
      const diff = b.rank - a.rank;

      if (diff === 0) {
        return b.allTimeAcuVotes - a.allTimeAcuVotes;
      }

      return diff;
    });

  return {
    strongIssues: sorted
      .filter(
        (n) =>
          n.votes >= STRONG_WEAK_THRESHOLD &&
          n.rating >= STRONG_RATING_THRESHOLD
      )
      .slice(0, STRONG_WEAK_COUNT), //.map(n => n.category),
    weakIssues: sorted
      .filter(
        (n) =>
          n.votes >= STRONG_WEAK_THRESHOLD && n.rating < WEAK_RATING_THRESHOLD
      )
      .slice(-STRONG_WEAK_COUNT), //.map(n => n.category),
  };
}
