module.exports = {
  "COLORS": {
    "TEXT": "#2D3436",
    "CONSERVATIVE": "#CC4748",
    "LIBERAL": "#0984E3",
    "GRAY": "#636E72",
    "LIGHT": "#B2BEC3"
  },
  "THRESHOLDS": [
    {
      "threshold": 0,
      "start": 2020,
      "end": 2020,
      "peopleId": "S000033",
      "name": "Bernie Sanders"
    },
    {
      "threshold": 100,
      "start": 2020,
      "end": 2020,
      "peopleId": "K000368",
      "name": "Ann Kirkpatrick"
    },
    {
      "threshold": 100,
      "start": 2020,
      "end": 2020,
      "peopleId": "G000377",
      "name": "Kay Granger"
    },
    {
      "threshold": 100,
      "start": 2020,
      "end": 2020,
      "peopleId": "B001289",
      "name": "Bradley Byrne"
    },
    {
      "threshold": 0.5,
      "start": 2019,
      "end": 2019,
      "chamber": "senate",
      "role": "sen",
      "state": "US"
    },
    {
      "threshold": 0.5,
      "start": 1971,
      "end": 2015
    },
    {
      "threshold": 0.666666666666666666666,
      "start": 2016,
      "end": 9999
    }
  ],
  "STRONGEST_WEAKEST_THRESHOLDS": {
    "STRONG": {
      "US:R": 82.5,
      "US:D": 15,
      "AL:R": 75,
      "AL:D": 25,
      "AK:R": 80,
      "AK:D": 22.5,
      "AZ:R": 82.5,
      "AZ:D": 20,
      "AR:R": 80,
      "AR:D": 25,
      "CA:R": 82.5,
      "CA:D": 15,
      "CO:R": 80,
      "CO:D": 17.5,
      "CT:R": 75,
      "CT:D": 20,
      "DE:R": 75,
      "DE:D": 20,
      "FL:R": 85,
      "FL:D": 25,
      "GA:R": 75,
      "GA:D": 25,
      "HI:R": 60,
      "HI:D": 20,
      "ID:R": 80,
      "ID:D": 25,
      "IL:R": 80,
      "IL:D": 20,
      "IN:R": 80,
      "IN:D": 22.5,
      "IA:R": 80,
      "IA:D": 25,
      "KS:R": 80,
      "KS:D": 25,
      "KT:R": 80,
      "KT:D": 20,
      "LA:R": 80,
      "LA:D": 35,
      "ME:R": 82.5,
      "ME:D": 20,
      "MD:R": 80,
      "MD:D": 15,
      "MA:R": 75,
      "MA:D": 15,
      "MI:R": 80,
      "MI:D": 25,
      "MN:R": 85,
      "MN:D": 15,
      "MS:R": 75,
      "MS:D": 27.5,
      "MO:R": 80,
      "MO:D": 20,
      "MT:R": 80,
      "MT:D": 20,
      "NE:R": 51,
      "NE:D": 51,
      "NV:R": 80,
      "NV:D": 20,
      "NH:R": 80,
      "NH:D": 15,
      "NJ:R": 80,
      "NJ:D": 12.5,
      "NM:R": 77.5,
      "NM:D": 20,
      "NY:R": 80,
      "NY:D": 17.5,
      "NC:R": 80,
      "NC:D": 27.5,
      "ND:R": 80,
      "ND:D": 20,
      "OH:R": 80,
      "OH:D": 20,
      "OK:R": 80,
      "OK:D": 27.5,
      "OR:R": 80,
      "OR:D": 20,
      "PA:R": 80,
      "PA:D": 20,
      "RI:R": 75,
      "RI:D": 22.5,
      "SC:R": 72.5,
      "SC:D": 25,
      "SD:R": 80,
      "SD:D": 25,
      "TN:R": 85,
      "TN:D": 30,
      "TX:R": 80,
      "TX:D": 20,
      "UT:R": 80,
      "UT:D": 22.5,
      "VT:R": 75,
      "VT:D": 20,
      "VA:R": 80,
      "VA:D": 20,
      "WA:R": 80,
      "WA:D": 17.5,
      "WV:R": 80,
      "WV:D": 27.5,
      "WI:R": 80,
      "WI:D": 17.5,
      "WY:R": 80,
      "WY:D": 22.5
    },
    "WEAK": {
      "US:R": 72.5,
      "US:D": 7.5,
      "AL:R": 67,
      "AL:D": 20,
      "AK:R": 67,
      "AK:D": 20,
      "AZ:R": 70,
      "AZ:D": 10,
      "AR:R": 67,
      "AR:D": 17.5,
      "CA:R": 70,
      "CA:D": 7.5,
      "CO:R": 70,
      "CO:D": 10,
      "CT:R": 62.5,
      "CT:D": 10,
      "DE:R": 67,
      "DE:D": 15,
      "FL:R": 75,
      "FL:D": 20,
      "GA:R": 67,
      "GA:D": 20,
      "HI:R": 40,
      "HI:D": 10,
      "ID:R": 67,
      "ID:D": 17.5,
      "IL:R": 67,
      "IL:D": 10,
      "IN:R": 67,
      "IN:D": 15,
      "IA:R": 70,
      "IA:D": 15,
      "KS:R": 67,
      "KS:D": 17.5,
      "KT:R": 70,
      "KT:D": 17.5,
      "LA:R": 67,
      "LA:D": 25,
      "ME:R": 67.5,
      "ME:D": 10,
      "MD:R": 67,
      "MD:D": 10,
      "MA:R": 67,
      "MA:D": 10,
      "MI:R": 67,
      "MI:D": 20,
      "MN:R": 72.5,
      "MN:D": 7.5,
      "MS:R": 62.5,
      "MS:D": 17.5,
      "MO:R": 67,
      "MO:D": 12.5,
      "MT:R": 67,
      "MT:D": 10,
      "NE:R": 49,
      "NE:D": 49,
      "NV:R": 67,
      "NV:D": 10,
      "NH:R": 67,
      "NH:D": 10,
      "NJ:R": 67,
      "NJ:D": 7.5,
      "NM:R": 67,
      "NM:D": 10,
      "NY:R": 67,
      "NY:D": 10,
      "NC:R": 67,
      "NC:D": 17.5,
      "ND:R": 67,
      "ND:D": 15,
      "OH:R": 70,
      "OH:D": 12.5,
      "OK:R": 67,
      "OK:D": 20,
      "OR:R": 67,
      "OR:D": 10,
      "PA:R": 67,
      "PA:D": 10,
      "RI:R": 62.5,
      "RI:D": 12.5,
      "SC:R": 60,
      "SC:D": 15,
      "SD:R": 67,
      "SD:D": 15,
      "TN:R": 72.5,
      "TN:D": 20,
      "TX:R": 67.5,
      "TX:D": 15,
      "UT:R": 67,
      "UT:D": 12.5,
      "VT:R": 67,
      "VT:D": 10,
      "VA:R": 67,
      "VA:D": 10,
      "WA:R": 67,
      "WA:D": 7.5,
      "WV:R": 67.5,
      "WV:D": 17.5,
      "WI:R": 70,
      "WI:D": 10,
      "WY:R": 67,
      "WY:D": 17.5
    }
  },
  "BREAKPOINTS": {
    "DESKTOP": 1255
  }
};