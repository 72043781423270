import styled from 'styled-components';

const Pill = styled('span')`
  text-transform: uppercase;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;

  letter-spacing: 0.04em;
  color: #636E72;

  background: #F0F3F5;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 0 4px;
`;

export default Pill;
