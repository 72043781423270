import styled from 'styled-components';

const Input = styled('input')`
  padding: 8px;
  border: 1px solid #BDBDBD;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #999999;
`;

export default Input;