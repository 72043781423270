import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { useQueryParam, NumberParam, withDefault } from "use-query-params";

import useSql from '../util/useSql';
import BillsList from '../components/BillsList';
import SearchInput from '../components/SearchInput';
import StateSelector from '../components/StateSelector';
import YearSelector from '../components/YearSelector';
import PartySelector from '../components/PartySelector';
import RatingBar from '../components/RatingBar';
import Select from '../components/Select';
import stateList from '../util/stateList.json';
import Loading from '../components/Loading';
import ShareBar from '../components/ShareBar';
import Footer from '../components/Footer';
import usePageTitle from '../util/usePageTitle';
import { cacheClient } from '../data/client';


const STATE_RANKINGS = gql`
query {
  rankings: ratings_getStateRankings {
    name
    rating
    chamber
    state
    total
    year
  }
}
`;


const Wrapper = styled('div')`
  max-width: 1000px;
  margin: 0 auto;
`;

const Title = styled('h1')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin: 0;
  color: #2D3436;
`;

const Subtitle = styled('h3')`
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 39px;
  margin: 0;
  color: #636E72;
`;

const Form = styled(Row)`
  padding: 16px;
  > * {
    margin-right: 24px;
  }
`;


const Options = styled('div')`
  p:first-child {
    font-weight: bold;
    color: #636E72;
  }
  > p {
    color: #636E72;
    display: inline-block;
    margin-right: 32px;

    span {
      color: #636E72;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;


const sortOptions = [{
  key: 'currentRankingOrder',
  name: 'Current Ranking',
  asc_nulls_last: 'Low to high',
  desc_nulls_last: 'High to low',
  prop: 'rating',
}, {
  key: 'senateRankingOrder',
  name: 'Senate Ranking',
  asc_nulls_last: 'Low to high',
  desc_nulls_last: 'High to low',
  prop: 'senate',
}, {
  key: 'houseRankingOrder',
  name: 'House Ranking',
  asc_nulls_last: 'Low to high',
  desc_nulls_last: 'High to low',
  prop: 'house',
}, {
  key: 'stateNameOrder',
  name: 'State Name',
  asc_nulls_last: 'A to Z',
  desc_nulls_last: 'Z to A',
  prop: 'name',
}];


const LOW_TO_HIGH = 'asc_nulls_last';
const HIGH_TO_LOW = 'desc_nulls_last';


function makeSort(orderBy, sortDirection) {
  const { prop, key } = orderBy;

  if (key === 'stateNameOrder') {
    if (sortDirection === LOW_TO_HIGH) {
      return (a, b) => a[prop].localeCompare(b[prop]);
    }
    return (a, b) => b[prop].localeCompare(a[prop]);
  }

  if (sortDirection === LOW_TO_HIGH) {
    return (a, b) => numOrInf(a[prop]) - numOrInf(b[prop]);
  } else {
    return (a, b) => numOrInf(b[prop]) - numOrInf(a[prop]);
  }
}

function numOrInf(n) {
  return isNaN(+n) ? -Infinity : +n;
}


const SortLabel = styled('div')`
  display: inline-block;
  display: flex;
  flex-direction: row;
  align-items: center;
  >div {
    margin-left: 8px;
  }
`;

const InnerWrapper = styled('div')`
  margin-top: 24px;
  padding: 24px;
  background: white;

  h3 {
    margin-top: 0;
  }
`;

const State = styled(Row)`
  margin: 24px 0 24px 24px !important;
  height: 24px;

  >div:first-child {
    /* text-align: right; */
    color: #636E72;
  }

  >div:nth-child(2) {
    color: #2D3436;
  }

  >div:nth-child(3) {
    span {
      color: #636E72;
    }
  }
`;

const ChamberRatings = styled(Col)`
  span {
    margin-right: 24px;
    color: #636E72;
    min-width: 90px;
    display: inline-block;
  }
`;

const YearSelectorWrapper = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const RatingLabel = styled('p')`
  margin: 2px 0 0 0;
  color: #636E72;
  font-size: 14px;
`;

const SmallSelect = styled(Select)`
  width: 180px;
`;

const SortGroup = styled('div')`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  >div {
    margin-right: 16px;
  }
`;

const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

function aggregateRatings(data, selectedYear) {
  let byState = data.reduce((acc, n) => {
    acc[n.state] = acc[n.state] || {};
    acc[n.state][n.chamber] = acc[n.state][n.chamber] || [];
    acc[n.state][n.chamber].push(n);
    return acc;
  }, {});


  for (const key in byState) {

    const byChamber = {};

    let sums = {};
    let numChambers = 0;
    const data = byState[key];
    for (const chamber in data) {
      numChambers++;
      for (const record of data[chamber]) {
        if (!selectedYear || selectedYear == record.year) {
          byChamber[chamber] = (byChamber[chamber] || 0) + record.rating;
          sums[record.year] = (sums[record.year] || 0) + record.rating;
        }
      }
    }

    let total = 0;
    let numYears = 0;
    for (const year in sums) {
      if (!selectedYear || selectedYear == year) {
        numYears++;
        sums[year] /= numChambers;
        total += sums[year];
      }
    }
    total /= numYears;
    byState[key].rating = total;

    for (const chamber in byChamber) {
      byState[key][`${chamber}Rating`] = byChamber[chamber] / numYears;

      if (chamber !== 'senate') {
        byState[key].type = chamber;
      }
    }

  }

  byState = Object.keys(byState).map(state => {
    return {
      // TODO: Do this more elegantly
      name: data.find(n => n.state === state).name,
      state,
      ...byState[state],
    };
  });

  return byState;
}

const HAS_ASSEMBLY = ['CA', 'NV', 'NY', 'NJ', 'WI'];

export default function StatesPage(props) {

  const [orderBy, setOrderBy] = useState(sortOptions[0]);
  const [sortDirection, setSortDirection] = useState(HIGH_TO_LOW);

  // TODO: Move to some kind of config
  const [year, setYear] = useQueryParam("year", NumberParam);

  useEffect(() => {
    if (!year) {
      setTimeout(() => setYear(2022));
    }
  }, [])

  const { data, loading, error } = useQuery(STATE_RANKINGS, {
    client: cacheClient,
  });
  // const sqlQuery = useSql(STATES_RATINGS_SQL);

  let byState = null;

  // TODO: Wrap into its own hook
  if (data && data.rankings) {
    byState = aggregateRatings(data.rankings, year);
  }

  const directionOptions = [{
    label: orderBy.asc_nulls_last,
    value: 'asc_nulls_last',
  }, {
    label: orderBy.desc_nulls_last,
    value: 'desc_nulls_last',
  }];

  const selectedDirection = directionOptions.find(d => d.value === sortDirection);

  function onSortChange(newSort) {
    setSortDirection(newSort.value);
  }

  usePageTitle('State Legislatures');

  if (loading) {
    return <Loading />;
  }

  function nextOrderBy() {
    const i = sortOptions.findIndex(n => n === orderBy);
    const nextOne = sortOptions[(i + 1) % sortOptions.length];
    setOrderBy(nextOne);
    if (nextOne.key === 'stateNameOrder') {
      setSortDirection(LOW_TO_HIGH);
    } else {
      setSortDirection(HIGH_TO_LOW);
    }
  }

  function swapOrder() {
    if (sortDirection === LOW_TO_HIGH) {
      setSortDirection(HIGH_TO_LOW);
    } else {
      setSortDirection(LOW_TO_HIGH);
    }
  }

  let states;

  // TODO: Make this part of the aggregate function
  if (byState) {

    states = byState.map(({
      state,
      name,
      rating,
      type,
      senateRating,
      houseRating,
      assemblyRating,
    }, i) => {

      return {
        rank: i + 1,
        abbr: state,
        name, // TODO: Replace with state name
        type,
        senate: fix(senateRating),
        house: fix(houseRating),
        assembly: fix(assemblyRating),
        rating: fix(rating),
      }
    })

  } else {
    states = stateList.slice(2).map((n, i) => {
      return {
        rank: i + 1,
        abbr: n.abbr,
        name: n.name,
        senate: 100 - i - n.name.length,
        house: 100 - i - n.name.length * 2,
        rating: 100 - i,
      };
    });
  }

  let federalIndex;

  const stateNameOrder = orderBy.key === 'stateNameOrder';

  states
    .sort(makeSort(orderBy, sortDirection))
    .forEach((s, i) => {

      if (s.abbr === 'US') {
        federalIndex = i;
      }

      if (!stateNameOrder && sortDirection === LOW_TO_HIGH) {

        if (federalIndex == null) {
          s.rank = 50 - i;
        } else if (federalIndex === i) {
          s.rank = '';
        } else {
          s.rank = 51 - i;
        }

      } else {

        if (federalIndex == null) {
          s.rank = i + 1;
        } else if (federalIndex === i) {
          s.rank = '';
        } else {
          s.rank = i;
        }

      }
    });



  function fix(n) {
    if (typeof n === 'number' && !isNaN(n)) {
      return (n * 100).toFixed(0)
    }
    return 'n/a';
  }

  function renderState(state) {
    return (
      <State>
        <Col md={0}>{state.rank ? `${state.rank}.` : ''}</Col>
        <Col>
          <Link to={state.abbr === 'US' ? '/congress' : `/states/${state.abbr}`}>
            {state.abbr === 'US' ? 'Congress' : state.name}
          </Link>
        </Col>
        <ChamberRatings>
          <span>{state.abbr === 'NE' ? 'Unicameral' : 'Senate'}: {state.senate}</span>
          {HAS_ASSEMBLY.includes(state.abbr) && <span>Assembly: {state.assembly}</span>}
          {!HAS_ASSEMBLY.includes(state.abbr) && state.abbr !== 'NE' && <span>House: {state.house}</span>}
        </ChamberRatings>
        <Col>
          <RatingBar rating={state.rating} />
          <RatingLabel>{year ? `${year} rating` : 'All-time rating'}</RatingLabel>
        </Col>
      </State>
    );
  }

  return (
    <Wrapper>
      <FlexRow>
        <Title>State rankings</Title>
        <ShareBar snippet="Check out CPAC's state ratings!" />
      </FlexRow>
      <Subtitle>Comparing the states on conservative policy</Subtitle>

      <Row>

        <Col md={8}>

          <SortGroup>
            <SortLabel>
              Sort by:
              <SmallSelect options={sortOptions.map(o => ({ value: o.key, label: o.name }))} value={{ value: orderBy.key, label: orderBy.name }} onChange={o => setOrderBy(sortOptions.find(so => so.name == o.label))} />
            </SortLabel>

            <SortLabel>
              Order by:
              <SmallSelect options={directionOptions} value={selectedDirection} onChange={onSortChange} />
            </SortLabel>
          </SortGroup>

          {/* <Options> */}
          {/* <SortLabel>Sort by: <span onClick={nextOrderBy}>{orderBy.name}</span></SortLabel> */}
          {/* <SortLabel>Order by: <span onClick={swapOrder}>{orderBy[sortDirection]}</span></SortLabel> */}
          {/* </Options> */}
        </Col>
        <YearSelectorWrapper>
          <YearSelector value={year} onChange={setYear} />
        </YearSelectorWrapper>
      </Row>

      <InnerWrapper>

        <h3>Ranking</h3>

        {states.map(renderState)}


      </InnerWrapper>

      <Footer />

    </Wrapper>
  )
}