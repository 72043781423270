import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import StateOverview from '../components/StateOverview';
import StatePerformance from '../components/StatePerformance';
import YearSelector from '../components/YearSelector';
import Link from '../components/Link';
import LeftChevron from '../icons/left-chevron';
import stateList from '../util/stateList.json';
import Leaderboard from '../components/Leaderboard';
import BillsList from '../components/BillsList';
import ShareBar from '../components/ShareBar';
import Footer from '../components/Footer';
import { Visible } from 'react-grid-system';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';


import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';


import Flag from '../components/Flag';
import StateBorder from '../components/StateBorder';
import usePageTitle from '../util/usePageTitle';
import { cacheClient } from '../data/client';


const GET_STATE_QUERY = gql`
query (
  $state: String!
  $year: Int
) {
  
  lifetimeRating: ratings_stateRatings_aggregate(
    where: { state: { _eq: $state } }
  ) {
    aggregate {
      avg {
        rating
      }
    }
  }
}
`;

const Wrapper = styled('div')`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
`;

const Name = styled('h1')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #2D3436;
  margin: 24px 0 40px 0;
`;

const Separator = styled('div')`
  height: 24px;
`;

const BackLink = styled(Link)`
  color: #2D3436;
  font-size: 16px;
  display: inline-block;
  
  svg {
    margin-bottom: -1px;
    margin-right: 2px;
  }
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  >div {
    display: flex;
    flex-direction: row;
    margin: 24px 0 40px 0;
    /* width: 120px; */

    >* {
      margin-left: 16px;
    }
  }
`;

const LATEST_YEAR_QUERY = gql`
  query(
    $state: String
  ) {
    ratings_acuRatings(
      where: { state: { _eq: $state }},
      limit: 1,
      distinct_on: year,
      order_by: { year: desc_nulls_last }
    ) {
      year
    }
  }
`;

function useLatestYear(state) {
  const {data, loading, error } = useQuery(LATEST_YEAR_QUERY, {
    variables: {
      state,
    },
    client: cacheClient,
  });
  if (data) {
    const { year } = (data.ratings_acuRatings[0] || {});
    return isNaN(+year) ? null : +year;
  }
}

export default withRouter(props => {

  let { id } = props.match.params;
  if (props.federal) {
    id = 'US';
  }

  // Default congress page to 2020
  const [year, setYear] = useState(2022);
  const [issues, setIssues] = useState([]);

  const latestYear = useLatestYear(id);

  useEffect(() => {
    if (latestYear) {
      setYear(latestYear);
    }
  }, [latestYear])

  const state = stateList.find(s => s.abbr === id);

  // State not found
  if (!state) {
    usePageTitle('State Details');
    return (
      <Wrapper>
        <BackLink href="/people"><LeftChevron fill="#2D3436" /> Back to search results</BackLink>
        <Name>State not found...</Name>
      </Wrapper>
    )
  }

  usePageTitle(state.abbr === 'US' ? 'U.S. Congress' : state.name);

  return (
    <Wrapper>

      <ScrollToTopOnMount />

      {/* TODO: Replace with left chevron */}
      <BackLink to="/states"><LeftChevron fill="#2D3436" /> Back to state rankings</BackLink>

      <TitleWrapper>
        {/* <Name>{state.name} <Flag src={flags[state.abbr]} /></Name> */}
        <Name>
          {state.name} 
          <Visible md lg xl>
            <Flag state={state.abbr} inline />
            {/* <StateBorder state={state.abbr} rating={rating} inline /> */}
          </Visible>
        </Name>
        <div>
          <ShareBar snippet={`Check out CPAC's rating for ${state.name}`} />
          <YearSelector value={year} onChange={setYear} />
        </div>
      </TitleWrapper>

      <StateOverview id={id} year={year} onSelectIssues={setIssues} setYear={setYear} />

      <Separator />

      <StatePerformance state={id} year={year} issues={issues} onSelectIssues={setIssues} />

      <Separator />

      <Leaderboard useCache={true} state={id} year={year} issues={issues} setYear={setYear} />

      <Separator />

      <BillsList useCache={true} state={id} year={year} issues={issues} />
      
      <Footer />

    </Wrapper>
  )
});
