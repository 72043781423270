import React, { useRef, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

export default function Tip(props) {
  const id = `test-tooltip-${(Math.random() * 10e5) | 0}`;

  const ref = useRef();
  useEffect(() => {
    if (props.showFirst) {
      setTimeout(() => {
        ReactTooltip.show(ref.current);
      }, props.delay || 0)
    }
  }, [props.title]);

  return (
    <>
      <span
        className="simple-tooltip"
        data-tip={props.title}
        data-for={id}
        ref={ref}
      >
        {props.children}
      </span>
      <ReactTooltip id={id} />
    </>
  );
}

function Tooltip(props) {
  const id = `tooltip-${(Math.random() * 10e5) | 0}`;
  const ref = useRef();

  useEffect(() => {
    window.ReactTooltip = ReactTooltip;
    window.ref = ref;

    // if (ref.current) {
    //   setTimeout(() => {
    //     ReactTooltip.show(ref.curent);
    //   }, 2000);
    // }
  }, [ref.current]);

  return (
    <>
      <div data-tip={props.title} data-for={id} ref={ref}>
        {props.children}
      </div>
      <ReactTooltip id={id} place="top" effect={props.effect || "solid"} />
    </>
  );
}

const StyledTooltip = styled("div")`
  h4 {
    color: white !important;
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
    position: relative;

    span {
      color: white;
      font-size: 16px;
      font-weight: 800;

      &:first-of-type {
        font-size: 10px;
        font-weight: 100;
        position: relative;
        top: -2px;
        padding: 0 3px;
      }
    }
  }

  p {
    font-style: italic;
    color: white !important;
    margin: 4px 0;
    white-space: nowrap;

    span {
      color: white;
      padding-right: 2px;
    }
  }

  div {
    div:first-child {
      margin-right: 6px;
    }
    div:last-child {
      margin-left: 6px;
    }
  }
`;
