import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-grid-system';
import gql from 'graphql-tag';
import { useQuery } from "@apollo/client";
import mapRating from '../../util/mapRating';
import { useQueryParam, ArrayParam, NumberParam, DelimitedArrayParam, withDefault } from 'use-query-params';
import CloseIcon from '../../icons/close';

import { getRole } from '../../util/person';
import formatDistrict from '../../util/formatDistrictName';
import querySql from '../../util/sql';
import toPostgresArray from '../../util/toPostgresArray';

import PersonSelector from './PersonSelector';
import SearchInput from '../../components/SearchInput';
import StateSelector from '../../components/StateSelector';
import PartySelector from '../../components/PartySelector';
import PersonRow from '../../components/PersonRow';
import Loading from '../../icons/loading';
import Select from '../../components/Select';
import ComparisonGraph from '../../components/ComparisonGraph';
import ShareBar from '../../components/ShareBar';
import FloatingIssueSelector from '../../components/FloatingIssueSelector';

import BackLink from '../../components/BackLink';
import YearSelector from '../../components/YearSelector';
import IssueSelector from '../../components/IssueSelector';
import WithAgainst from '../../components/WithAgainst';
import ProfileImage from '../../components/ProfileImage';
import Footer from '../../components/Footer';

import LawmakerDetail from './LawmakerDetail';
import usePageTitle from '../../util/usePageTitle';


// TODO: Use performance pg function to fetch performance, not aggregates
const GET_PEOPLE_QUERY = gql`
query getPeopleForComparison(
  $ids: [String!]
  $issues: [String!]
) {
  people: ratings_people(limit: 5, where: {
    id: {
      _in: $ids
    }
  }) {
    id
    firstName
    lastName
    thumbnailUrl
    state
    party
    role

    acuLifetimeRatings(limit: 1, where: { rating: { _is_null: false } }, order_by: { year: desc_nulls_last }) {
      rating
    }

    history(limit: 1, order_by: { year: desc_nulls_last }) {
      party
      chamber
      district
      state
      from
      year
    }

    trend: issueRatings(where: {
      categoryId: {
        _in: $issues
      }
    }) {
      categoryId
      rating
      year
      total
    }
  }
}
`;

const GET_COMPARISON_ARTICLES = gql`
query getComparisonArticles(
  $peopleIds: _text
  $categories: _text
) {
  articles: ratings_getComparisonArticles(args:{
    peopleIds: $peopleIds,
    categories: $categories
  }) {
    acuVote
    amendmentNumber
    articleId
    billNumber
    party
    peopleId
    points
    rating
    title
    vote
    withAcu
    year
  }
}
`;

const Wrapper = styled('div')`
  max-width: 1600px;
  /* margin: -48px; */
  margin: 0 auto;

  h1 {
    font-size: 32px;
    font-family: "Helvetica Neue";
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const Heading = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 36px 0 24px 0;

  >div:last-child {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;


const Scrollable = styled('div').attrs(props => ({ style: { height: props.height ? props.height + 'px' : 'calc(100vh - 66px)' } }))`
  /* background-color: ${_ => ['red', 'green', 'blue', 'orange', 'pink', 'yellow'][Math.random() * 4 | 0]}; */
  width: 400px;
  overflow-x: hidden;
  overflow-y: auto;

  padding: 24px;

  box-sizing: border-box;

  /* padding-bottom: 48px; */
  &:first-child {
    /* padding-left: 48px; */
  }
  &:last-child {
    /* padding-right: 48px; */
  }

  h3 {
    margin: 0;
  }
`;

const Column = styled('div')`
  flex: 1;
  background-color: white;
  margin: 10px;

  &:first-child {
    margin-left: 0;
  }


  &:last-child {
    margin-right: 0;
  }

  padding: 24px 8px 0;

  &>p {
    padding: 0 16px;
    color: #636E72;
  }
`;

const RepColumn = styled(Column)``;

const BlankColumn = styled('div')`
  padding: 24px 16px 0;
  flex: 1;
`;

const ColumnLabel = styled(Column)`
  padding: 24px 8px 0;
  background: none;
  padding-top: 100px;
  min-width: 160px;
  /* min-width: 140px; */
  flex-grow: 0;

  &>p {
    /* text-align: right; */
    /* border: 1px solid red; */
    padding: 25px 0;
    margin: 0;
    color: #2D3436;
  }
`;

const Separator = styled('hr')`
  border: 1px solid ${props => props.invisible ? 'transparent' : '#F0F3F5'};
  margin: ${props => `${props.padding || 24}px 0 ${props.invisible ? 0 : props.padding || 24}px 0`};
`;

const RepColumnsWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

function PinToTop(props) {

  usePageTitle('Lawmaker Comparison');

  const ref = useRef(null);
  const [floating, setFloating] = useState(false);
  const [width, setWidth] = useState(null);

  useEffect(() => {

    if (ref.current) {

      setWidth(ref.current.offsetWidth);

      let offsetTop = null;

      function onScroll(e) {

        if (offsetTop === null) {
          requestAnimationFrame(() => {
            // Offset by 20 px to get it to line up correctly
            const nowFloating = (offsetTop + ref.current.offsetHeight - 20) < window.scrollY;
            if (floating != nowFloating) {
              setFloating(nowFloating);
            }
            offsetTop = null;
          });
        }
        offsetTop = ref.current.offsetTop;
      }

      document.addEventListener('scroll', onScroll);
      return () => document.removeEventListener('scroll', onScroll);
    }
  }, [ref, floating, setFloating]);

  if (floating) {
    return (
      <>
        <div ref={ref}>{props.children}</div>
        <div style={{ position: 'fixed', top: 0, zIndex: 2, width }}>{props.children}</div>
      </>
    )
  }

  return (
    <div ref={ref}>{props.children}</div>
  );
}

// Make it a component with a non-fixed parent, that keeps the size of the original...?
const RepColumnHeaderWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  margin: 0 16px;

  p {
    margin: 0;

    &:first-child {
      margin-bottom: 8px;
      color: #2D3436;
      white-space: nowrap;
    }

    &:last-child {
      color: #636E72;
    }
  }

  position: relative;

  svg {
    position: absolute;
    top: -16px;
    right: -16px;
    cursor: pointer;
  }

  >span {
    position: absolute;
    top: -16px;
    right: -16px;
    cursor: pointer;
  }
`;

const ProfileImageFlush = styled(ProfileImage)`
  margin-left: 0;
  cursor: ${props => props.pointerOnPicture ? 'pointer' : 'default'};
`;

function RepColumHeader(props) {

  const {
    firstName,
    lastName,
    name,
    state,
    body,
    party,
    thumbnailUrl,
    hideClose,
    lifetimeRating,
    rating,
    pointerOnPicture,
  } = props;

  // TODO: Use earliest history is year is before the person's time
  const history = ((props.history || []).find(h => !props.year || h.year === props.year) || props.history[0] || {});

  const role = getRole(props);

  let chamber = '';
  try {
    chamber = history.chamber[0].toUpperCase() + history.chamber.slice(1);
  } catch (e) {
    chamber = '';
  }

  const district = formatDistrict(history.district, false);


  return (
    <RepColumnHeaderWrapper onClick={props.onClick} className={props.className}>
      {/* <RepImage image={imageUrl} /> */}
      <ProfileImageFlush src={thumbnailUrl} rating={rating || lifetimeRating} pointerOnPicture />
      <div>
        <p><Link to={`/people/${props.id}`}>{role}. {firstName} {lastName} ({history.party})</Link></p>
        <p>{history.state} {chamber} {history.state === 'US' ? `– ${history.from} ` : ''}{district ? `– ${district}` : ''}</p>
      </div>
      {!hideClose && <span onClick={props.onClose}>X</span>}
    </RepColumnHeaderWrapper>
  )
}



const IssueFilter = styled('a')`
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
  min-width: 120px;
  outline: none;
`;

const FloatingIssueFilter = styled('div')`
  position: absolute;
  top: 48px;
  right: 0;
  background-color: white;
  border: 1px solid #F0F3F5;
  box-shadow: 0 2px 6px -2px rgba(0,0,0,.125);
  z-index: 2000;
`;


const SectionHeader = styled('h3')`
  margin-left: 175px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #2D3436;
  margin-top: 48px;
`;

const GraphWrapper = styled('div')`
  background-color: white;
  padding: 16px;
  margin-top: 24px;
  margin-left: 175px;
  padding: 24px;
  display: flex;

  div {
    display: flex;
  }

  svg {
    max-width: 800px;
    margin: auto;
  }
`;

const SearchResultWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 12px;
  cursor: pointer;

  &:first-of-type {
    /* padding-bottom: 16px; */
  }

  &:last-of-type {
    /* padding-bottom: 16px; */
    border-bottom: 1px solid #BDBDBD;
  }


  &:hover {
    background-color: #f0f0f0;
  }

  >div:first-child {
    background: ${props => `url('${props.image}')`} center center;
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    margin-right: 16px;
    border: 1px solid rgba(0,0,0,.1);
  }

  div {
    p {
      margin: 2px 0;
    }
    p:first-child {
      color: #2D3436;
    }
    p:last-child {
      color: #636E72;
    }
  }
`;

const Details = styled('p')`
  margin-left: 175px;
  margin-bottom: 64px;
`;

function mapPerson(person) {
  const {
    id,
    name,
    firstName,
    lastName,
    state,
    body,
    party,
    role,
    thumbnailUrl,
    history,
    rating,
    allTimeRating,
    acuLifetimeRatings,
    trend,
  } = person;
  return {
    id,
    name,
    firstName,
    lastName,
    state,
    body,
    party,
    role,
    thumbnailUrl,
    history,
    lifetimeRating: acuLifetimeRatings ? acuLifetimeRatings[0].rating : null,
    // rating: fix(rating.aggregate.avg.rating),
    // allTimeRating: fix(allTimeRating.aggregate.avg.rating),
    // votes: fix(rating.aggregate.sum.total, '0'),
    // allTimeVotes: fix(allTimeRating.aggregate.sum.total, '0'),
    trend: Object.values(trend.reduce((acc, n) => {

      if (!acc[n.year]) {
        acc[n.year] = {
          sum: 0,
          count: 0,
          year: n.year,
        };
      }

      acc[n.year].sum += n.rating;
      acc[n.year].count += n.total;

      return acc;

    }, {})).map(n => ({ year: n.year, rating: n.sum / n.count })),
  }
}

const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function fix(n, defaultValue) {
  if (typeof n === 'number') {
    return n.toFixed(0);
  }
  return n || defaultValue || 'n/a';
}

// TODO: Pull this out and re-use
const LoadingWrapper = styled('div').attrs(props => ({ style: { height: props.height } }))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

function LoadingBlock({ height }) {
  return (
    <LoadingWrapper height={height}>
      <Loading />
    </LoadingWrapper>
  )
}

function getKey(bill) {
  return `${bill.year}-${bill.amendmentNumber || ''}-${bill.billNumber.replace(/[^ \w]/g, '')}-${bill.title.replace(/[^ \w]/g, '').toUpperCase()}`
}

function useArticles({ peopleIds, limit, issues, showDifferences = false }) {

  const [result, setResult] = useState(null);

  const { data, loading, error } = useQuery(GET_COMPARISON_ARTICLES, {
    variables: {
      peopleIds: toPostgresArray(peopleIds),
      categories: toPostgresArray(issues),
    },

    // https://github.com/relay-tools/relay-hooks/issues/57
    skip: !result && !peopleIds?.length,
  });


  useEffect(() => {
    if (data && data.articles) {
      processData(data.articles);
    } else {
      // console.warn('No query result, not processing data, showDifferences: ', showDifferences);
    }


    function processData(data) {

      const byArticle = data.reduce((acc, n) => {

        const {
          peopleId,
          articleId,
          billNumber,
          amendmentNumber,
          title,
          vote,
          year,
          acuVote,
          withAcu,
          rating,
          points,
          party,
        } = n;

        // const key = `${year}-${billNumber}-${amendmentNumber}`;
        const key = getKey(n);

        acc[key] = (acc[key] || {
          articleId,
          billNumber,
          amendmentNumber,
          title,
          acuVote,
          year,
          votes: [],
        });

        // const withAcu = vote === 0 ? null : (acuVote < 0 && vote < 0) || (acuVote > 0 && vote > 0);

        acc[key].votes.push({
          articleId,

          billNumber,
          amendmentNumber,
          title,
          peopleId,
          vote,
          rating,
          points,
          acuVote,
          withAcu,
          party,
        });

        return acc;

      }, {});

      const list = Object.values(byArticle);

      // Go through and add a ranking
      for (const article of list) {

        // A point for each vote
        article.rank = article.votes.length;

        for (let i = 0; i < article.votes.length; i++) {
          const vote = article.votes[i];

          // A poiont for voting against party, i.e., D voting with CPAC, or R voting against
          if ((vote.party === 'D' && vote.rating > 0) || vote.party === 'R' && vote.rating < 0) {
            article.rank += 1;
          }

          if (vote.vote === 0) continue;
          for (let j = i + 1; j < article.votes.length; j++) {
            const next = article.votes[j];


            if (next.vote !== 0) {
              if (vote.party === next.party) {

                // Ten points for breaking with party
                if (vote.vote !== next.vote) {
                  article.rank += 10;
                }

                // if (showDifferences) {
                //   // Ten points for voting with opposite side
                //   if (vote.vote === next.vote) {
                //     article.rank += 10;
                //   }

                // } else {
                //   // Ten points for voting against opposite side, if `showDifferences` enabled
                //   if (vote.vote !== next.vote) {
                //     article.rank += 10;
                //   }

                // }

              } else {

                if (showDifferences) {

                  // Ten points for voting against opposite side, if `showDifferences` enabled
                  if (vote.vote !== next.vote) {
                    article.rank += 10;
                  }
                } else {

                  // Ten points for voting with opposite side
                  if (vote.vote === next.vote) {
                    article.rank += 10;
                  }
                }


              }
            }
          }
        }
      }

      const ranked = list
        // .filter(a => a.rank > 0)
        .sort((a, b) => {
          if (b.rank === a.rank) {
            if (b.votes.length === a.votes.length) {
              return b.year - a.year;
            }
            return b.votes.length - a.votes.length;
          }
          return b.rank - a.rank;
        });

      setResult(ranked);
    }

  }, [data, showDifferences]);

  return {
    data: result ? result.slice(0, limit) : result,
    error,
    loading: loading || (data && !result),
  };
}


function useRepLimit(reps) {
  const [limit, setLimit] = useState(4);
  useEffect(() => {
    function update() {
      const width = window.outerWidth;
      // if (width > 1800) {
      // setLimit(5);
      // } else
      if (width > 1600) {
        setLimit(4);
      } else if (width > 1280) {
        setLimit(3);
      } else {
        setLimit(2);
      }
    }
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [reps]);
  return limit;
}


export default function ComparisonPage(props) {

  const [showIssueSelector, setShowIssueSelector] = useState(false);
  const [year, setYear] = useState();
  const [limit, setLimit] = useState(50);
  const selectorRef = useRef();
  const [reps, setReps] = useQueryParam('reps', withDefault(ArrayParam, []));
  // const [selectedIssues, setSelectedIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useQueryParam('issues', withDefault(DelimitedArrayParam, []));


  useEffect(() => {
    if (!reps || !reps.length) {
      try {
        const savedReps = JSON.parse(localStorage.getItem('reps'))
        if (Array.isArray(savedReps)) {
          setTimeout(() => {
            setReps(savedReps);
          }, 1);
        }
      } catch (e) {

      }
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('reps', JSON.stringify(reps || '[]'));
  }, [reps])


  // TODO: Parameterize based on latest year in DB
  const selectedYear = year || 2020;

  const peopleQuery = useQuery(GET_PEOPLE_QUERY, {
    variables: {
      ids: reps,
      issues: selectedIssues.length ? selectedIssues : null,
      // year: selectedYear,
      // billLimit: limit * 10,
    },
  });

  useEffect(() => {
    function hideIssueFilterOnBodyClick(e) {
      let el = e.target;
      while (el.parentElement) {
        if (el === selectorRef.current) {
          return;
        }
        el = el.parentElement;
      }
      setShowIssueSelector(false);
    }
    document.body.addEventListener('click', hideIssueFilterOnBodyClick);
    return () => document.body.removeEventListener('click', hideIssueFilterOnBodyClick);
  }, []);


  function selectPerson(person) {
    if (reps.length < 4 && !reps.find(rep => rep.id == person.id)) {
      setReps([...reps, person.id]);
    }
  }
  const show = useRepLimit(reps);

  const numPeople = peopleQuery.data ? peopleQuery.data.people.length : 0;

  let peopleInOrder = [];
  let maxes = {};

  if (numPeople) {
    peopleInOrder = reps.slice(0, show).map(repId => peopleQuery.data.people.find(p => p.id === repId)).filter(n => n).map(mapPerson);
    maxes = peopleInOrder.reduce((maxes, p) => {
      for (const key in maxes) {
        maxes[key] = Math.max(maxes[key], isNaN(+p[key]) ? 0 : +p[key]);
      }
      return maxes;
    }, {
      rating: 0,
      allTimeRating: 0,
      votes: 0,
      allTimeVotes: 0,
    });
  }

  function removeRep(id) {
    const newReps = reps.filter(repId => repId !== id);
    setReps(newReps);
  }

  return (
    <Wrapper>
      <FlexRow>
        <BackLink />
        <ShareBar snippet={`Check this rep comparison on the CPAC ratings site!`} />
      </FlexRow>

      <Heading>
        <h1>Lawmaker Comparison</h1>
        <div>
          {/* {showIssueSelector &&
            <FloatingIssueFilter ref={selectorRef}>

              <Scrollable height={500} width={250}>
                <IssueSelector issues={selectedIssues} onIssuesSelected={setSelectedIssues} multiSelectOnly={true} />
              </Scrollable>
            </FloatingIssueFilter>
          }
          <IssueFilter onClick={() => setShowIssueSelector(!showIssueSelector)}>Filter By Issue {selectedIssues.length ? `(${selectedIssues.length})` : ''}</IssueFilter> */}
          <FloatingIssueSelector labelStyle={{ fontWeight: 'bold', fontSize: 20, marginRight: 24 }} hideTriangle onIssuesSelected={setSelectedIssues} issues={selectedIssues} alignRight />

          <YearSelector value={year} onChange={setYear} />
        </div>
      </Heading>

      <RepColumnsWrapper>
        <ColumnLabel>
          <p>Most recent rating</p>
          <p>Most recent votes</p>
          <p>{selectedIssues.length ? 'All-time' : 'Lifetime'} rating</p>
          <p>{selectedIssues.length ? 'All-time' : 'Lifetime'} votes</p>
        </ColumnLabel>
        {reps.slice(0, show).map(id => <LawmakerDetail key={id} id={id} year={year} issues={selectedIssues} onClose={() => removeRep(id)} />)}
        {reps.slice(0, show).length < show && <PersonSelector prompt={reps.length ? 'Add another lawmaker' : 'Start by adding a lawmaker'} onSelect={selectPerson} exclude={reps} />}
        {getBlankCols(reps.length, show - 1)}
      </RepColumnsWrapper>

      <SectionHeader>Ratings over time</SectionHeader>

      <GraphWrapper>
        <ComparisonGraph ids={reps} issues={selectedIssues} />
      </GraphWrapper>

      <BillsMatrix reps={peopleInOrder} issues={selectedIssues} onIssuesSelected={setSelectedIssues} show={show} />

    </Wrapper>
  );
}


const BillMatrixLabel = styled('p')`
  /* line-height: 26px; */

  span, a {
    font-family: "Helvetica Neue";
    display: block;
    margin: 4px 0;
    font-weight: 400;
  }

  span:first-child,
  span:first-child > a {

    font-weight: 700;
    hyphens: auto;
  }

  height: 98px;
  padding: 0 !important;
  display: flex;

  flex-direction: column;
  justify-content: space-around;

`;

const BillMatrixColumnLabel = styled(ColumnLabel)`
  /* padding-top: 84px; */
  padding-top: 90px;
`;


const BillsMatrixRepColumn = styled(RepColumn)`
  padding-top: 0;
`;

const BillMatrixRepColumnHeader = styled(RepColumHeader)`
  margin: 0;
  padding: 16px;
`;

const ResetSort = styled('p')`
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
`;


function isMixed(reps) {
  if (!reps || !reps.length) return false;
  let { role } = reps[0];
  for (const rep of reps) {
    if (rep.role !== role) {
      return true;
    }
  }
  return false;
}

function isCrossState(reps) {
  if (!reps || !reps.length) return false;
  let { state } = reps[0];
  for (const rep of reps) {
    if (rep.state !== state) {
      return true;
    }
  }
  return false;
}

const LoadMore = styled('p')`
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;


const limitOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '250', value: 250 },
  { label: '500', value: 500 },
  { label: 'All', value: 99999 },
];

function BillsMatrix({ reps, issues, onIssuesSelected, show }) {

  const [limit, setLimit] = useQueryParam(
    "limit",
    withDefault(NumberParam, 50)
  );
  // const [limit, setLimit] = useState(50);
  const [showDifferences, setShowDifferences] = useState(false);

  const { data: articles, loading } = useArticles({
    peopleIds: (reps || []).slice(0, show).map(r => r.id),
    issues,
    limit,
    showDifferences,
  });

  if (loading || !articles || reps?.length < 2) {
    return (
      <>
        <FlexRow style={{ alignItems: 'baseline' }}>
          <SectionHeader>Voting record comparison</SectionHeader>

          <FlexRow style={{ alignItems: 'baseline' }}>
            <label style={{ marginRight: 16 }}>
              <input checked={showDifferences} type="checkbox" onChange={e => setShowDifferences(!showDifferences)} /> Show differences
            </label>
            <FloatingIssueSelector hideTriangle onIssuesSelected={onIssuesSelected} issues={issues} alignRight />
            <Select options={limitOptions} value={limitOptions.find(o => o.value === limit)} onChange={o => setLimit(o.value)} placeholder="5" />
          </FlexRow>
        </FlexRow>

        {
          reps?.length < 2 ? <Details>Select a lawmaker to view their votes.</Details> :
            (loading || !articles) ? <LoadingBlock height={300} /> :

              <Details>No bills found for the selected lawmakers.</Details>
        }

        <Footer width={1440} />
      </>
    )
  }

  return (
    <>
      <FlexRow style={{ alignItems: 'baseline' }}>
        <SectionHeader>Voting record comparison</SectionHeader>

        <FlexRow style={{ alignItems: 'baseline' }}>
          <label style={{ marginRight: 16 }}>
            <input checked={showDifferences} type="checkbox" onChange={e => setShowDifferences(!showDifferences)} /> Show differences
          </label>
          <FloatingIssueSelector hideTriangle onIssuesSelected={onIssuesSelected} issues={issues} alignRight />
          <Select options={limitOptions} value={limitOptions.find(o => o.value === limit)} onChange={o => setLimit(o.value)} placeholder="5" />
        </FlexRow>
      </FlexRow>
      {reps.length ? null : <Details>Select a lawmaker to view their votes.</Details>}
      <RepColumnsWrapper>

        <BillMatrixColumnLabel>

          {articles.map(b =>
            <BillMatrixLabel key={b.articleId}>
              <span lang="en">
                <span title={`${b.title}`}><Link to={`/bills/${b.articleId}`}>{b.title.slice(0, 36)}{b.title.length > 36 ? '...' : ''}</Link></span>
                <span title={b.rank}>{b.billNumber} ({b.year})</span>
              </span>
            </BillMatrixLabel>
          )}
        </BillMatrixColumnLabel>

        {reps.slice(0, show).map(r =>
          <BillsMatrixRepColumn key={r.articleId}>
            <PinToTop>
              <BillMatrixRepColumnHeader {...r} hideClose pointerOnPicture type="BillMatrixRepColumnHeader" />
            </PinToTop>

            {articles.map(b =>
              <>
                <Separator padding={8} key={b.id}/>

                <WithACU bill={b} personId={r.id} key={b.id} />
                {/* <p>{(b.votes.find(v => v.peopleId === r.id) || {}).vote || 'n/a'}</p> */}

              </>)}

            <Separator padding={8} invisible />

          </BillsMatrixRepColumn>
        )}

        {getBlankCols(reps.length, show)}

      </RepColumnsWrapper>

      <Footer width={1440} />
    </>
  );
}

const PlainLink = styled(Link)`
  text-decoration: none;
`;

const WithAcuWrapper = styled('div')`
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  padding: 16px 0;
  height: 48px;

  p {
    /* line-height: 29px; */
    margin: 4px 0 0 0;
    font-style: italic;
    opacity: .8;
  }
`;

function WithACU({ bill, personId }) {
  const vote = bill.votes.find(v => v.peopleId === personId);

  if (!vote) {
    return (
      <WithAcuWrapper>
        <p style={{ lineHeight: '47px' }}>n/a</p>
      </WithAcuWrapper>
    );
  }

  return (
    <PlainLink key={personId} to={`/people/${personId}?search=${bill.billNumber}&year=${bill.year}`}>
      <WithAcuWrapper>
        <WithAgainst with={vote.withAcu} />
        <p>{bill.acuVote > 0 ? 'Conservative Bill' : 'Liberal Bill'}</p>
      </WithAcuWrapper>
    </PlainLink>
  )
}


function getBlankCols(numPeople, limit = 3) {
  if (numPeople < limit) {
    return [...new Array(limit - numPeople)].map((_, i) => <BlankColumn key={i}/>);
  }
  return null;
}


