import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

const Wrapper = styled(Select)`
  width: 145px;
  z-index: 100;
  position: relative;

  > div {
    border-radius: 0;
    border-color: #BDBDBD;
  }
  
  div {
    border-color: #BDBDBD;
  }
`;



const parties = [{
  label: 'All parties',
  value: null,
}, {
  label: 'Republican',
  value: 'R',
}, {
  label: 'Democrat',
  value: 'D',
}, {
  label: 'Independent',
  value: 'I',
// }, {
//   label: 'Green',
//   value: 'G',
}];

function getInitialValue(value) {
  if (typeof value === 'string') {
    return parties.find(p => p.value === value) || parties[0];
  }
  return parties[0];
}

// TODO: Handle value from props
export default function PartySelector(props) {

  const [party, setParty] = useState(getInitialValue(props.value));

  useEffect(() => {
    if (props.value !== party) {
      setParty(getInitialValue(props.value));
    }
  }, [props.value]);

  function onChange(party) {

    const value = (party || parties[0]).value;
    if (props.onChange) {
      props.onChange(value);
    }
    setParty(party || parties[0]);
  }

  return (
    <Wrapper
      {...props}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      options={parties}
      simpleValue
      clearable={false}
      name="selected-year"
      value={{ ...party, value: party.value || '' }}
      onChange={onChange}
    />
  );
}
