import React, { useEffect, useState } from 'react';
import styled from 'styled-components';


const ResultsBreakdownWrapper = styled('div')`

  h3 {
    font-weight: bold;
  }
  p, h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    margin: 4px 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #636E72;
  }
`;

export default function ResultsBreakdown({ title, legend, items, className }) {

  if (!items) {
    return null;
  }

  return (
    <ResultsBreakdownWrapper className={className}>
      <h3><span>{title}</span> <span>{legend}</span></h3>
      {items.map(({ label, value }) => (
        <p>
          <span>{label}</span> <span>{value}</span>
        </p>)
      )}
    </ResultsBreakdownWrapper>
  )
}
