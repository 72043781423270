import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

import RatingBar from "./RatingBar";
import Pill from "./Pill";
import CompareIcon from "../icons/compare";

import colorScale from "../util/colorScale";
import stateAbbrMap from "../util/stateAbbrMap";
import partyMap from "../util/partyMap";
import mapRating from "../util/mapRating";
import formatDistrictName from "../util/formatDistrictName";
import ProfileImage from "./ProfileImage";
import Tooltip from "./Tooltip";

const PersonWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;
  margin-right: 16px;
`;

const Ranking = styled("p")`
  width: 60px;
  min-width: 60px;
  text-align: right;
  margin-right: 8px;
`;

const Details = styled("div")`
  width: 30%;
  min-width: 250px;
  margin: 0 8px;

  p {
    margin: 10px 0;
  }

  a p {
    padding-top: 4px;
  }

  > p:last-child {
    color: #636e72;
    font-size: 15px;
  }
`;

const VoteDetails = styled("div")`
  @media (max-width: 950px) {
    display: none;
  }

  min-width: 100px;
  width: 20%;

  p {
    margin: 0 16px 4px;
    color: #636e72;
  }

  p:nth-child(2) {
    margin-bottom: 0;
    font-size: 14px;
    color: #636e72;
  }
`;

const Rating = styled("div")`
  @media (max-width: 800px) {
    display: none;
  }

  margin-left: auto;

  & > p {
    text-align: right;
    color: #636e72;
    margin: 4px 0;
    font-size: 14px;
  }
`;

const CompareLink = styled(Link)`
  margin-left: 36px;
  margin-bottom: 8px;
`;

function renderVoteDetails({
  votes,
  sponsorships,
  hideVoteDetails,
  acuRatings,
  acuLifetimeRatings,
  year,
  yearsRated,
  details,
}) {
  if (details) {
    return <VoteDetails>{details}</VoteDetails>;
  }

  if (hideVoteDetails) {
    return null;
  }

  let voteDetails = [];
  if (votes) {
    voteDetails.push(
      `${votes}\u00A0vote${votes == 1 ? "" : "s"}${year ? " in " + year : " in all years"
      }`
    );
  }
  if (sponsorships) {
    voteDetails.push(`${sponsorships}\u00A0sponsorships`);
  }

  if (acuRatings || acuLifetimeRatings) {
    const years = yearsRated || (acuRatings || acuLifetimeRatings).length;
    yearsRated = `Rated for ${years} year${years == 1 ? "" : "s"}`;
  }

  return (
    <VoteDetails>
      <p>{voteDetails.join(", ")}</p>
      <p>{yearsRated}</p>
    </VoteDetails>
  );
}

const NoRating = styled("p")`
  font-style: italic;
  color: #ccc;
  margin-left: auto;
  margin-right: 16px;
  font-size: 20px;
`;

function renderRating(props) {
  if (props.rating === null || isNaN(+props.rating)) {
    return (
      <Rating>
        <NoRating>No rating</NoRating>
      </Rating>
    );
  }

  const cleanRating = props.decimal
    ? ("" + props.rating).replace(/\.00$/, "")
    : (+props.rating).toFixed(0);

  return (
    <Rating>
      <RatingBar
        showAsterisk={props.showAsterisk}
        rating={cleanRating}
        decimal={props.decimal}
      />
      <p>{props.ratingDetail}</p>
    </Rating>
  );
}

function getBody(props) {
  const { role, state } = props;

  if (state === "US") {
    return "";
  }
  if (state === "NE") {
    return " Unicameral";
  }

  switch (role) {
    case "Rep":
    case "Del":
      return " House";
    case "Asm":
      return " Assembly";
    case "Sen":
      return " Senate";
  }
  return "";
}

export default function PersonRow(props) {
  const federal = props.federal ? <Pill>Federal</Pill> : "";
  const sponsor = props.sponsor ? <Pill>Sponsor</Pill> : "";

  const image = props.thumbnailUrl;

  const lifetimeRating = getLifetimeRating(props);

  const district = formatDistrictName(props.district);

  // const chamber = (props.chamber || '')[0].toUpperCase();

  return (
    <PersonWrapper className={props.className} style={props.style}>
      {props.ranking && <Ranking>{props.ranking}.</Ranking>}

      <Tooltip title={`Click her to view ${getRole()}${getName()}'s profile`}>
        <Link to={`/people/${props.id || props.peopleId}`}>
          <ProfileImage rating={lifetimeRating} src={image} id={props.id} />
        </Link>
      </Tooltip>

      <Details>
        <Link to={`/people/${props.id || props.peopleId}`}>
          <Tooltip title={`Click here to view ${getRole()}${getName()}'s full profile`}>
            <p>
              {getRole()}
              {getName()} ({props.party}) {sponsor}
            </p>
          </Tooltip>
        </Link>
        <p>
          <a
            href={props.state === "US" ? "/congress" : `/states/${props.state}`}
          >
            {stateAbbrMap(props)}
            {getBody(props)}
          </a>
          {/* <a href={`/people?state=${props.state}&chamber=${chamber}`}>{getBody(props)}</a> */}
          {district ? ` - ${district}` : ""}
        </p>
      </Details>

      {renderVoteDetails(props)}

      {renderRating(props)}

      {props.includeCompare && (
        <CompareLink title="Compare" to={`/comparison?reps=${props.id}`}>
          <CompareIcon />
        </CompareLink>
      )}
    </PersonWrapper>
  );

  function getRole() {
    if (props.role) {
      return `${props.role}. `;
    }
    return "";
  }

  // TODO: Rip this out and just use displayName
  function getName() {
    // if (props.firstName && props.lastName) {
    //   const middle = props.middleName ? props.middleName[0] + '. ' : '';
    //   return `${props.firstName} ${middle}${props.lastName}`.replace(/[^ \w\.\-]/g, '');
    // }
    // return props.name.replace(/[^ \w\-\.Ññ]/g, '');
    return props.name;
  }

  function titleCase(str) {
    return str
      .split(" ")
      .map((s) => s[0].toUpperCase() + s.slice(1).toLowerCase())
      .join(" ");
  }
}

PersonRow.displayName = "PersonRow";

function getLifetimeRating(person) {
  try {
    if (person.acuLifetimeRatings) {
      let latestRating = { year: -Infinity, rating: null };

      for (const rating of person.acuLifetimeRatings) {
        if (!latestRating || latestRating.year < rating.year) {
          latestRating = rating;
        }
      }

      return latestRating.rating || person.lifetimeRating || null;
    }
    return person.lifetimeRating;
  } catch (e) {
    console.warn('person error', e);
    return null;
  }
}

