import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import BillOverview from '../components/BillOverview';
import BillsList from '../components/BillsList';
import BillVotes from '../components/BillVotes';
import Link from '../components/Link';
import LeftChevron from '../icons/left-chevron';
import ShareBar from '../components/ShareBar';
import Footer from '../components/Footer';

import stateAbbrMap from '../util/stateAbbrMap';
import usePageTitle from '../util/usePageTitle';


const GET_BILL_QUERY = gql`
query (
  $id: String!
) {
  ratings_articles(where: { id: { _eq: $id }}) {
    id
    title
    state
    body
    year
    billNumber
    categorizations {
      categoryId
      category {
        name
      }
    }
  }
}
`;

const Wrapper = styled('div')`
  /* margin: auto; */
  max-width: 1200px;
  margin: 0 auto;
`;

const Name = styled('h1')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #2D3436;
  margin: 24px 0 40px 0;
`;

const Separator = styled('div')`
  height: 24px;
`;

const BackLink = styled(Link)`
  color: #2D3436;
  font-size: 16px;
  display: inline-block;
  
  svg {
    margin-bottom: -1px;
    margin-right: 2px;
  }
`;

const TitleWrapper = styled('div')`
  
  h1 {
    flex: 10;
    margin-right: 16px;
    margin-bottom: 8px;
  }

  >div {
    flex: 2;
    margin-bottom: 16px;
    /* margin-bottom: 32px; */
    /* width: 120px; */
  }

  p {
    margin-top: 0;
    font-size: 24px;
    color: #636E72;
    /* margin-bottom: 16px; */
  }
`;

const FlexRow = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


export default withRouter(props => {

  const { id } = props.match.params;
  const [year, setYear] = useState(null);

  const { data, loading, error } = useQuery(GET_BILL_QUERY, {
    variables: {
      id,
      // year: year,
    }
  });

  const { ratings_articles: [article] } = data || { ratings_articles: [{title:'', categorizations: null}] };

  let search = null;
  try {
    search = article.categorizations[0].category.name;
  } catch (e) {
  }

  const issues = article.categorizations?.map(c => c.categoryId);

  const title = stripPeriod(article.title);
  const body = article.body === 'H' ? 'House of Representatives' : article.body === 'S' ? 'Senate' : '';

  usePageTitle(article.billNumber || 'Bill details');

  return (
    <Wrapper>
      <ScrollToTopOnMount/>

      {/* TODO: Replace with left chevron */}
      <FlexRow>
        <BackLink href="/bills"><LeftChevron fill="#2D3436" /> Back to search results</BackLink>
        <ShareBar snippet={`Check out CPAC's position on ${article.billNumber} – ${title}`} />
      </FlexRow>

      <TitleWrapper>
        <Name>{article.billNumber} &mdash; {title}</Name>
        <p>{article.year} &mdash; {stateAbbrMap(article)} {body}</p>
      </TitleWrapper>

      <BillOverview id={id} />
      
      <Separator />
      
      {/* <PersonPerformance id={id} year={year} /> */}
      <BillVotes id={id} />

      <Separator />

      <BillsList title="Related bills" year={year} issues={issues} hideControls={true} hideId={article.id} state={article.state} limit={10} />

      <Footer />

    </Wrapper>
  )
});

function stripPeriod(str) {
  return str.slice(-1) === '.' ? str.slice(0, str.length - 1) : str;
}