import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 12H2.54545V4.8H0V12ZM14 5.4C14 4.74 13.4273 4.2 12.7273 4.2H8.71182L9.31636 1.458L9.33545 1.266C9.33545 1.02 9.22727 0.792 9.05545 0.63L8.38091 0L4.19364 3.954C3.95818 4.17 3.81818 4.47 3.81818 4.8V10.8C3.81818 11.46 4.39091 12 5.09091 12H10.8182C11.3464 12 11.7982 11.7 11.9891 11.268L13.9109 7.038C13.9682 6.9 14 6.756 14 6.6V5.4Z" fill="white" />
  </Svg>
);
