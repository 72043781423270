import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import RatingBar from './RatingBar';
import ScrollableContainer from './ScrollableContainer';
import Rating from './Rating';
import Select from './Select';
import RatingGraph from './RatingGraph';
import SimpleTooltip from './Tooltip';

import mapRating from '../util/mapRating';
import colorScale from '../util/colorScale';

import FloatingIssueSelector from './FloatingIssueSelector';
import threshold from '../util/threshold';

import Loading from './Loading';


const GET_PERSON_QUERY = gql`
query personPerformance(
  $id: String!
  $issues: _text,
) {
  ratings_people(where: { id: { _eq: $id }}) {
    name
    lastName
    party
    id
    role
    state
    lifetimeRating

    acuRatings {
      year
      rating
    }

    acuLifetimeRatings {
      year
      rating
      total: totalVotes
    }

    ratings: acuRatings {
      year
      rating
      total
      acuVotes
    }

    issueRatings {
      category
      categoryId
      year
      acuVotes
      rating
      total
      votes
    }

  }

  performance: ratings_getPersonPerformance(args: {
    peopleId: $id,
    categories: $issues
  }) {
    peopleId
    rating
    year
    acuVotes
    votes
    total
  }
}
`;


const Wrapper = styled('div')`
  background-color: white;
  padding: 24px;
`;

const Title = styled('h2')`
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SubTitle = styled('h3')`
  font-family: Roboto, sans-serif;
  color: #2D3436;
  font-size: 16px;
  font-weight: 500;
`;

const Details = styled('p')`
  font-family: Roboto, sans-serif;
  color: #636E72;
  font-size: 14px;
`;

const Disclaimer = styled('p')`
  color: #B2BEC3;
  font-size: 14px;
`;

const NoData = styled('p')`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #666;
  margin: 64px 0;
`;

const Placeholder = styled('div')`
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

function toPostgresArray(array) {
  if (!array || array.length === 0) {
    return null;
  }
  return `{${array.join(', ')}}`;
}

export default function PersonPerformance(_props) {

  let props = { ..._props };

  const { onYearSelect = () => { } } = props;

  if (!props.id) {
    return;
  }

  const [selectedIssues, setSelectedIssues] = useState(props.issues || []);
  function onSelectIssues(issues) {
    if (props.onSelectIssues) {
      props.onSelectIssues(issues);
    }
    setSelectedIssues(issues);
  }

  const issues = props.issues || selectedIssues;

  const { data, loading, error } = useQuery(GET_PERSON_QUERY, {
    variables: {
      id: props.id,
      // year: props.year,
      // prevYear: props.year - 1,
      issues: toPostgresArray(issues),
    }
  });

  if (data) {
    const { ratings_people: [person], performance } = data;

    // const rating = mapRating(person.rating_aggregate.aggregate.avg.rating);
    // const rating = Math.round((props.year == 2018 ? person.rating2018 : props.year == 2017 ? person.rating2017 : person.lifetimeRating) || person.lifetimeRating);
    const maxYear = person.ratings.reduce((max, n) => Math.max(max, n.year), 0);
    const rating = fix((person.ratings.find(n => n.year === (props.year || maxYear)) || {}).rating);

    // const lifetimeRating = mapRating(person.lifetimeRating_aggregate.aggregate.avg.rating);

    props = {
      ...person,
      ...props,
      ratingYear: props.year || maxYear,
      rating,
      // lifetimeRating,
      issueRatings: person.issueRatings || [],
      // votes: person.votes_aggregate.aggregate.count,
      // sponsorships: person.sponsorships_aggregate.aggregate.count,
      // lifetimeVotes: person.lifetimeVotes_aggregate.aggregate.count,
      // lifetimeSponsorships: person.lifetimeSponsorships_aggregate.aggregate.count,
      trend: [],
      performance,
    }
  }

  if (loading) {
    return (
      <Wrapper>
        {/* <Title>Rating in <TextSelector options={issues} value={issue} onChange={setIssue} /></Title> */}
        {/* <Title>Rating in <Select options={issues} value={issue} onChange={setIssue} /></Title> */}
        <Title>Rating in <FloatingIssueSelector issues={issues} label={issues.length ? `${issues.length} categor${issues.length > 1 ? 'ies' : 'y'}` : 'All categories'} style={{ marginLeft: 8 }} onIssuesSelected={onSelectIssues} /></Title>
        <Placeholder>
          <Loading />
        </Placeholder>
      </Wrapper >
    );
  }

  if (error) {
    console.warn('PersonPerformance error: ', error);
    return <p>Something went wrong.</p>
  }

  const performance = (props.performance || []).filter(n => {
    return threshold(n.votes / n.total, props.state, props.chamber || props.role, n.year, props.id)
  });

  // props.trend = getTrend(filteredRatings);
  props.trend = performance || [];

  // const current = getRatingAndVotesForYear(filteredRatings, props.year);
  const current = performance.find(p => !props.year || p.year === props.year) || { rating: null, year: props.year };
  // const current = performance.find(p => (!props.year && props.ratingYear === p.year) || p.year === props.year) || { rating: null, year: props.year };
  props.rating = current.rating;
  props.votes = current.votes;

  // const previous = getRatingAndVotesForYear(filteredRatings, current.year - 1);
  const previous = performance.find(p => p.year < current.year && p.rating !== null) || { rating: null, year: props.year };
  props.previousRating = previous.rating;
  props.previousVotes = current.previousVotes;

  // const lifetime = getRatingAndVotesForYear(filteredRatings, null, true);

  // TODO(Joshua): Filter out years that don't meet threshold, when no issues are selected
  const lifetime = performance.reduce((acc, n) => {
    acc.acuVotes += n.acuVotes || 0;
    acc.votes += n.votes || 0;
    acc.total += n.total || 0;
    acc.rating += n.rating || 0;
    acc.year = Math.max(n.year, acc.year);
    return acc;
  }, {
    rating: 0,
    acuVotes: 0,
    votes: 0,
    total: 0,
    year: 0,
  });

  if (issues.length) {
    lifetime.rating /= performance.length;
  } else {
    lifetime.rating = 100 * lifetime.acuVotes / lifetime.votes;
  }

  props.lifetimeRating = lifetime.rating;
  props.lifetimeVotes = lifetime.votes;

  // const previousLifetime = getRatingAndVotesForYear(filteredRatings, props.year ? current.year : current.year - 1, true);

  const previousLifetime = performance.reduce((acc, n) => {
    if ((props.year && n.year <= props.year) || (!props.year && n.year < lifetime.year)) {
      acc.acuVotes += n.acuVotes || 0;
      acc.votes += n.votes || 0;
      acc.total += n.total || 0;
      acc.rating += n.rating || 0;
      acc.year = Math.max(n.year, acc.year);
    }
    return acc;
  }, {
    rating: 0,
    acuVotes: 0,
    votes: 0,
    total: 0,
    year: 0,
  });

  if (issues.length) {
    previousLifetime.rating /= performance.length;
  } else {
    previousLifetime.rating = 100 * previousLifetime.acuVotes / previousLifetime.votes;
  }


  function getTrend(filteredRatings) {
    const trend = Object.values(filteredRatings.filter(n => n.votes > 0).reduce((acc, n) => {
      // if (issue.value && issue.value != n.categoryId) {
      //   return acc;
      // }

      if (!acc[n.year]) {
        acc[n.year] = {
          sum: 0,
          count: 0,
          year: n.year,
        };
      }

      // Group by year
      // if (threshold(n.votes / n.total, props.state, props.role, n.year)) {
      acc[n.year].sum += n.acuVotes;
      acc[n.year].count += n.votes;
      // }

      return acc;

    }, {})).map(n => ({
      rating: 100 * n.sum / n.count,
      year: n.year,
      acuVotes: n.sum,
      total: n.count,
    }));

    return trend;
  }


  function getThisYearChange() {

    // TODO: Remove
    // return '';

    if (!current.rating) {
      return '';
    }

    if (!previous) {
      return `Not rated in previous year`;
    }

    if (previous.year === -1) {
      return `Not rated in previous year`;
    }

    const ratingChange = current.rating - previous.rating;

    if (isNaN(ratingChange)) {
      return `Not rated in ${previous.year}`;
    }

    if (ratingChange === 0) {
      return `No change from ${previous.year}`;
    }

    if (Math.abs(ratingChange) < 1) {
      return `${ratingChange >= 0 ? '+' : ''}${ratingChange.toFixed(2).replace(/(\.0)?0$/, '')} since ${previous.year}`;
    }

    return `${ratingChange >= 0 ? '+' : ''}${Math.floor(ratingChange)} since ${previous.year}`;
  }

  function getLifetimeChange() {
    const current = lifetime;
    const previous = previousLifetime;

    if (!current.rating) {
      return '';
    }

    if (!previous) {
      return `Not rated in previous year`;
    }

    if (previous.year === -1) {
      return `Not rated in previous year`;
    }

    const ratingChange = current.rating - previous.rating;

    if (isNaN(ratingChange)) {
      return `Not rated in ${previous.year}`;
    }

    if (ratingChange === 0) {
      return `No change from ${previous.year}`;
    }


    return `${ratingChange >= 0 ? '+' : ''}${Math.floor(ratingChange)} since ${previous.year}`;
  }

  function getDetails(votes, sponsored) {
    // return '';
    const v = votes ? `${votes} votes` : 'No votes';
    // const v = votes ? `${votes} voted on` : 'None voted on';

    return v;

    const s = sponsored ? `${sponsored} sponsored` : 'none sponsored';
    return `${v}, ${s}`;
  }

  return (
    <Wrapper>
      {/* <Title>Rating in <TextSelector options={issues} value={issue} onChange={setIssue} /></Title> */}
      {/* <Title>Rating in <Select options={issues} value={issue} onChange={setIssue} /></Title> */}
      <Title>
        <span>Rating in </span>
          <FloatingIssueSelector
            issues={issues}
            label={issues.length ? `${issues.length} categor${issues.length > 1 ? 'ies' : 'y'}` : 'All categories'}
            style={{ marginLeft: 8 }}
            onIssuesSelected={onSelectIssues}
          />
      </Title>
      <Row justify="between">

        <Col md={6} lg={5}>

          <Row>
            <Col sm={6}>
              <SubTitle>Category rating ({current.year || props.ratingYear})*</SubTitle>
              {
                props.rating !== null ?
                  <Rating rating={props.rating} detail={getThisYearChange()} /> :
                  <NoData>No data</NoData>
              }

              <SubTitle>{current.year || props.ratingYear} votes</SubTitle>
              <Details>{getDetails(props.votes, props.sponsored)}</Details>
            </Col>

            <Col sm={6}>
              <SubTitle>Category rating (all years)*</SubTitle>
              <Rating rating={props.lifetimeRating} detail={getLifetimeChange()} decimal />

              <SubTitle>Votes from all years</SubTitle>
              <Details>{getDetails(props.lifetimeVotes, props.lifetimeSponsorships)}</Details>
            </Col>
          </Row>

          <Disclaimer>*Based on votes cast on bills rated by CPAC</Disclaimer>

        </Col>

        <Col md={6} lg={7}>
          <SubTitle>Trend: Rating over time</SubTitle>
          <RatingGraph height="210" data={props.trend} onYearSelect={onYearSelect} />
        </Col>

      </Row>
    </Wrapper >
  )
}



function getRatings({ ratings, issueRatings }, issue, year) {

  const mostRecentYear = ratings.reduce((max, n) => Math.max(max, n.year), 0);

  let trend, rating, lifetimeRating, selectedVotes, lifetimeVotes;

  lifetimeVotes = issueRatings.reduce((total, n) => total + n.total, 0);

  if (!issue || !issue.value) {

    trend = averageByYear(ratings);
    lifetimeRating = average(ratings.map(n => n.rating));
    rating = average(ratings.filter(n => n.year === (year || mostRecentYear)).map(n => n.rating));

  } else {

    const selectedRatings = issueRatings
      .filter(r => r.categoryId.includes(issue.value));

    selectedVotes = selectedRatings.filter(r => (year || mostRecentYear)).reduce((total, n) => total + n.total, 0);


    rating = average(selectedRatings.filter(r => r.year == (year || mostRecentYear)).map(n => n.rating));

    lifetimeRating = average(selectedRatings.map(n => n.rating));

    trend = averageByYear(selectedRatings);

    // const ratingChange = (props.rating2018 - (props.rating2017 || 0)) / 100;

    // if (trend.length === 1) {

    //   trend.push({
    //     year: trend[0].year - 1,
    //     rating: trend[0].rating,
    //   });
    // }
  }

  return {
    trend,
    rating,
    lifetimeRating,
    selectedVotes,
    lifetimeVotes,
    mostRecentYear,
  };
}

function average(nums) {
  return nums.reduce((acc, n) => acc + n, 0) / nums.length;
}


function averageByYear(ratings) {
  return Object.values(
    ratings.reduce((acc, n) => {
      if (!acc[n.year]) {
        acc[n.year] = {
          count: 0,
          sum: 0,
          year: n.year,
        };
      }

      acc[n.year].count++;
      acc[n.year].sum += n.rating;

      return acc;
    }, {})
  ).map(({ count, sum, year }) => ({ rating: sum / count, year }));
}

function fix(n) {
  try {
    return n.toFixed(0);
  } catch (e) {
    return n;
  }
}