import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" fill="white" stroke="#BDBDBD" />
    <rect x="6" y="6" width="4" height="4" fill="#5C5C5C" />
  </svg>
);
