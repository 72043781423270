import React, { Fragment, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link as BrowserLink, withRouter } from 'react-router-dom';

// import Logo from '../../images/acuf-logo-dark.png';
import Logo from '../../images/acuf-logo.png';
import Alert from '../components/Alert';
import useDimensions from '../util/useDimensions';

import menuSvg from '../../images/menu.svg';
import closeSvg from '../../images/close.svg';


const Container = styled('div')`
  background-color: #ffffff;
  padding: 3px;
  height: 60px;
  display: flex;
  z-index: 91000;
`;

const Content = styled('div')`
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  justify-content: center;

  width: 100%;
  color: white;

  a {
    color: #636E72;
    margin-right: 36px;
    text-transform: uppercase;

    @media (max-width: 1400px) {
      margin-right: 24px;
    }
  }

  display: flex;
  flex-direction: row;
  align-items: center;

  .header {
    font-size: 36px;

    font-weight: 900;

    @media (max-width: 1100px) {

    }
  }
  
  img.menu-button {
    display: none;
    cursor: pointer;
  }

  @media (max-width: 800px) {
    justify-content: space-between;
    margin: 0 12px;
    img.menu-button {
      display: block;
    }
  }
`;

const Link = styled(BrowserLink)`
  text-decoration: none;
  padding: 24px 0 20px;
  line-height: 1;
  white-space: nowrap;
  

  @media (min-width: 800px) {
    ${(/** @type {any} */ props) => {

      if (props.active === 'true') {
        return `
          color: #D63031;
          font-weight: bold;
          border-bottom: 5px solid #D63031;
        `;
      }

      return `
        border-bottom: 5px solid transparent;
      `;
    }}
  }

  @media (max-width: 800px) {

    padding: 6px 12px 6px;
    margin: 24px 0;

    ${(/** @type {any} */ props) => {

      if (props.active) {
        return `
          color: #D63031;
          font-weight: bold;
          border-left: 5px solid #D63031;
        `;
      }

      return `
        border-left: 5px solid transparent;
      `;
    }}
  }
`;

const ExternalLink = styled('a')`
  text-decoration: none;
  padding: 24px 0 20px;
  line-height: 1;
  white-space: nowrap;
  border-bottom: 5px solid transparent;
  font-weight: 700;
  color: #b80000 !important;
`;


const LogoImage = styled('img')`
  height: 53px;
  margin-top: 7px;

  image-rendering: -webkit-optimize-contrast;
  
  @media (max-width: 1235px) {
    height: 40px;
  }
  
  @media (max-width: 1100px) {
    height: 32px;
  }
`;


const MenuWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 9999;
  overflow-y: auto;

  > img.close-button {
    position: absolute;
    top: 20px;
    right: 16px;
    display: none;
    height: 24px;
    cursor: pointer;
  }

  .header {
    margin-top: 32px;
    margin-bottom: 16px;
    img {
      height: 60px;
    }
    display: none;
  }

  @media (max-width: 800px) {

    .header {
      display: block;
    }

    ${props => props.isOpen === 'true' ? `
        background-color: white;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        img.close-button {
          display: block;
        }
      ` : `
      background-color: blue;
      display: none;
    `}
  }
`;




export default withRouter(props => {

  const [menuOpen, setMenuOpen] = useState(false);

  const isSelected = path => {
    return ''+(
      props.location.pathname.includes(path) &&
      (path !== '/' || path === props.location.pathname)
    );
  };
  const makeLink = ({ path, label }) => <Link key={path} id={path} to={path} active={isSelected(path)}>{label}</Link>;

  const dimensions = useDimensions();

  const links = [
    // { path: '/', label: 'Home' }, // TODO: Show on wide screens
    { path: '/congress', label: 'Congress' },
    { path: '/states', label: dimensions.width < 1235 ? 'States' : 'State Legislatures' },
    { path: '/people', label: 'Lawmakers' },
    dimensions.width < 1000 ? null : { path: '/comparison', label: 'Compare Lawmakers' },
    { path: '/issues?multiselect=1', label: 'Issues' },
    { path: '/bills', label: dimensions.width < 1150 ? 'Bills' : 'Bill Search' },
  ].filter(n => n);

  function handleCloseClick(e) {
    if (e.currentTarget !== e.target) {
      setMenuOpen(false);
    }
  }

  return (
    <Fragment>
      {/* <Alert noBorderRadius={true} /> */}
      <Container>
        <Content>
          <BrowserLink className="header" to="/">
            <LogoImage src={Logo} />
          </BrowserLink>

          <MenuWrapper isOpen={''+menuOpen} onClick={handleCloseClick}>

            <BrowserLink className="header inner" to="/">
              <LogoImage src={Logo} />
            </BrowserLink>

            {links.map(makeLink)}
            <ExternalLink
              id="donate"
              href="https://secure.foundation.conservative.org/ratings"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </ExternalLink>

            <img className="close-button" src={closeSvg} onClick={() => setMenuOpen(false)} />
          </MenuWrapper>

          <img className="menu-button" src={menuSvg} onClick={() => setMenuOpen(true)} />

        </Content>
      </Container>
    </Fragment>
  )
});
