import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PersonSelector from "../pages/Comparison/PersonSelector";

export default () => {
  const history = useHistory();
  const [zipcode, setZipcode] = useState("");

  function update(e) {
    const val = e.target.value;
    if (!val || val.match(/^\d+$/)) {
      setZipcode(val);
    }
  }

  function go(e) {
    if (e) {
      e.preventDefault();
    }
    history.push(`/people?zipcode=${zipcode}&year=latest`);
  }

  function openPerson(p) {
    history.push(`/people/${p.id}`);
  }

  return (
    <Wrapper onSubmit={go}>
      <label>Find your legislators by name: </label>
      <PersonSelector onSelect={openPerson} />

      <span>
        <label for="zip">or by zip code: </label>
        <Input
          id="zip"
          placeholder="Zip code"
          value={zipcode}
          onChange={update}
        />
        <Button disabled={zipcode.length !== 5} onClick={go}>
          Search
        </Button>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled("form")`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 24px;
  display: block;
  line-height: 50px;
  text-align: center;
  
  * {
    line-height: initial;
    text-align: left;
    p {
      white-space: nowrap;
    }
  }

  & > * {
    display: inline-block;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }

    margin-left: 0;
  }

  label {
    white-space: nowrap;
    font-size: 18px;
  }

  & > span {
    white-space: nowrap;
    > * {
      display: inline-block;
    }
  }
`;

const Input = styled("input")`
  padding: 9px 8px 7px 10px;
  border: 1px solid #bdbdbd;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  width: 100%;
  box-sizing: border-box;
  width: 100px;
  margin-right: 12px;
  margin-left: 12px;

`;

const Button = styled("button")`
  padding: 9px 8px 7px 10px;
  border: 1px solid #bdbdbd;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #444444;
  box-sizing: border-box;
  cursor: pointer;
  background: white;
  margin-right: 0;
`;
