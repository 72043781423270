import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import StateMap from '../../components/StateMap';
import { VictoryChart, VictoryTheme, VictoryLine, VictoryAxis, VictoryTooltip } from 'victory';
import Leaderboard from '../../components/Leaderboard';

import checkmarkImg from '../../../images/checkmark.svg';
import clockImg from '../../../images/clock.svg';
import xmarkImg from '../../../images/xmark.svg';


const IssueStatsWrapper = styled('div')`
  background: white;
  padding: 24px;

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2D3436;
  }
`;



const Supports = styled('span')`
  color: white;
  background: ${props => props.support ? '#CC4748' : '#0984E3' };
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 8px;
`;

// const IssueRowWrapper = styled('div')

const IssueRowRender = props => {

  const imgMap = {
    'In Progress': clockImg,
    'Defeated': xmarkImg,
    'Passed': checkmarkImg,
  };

  return (
    <Row {...props}>
      <Col md={6}>
        <p><strong>{props.billNumber}</strong> &mdash; {props.title}</p>
        <p></p>
      </Col>
      <Col md={3}>
        <p><img src={imgMap[props.status]}/> {props.status}</p>
        <p>{props.yea} yea, {props.nay} nay</p>
      </Col>
      <Col md={3}>
        <Supports support={props.support}>{props.support ? 'Conservative' : 'Liberal'}</Supports>
      </Col>
    </Row>
  );
};

const IssueRow = styled(IssueRowRender)`
  padding: 8px;
`;


export default function IssueBills(props) {

  const bills = [];

  return (
    <IssueStatsWrapper>
      <h2>Bills</h2>
      {bills.map(p => <IssueRow {...p}/> )}
    </IssueStatsWrapper>
  )
}
