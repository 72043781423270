import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryTheme,
  VictoryLabel,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory';
import { Point } from 'victory-core';

import colorScale from '../util/colorScale';

import { scaleLinear } from 'd3';


const defaultGray = '#636E72';

const axisStyle = {

  axis: {
    stroke: defaultGray,
  },
  tick: {
    stroke: defaultGray,
    size: 0,
  },
  tickLabels: {
    fill: defaultGray,
  }
};

const lineStyle = {
  data: { stroke: "url(#linear)" }
};

function ScatterPoint(props) {
  const fill = props.datum && typeof props.datum.y === 'number' ? colorScale(props.datum.y) : '#000000';
  return <Point
    {...props}
    style={{ ...props.style, fill, cursor: 'pointer' }}
  />;
}

export default function RatingGraph(props) {

  const maxAcuVotes = (props.data || []).reduce((acc, n) => Math.max(acc, n.acuVotes || 0), 0);
  const sizeScale = scaleLinear().domain([0, maxAcuVotes]).range([2, 6]);

  const onYearSelect = props.onYearSelect || (() => { });

  const ratingData = (props.data || []).map(n => ({
    x: n.year,

    // Stupid hack to get Jim Jordan's perfect score to show a line
    y: Math.min(n.rating, 100 - (Math.random() * .01)),
    // label: `${n.year}: ${typeof n.rating === 'number' ? n.rating.toFixed(2) : n.rating}%`,
  }));

  const ratingPoints = (props.data || []).map(n => {

    let fraction = '';
    if (typeof n.total === 'number') {
      if (n.total === 0) {
        fraction = 'no votes';
      } else {
        fraction = ` ${n.acuVotes}/${n.total}`;
      }
    }

    let rating = n.rating;
    if (typeof n.rating === 'number') {
      rating = n.rating.toFixed(2).replace(/\.?0+$/, '');
    }

    return ({
      x: n.year,
      y: n.rating,
      acuVotes: n.acuVotes,
      label: [`${rating}%`, fraction, n.year].filter(n => n).join('\n'),
    });
  });

  // TEST DATA
  // const ratingData = [
  //   { x: 2010, y: 0 },
  //   { x: 2011, y: 10 },
  //   { x: 2012, y: 25 },
  //   { x: 2013, y: 50 },
  //   { x: 2014, y: 75 },
  //   { x: 2015, y: 100 },
  //   { x: 2016, y: 80 },
  //   { x: 2017, y: 44 },
  //   { x: 2018, y: 5 },
  //   { x: 2019, y: 2 },
  //   { x: 2020, y: 16 },
  // ]

  const min = ratingData.reduce((acc, n) => Math.min(acc, n.y), 100);
  const max = ratingData.reduce((acc, n) => Math.max(acc, n.y), 0);

  const height = !isNaN(+props.height) ? +props.height : 160;

  // console.log({
  //   maxAcuVotes,
  //   sizeScale,
  //   onYearSelect,
  //   ratingData,
  //   ratingPoints,
  //   min,
  //   max,
  //   height,
  // });

  return (
    <>
      <svg style={{ position: 'absolute' }}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={colorScale(max)} />
            <stop offset="100%" stopColor={colorScale(min)} />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        style={{ parent: { maxWidth: "100%" } }}
        // theme={VictoryTheme.material}
        height={height}
        padding={{ top: 8, left: 32, bottom: 32, right: 16 }}
        containerComponent={<VictoryVoronoiContainer />}
      >

        <VictoryAxis
          tickFormat={(d, i) => d % 1 != 0 ? '' : '' + d}
          style={axisStyle}
        />

        <VictoryAxis
          dependentAxis

          domain={[0, 100]}
          style={{
            ...axisStyle,
            grid: { stroke: "#e0e0e0" },
          }}

        />

        <VictoryLine
          style={{
            data: { stroke: "url(#linear)" },
            parent: { border: "1px solid #ccc" }
          }}
          interpolation="monotoneX"
          data={ratingData}
        // labels={({ datum }) => datum.}
        />

        <VictoryScatter data={ratingPoints}
          size={d => sizeScale(d.datum.acuVotes)}
          style={{
            data: { fill: "url(#linear)" },
            parent: { border: "1px solid #ccc" },
          }}
          labelComponent={
            <VictoryTooltip
              cornerRadius={2}
              flyoutStyle={{
                stroke: 'none',
                fill: '#f0f0f0',
              }}
              labelComponent={<VictoryLabel lineHeight={1.5} backgroundPadding={6} />}
            />
          }
          dataComponent={<ScatterPoint />}
          events={[{
            target: "data",
            eventHandlers: {
              onClick: () => [{
                target: "data",
                mutation: (props) => {
                  if (props.datum && typeof props.datum.x === 'number') {
                    onYearSelect(props.datum.x);
                  }
                }
              }]
            }
          }]}
        />

      </VictoryChart>
    </>
  )
}
