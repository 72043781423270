import React, { useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';


const Icon = styled('p')`
  display: block;
  padding: 7px 8px 9px 7px;
  border: 1px solid #bdbdbd;
  border-radius: 100px;
  width: 16px;
  height: 16px;
  text-align: center;
  cursor: pointer;
  /* color: #636E72; */
  color: #636E72;
  font-size: 20px;
  margin: 0;
  background-color: white;
  font-family: serif;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
`;

export default (props) => {
  const [id] = useState(`tooltip-${Math.random() * 1000000 | 0}`);
  return (
    <>
      <Icon data-for={id} data-tip={props.children}>i</Icon>
      <ReactTooltip id={id} />
    </>
  )
}