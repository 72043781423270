import styled from 'styled-components';

function propsToSvgColor({ color, stroke, fill }) {

  if (color) {
    return `
      fill: ${color};
      stroke: ${color};
  `;
  }

  if (stroke && fill) {
    return `
      stroke: ${stroke};
      fill: ${fill};
    `;
  }

  if (fill) {
    return `
      fill: ${fill};
      stroke: transparent;
    `;
  }

  if (stroke) {
    return `stroke: ${stroke};`;
  }

}


function log(fn) {
  return props => {
    const color = fn(props);
    return color;
  }
}

export default styled('svg')`
  path {
    ${propsToSvgColor}
  }
`;