import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';

export default (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="15" height="15" fill="white" stroke="#BDBDBD" />
    <path d="M7.61039 12L3 8.69841L4.1039 7.1746L7.22078 9.39683L11.5065 4L13 5.14286L7.61039 12Z" fill="#5C5C5C" />
  </svg>
);
