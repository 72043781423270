import React from 'react';
import styled from 'styled-components';

import Rating from './Rating';


const SubTitle = styled('h3')`
  font-family: Roboto, sans-serif;
  color: #2D3436;
  font-size: 16px;
  font-weight: 500;
`;

const NoData = styled('p')`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #666;
  margin: 64px 0;
`;


export default
  function RatingWidget({
    title,
    rating,
    detail,
    decimal,
  }) {
  return (
    <>
      <SubTitle>{title}</SubTitle>
      {
        rating !== null ?
          <Rating rating={rating} detail={detail} decimal={decimal} /> :
          <NoData>No data</NoData>
      }
    </>
  )
}
