import React from "react";
import {
  VictoryChart,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLegend
} from "victory";

import colorScale from "../util/colorScale";

const defaultGray = "#636E72";

const axisStyle = {
  axis: {
    stroke: defaultGray,
  },
  tick: {
    stroke: defaultGray,
    size: 0,
  },
  tickLabels: {
    fill: defaultGray,
  },
};

export default function MultiRatingGraph(props) {
  const ratingDatasets = (props.data || []).map((dataset) =>
    dataset.data.map((datapoint) => ({
      ...datapoint,
      x: datapoint.year,
      y: Math.min(datapoint.rating, 100 - Math.random() * 0.01),
      fill: dataset.fill,
      color: dataset.color,
    }))
  );

  const min = ratingDatasets.flat().reduce((acc, n) => Math.min(acc, n.y), 100);
  const max = ratingDatasets.flat().reduce((acc, n) => Math.max(acc, n.y), 0);

  const height = !isNaN(+props.height) ? +props.height : 160;

  const onLegendLabelClick = () => {
    return [
      {
        target: "labels",
        mutation: (mutationProps) => {
          props.toggle(mutationProps.datum.name);
        },
      },
    ];
  };

  const renderLines = () => {
    return ratingDatasets.map((dataset) => {
      if (dataset.length === 0) {
        return;
      }

      const { x, y, color } = dataset[0];
      return (
        <VictoryLine
          key={`${x}-${y}`}
          style={{
            data: { stroke: color },
          }}
          data={dataset}
        />
      );
    });
  };

  const renderPoints = () => {
    return ratingDatasets.map((dataset) => {
      if (dataset.length === 0) {
        return;
      }

      const { x, y, fill, color } = dataset[0];
      return (
        <VictoryScatter
          key={`${x}-${y}`}
          style={{
            data: {
              fill,
              stroke: color,
              strokeWidth: 3,
            },
            labels: {
              fontSize: 12,
              fill: color,
            },
          }}
          size={3}
          data={dataset}
          labelComponent={
            <VictoryTooltip
              cornerRadius={2}
              flyoutStyle={{
                stroke: "none",
                fill: "#f0f0f0",
              }}
              labelComponent={
                <VictoryLabel lineHeight={1.5} backgroundPadding={6} />
              }
            />
          }
          labels={(d) => props.generatePointLabelText(d.datum)}
        />
      );
    });
  };

  return (
    <>
      <svg style={{ position: "absolute" }}>
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={colorScale(max)} />
            <stop offset="100%" stopColor={colorScale(min)} />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        style={{ parent: { maxWidth: "100%" } }}
        height={height}
        padding={{ top: 5, left: 32, bottom: 70, right: 16 }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis
          tickFormat={(d, i) => (d % 1 != 0 ? "" : "" + d)}
          style={axisStyle}
        />

        <VictoryAxis
          dependentAxis
          domain={[0, 100]}
          style={{
            ...axisStyle,
            grid: { stroke: "#e0e0e0" },
          }}
        />

        {renderLines()}

        {renderPoints()}

        <VictoryLegend
          x={25}
          y={170}
          titleOrientation="left"
          orientation="horizontal"
          rowGutter={-15}
          data={props.legendData}
          itemsPerRow={3}
          style={{
            data: {
              cursor: 'pointer'
            },
            labels: {
              cursor: 'pointer'
            }
          }}
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: onLegendLabelClick,
              },
            },
            {
              target: "labels",
              eventHandlers: {
                onClick: onLegendLabelClick,
              },
            },
          ]}
        />
      </VictoryChart>
    </>
  );
}
