import styled from 'styled-components';

const Alert = styled.div`
  margin-bottom: 30px;
  text-align: center;
  background: #b80000;
  border-radius: ${(props) => props.noBorderRadius ? "0" : "6px"};
  padding: 24px 12px;
  color: #fff;
  font-size: 1.1rem;

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default {
  Alert,
};
