import React from "react";
import styled from "styled-components";
import ArrowImage from "../../images/arrow.svg";
import RatingLegendImage from "../../images/rating-legend.svg";
import MapWidget from "../components/Map";
import ImportantRightNow from "../components/ImportantRightNow";
import Leaderboard from "../components/Leaderboard";
import IssuesToWatch from "../components/IssuesToWatch";
import Footer from "../components/Footer";
import usePageTitle from "../util/usePageTitle";

import { Row, Col, Container, Visible, Hidden } from "react-grid-system";

const Wrapper = styled("div")`
  max-width: 1200px;
  margin: 0 auto;

  .inner {
    max-width: 800px;
  }

  h1 {
    font-size: 32px;
    font-family: "Helvetica Neue";
    margin-top: 0;
  }

  h2 {
    font-size: 24px;
    font-family: "Helvetica Neue";
  }

  p,
  li {
    line-height: 1.5;
  }
`;

const Spacer = styled("div")`
  margin: 24px;
`;

export default (props) => {
  usePageTitle("About");
  return (
    <Wrapper>
      <div className="inner">
        <h1>About the Center for Legislative Accountability</h1>

        <p>
          The Center for Legislative Accountability (CLA), an initiative of the
          Conservative Political Action Coalition (CPAC) Foundation, works to
          promote government transparency while carrying on the American
          Conservative Union Foundation’s (ACUF) over 50-year mission of holding
          lawmakers accountable through the publishing of their voting records.
          As the successor to the ACUF, the CLA is home to the longest-running
          conservative congressional scorecard, and its state ratings program
          became the first and only operation to annually score all 8,000
          lawmakers in the 50 states across every policy area. The CLA awards
          lawmakers who achieve scores of at least 80% on its scorecards at the
          annual Conservative Political Action Conference, which is the largest
          and most influential gathering of conservatives in the world.
        </p>

        <h2>History</h2>

        <p>
          The American Conservative Union (ACU) was founded in 1964 by William
          F. Buckley following the defeat of Barry Goldwater in the presidential
          election. From its inception, ACU served as the umbrella organization
          for the conservative movement, perpetually steering it in the
          direction of true conservatism which best protects our natural rights,
          including the right to life, liberty and property. In 1971, ACU
          launched its _Ratings of Congress_ to provide a comprehensive
          evaluation of the voting records of every Member of Congress. The
          Ratings quickly became considered the “gold standard” and over the
          following decades have been utilized by activists, voters, academics
          and elected officials to determine which lawmakers best adhere to
          conservative principles. In 2011, these Ratings were expanded into
          state legislatures as part of a five-state pilot program. By
          2015, _Ratings of the States_ grew to include all 50 states and all 99
          legislative chambers.
        </p>

        <h2>The Ratings Research Process</h2>

        <p>
          The CLA’s team of policy analysts annually review the over 105,000
          pieces of legislation introduced across Congress and each of the 50
          state legislatures. The CLA then selects roughly 3,500 of the top roll
          calls (over 280,000 individual lawmaker votes) that best reflect how
          elected officials view the role of government in an individual’s life.
          The sampling includes the full spectrum of policy issues across the
          CLA’s glossary of 186 policy issue areas and is performed through a
          rigorous eight step process to ensure an accurate representation of
          each state’s legislative session. Some of key areas focused upon
          include taxation, spending, education, labor, licensure, regulation,
          environment, life, free speech, Second Amendment rights, and national
          security.
        </p>

        <h2>The Scorecard Methodology</h2>

        <p>
          When determining a position on a particular issue, the CLA begins by
          defining conservatism: the political philosophy that sovereignty
          resides in the person. Then, we apply the Founders' belief that the
          proper role of government is to protect life, liberty and property.
          Each lawmaker is evaluated on a zero to 100 scale, with a score of 100
          representing a voting record that is perfectly aligned with
          conservative principles. Lawmakers who earn a score of 90% or higher
          are presented with the CLA's highest honor, the Award for Conservative
          Excellence, while those who earn a score of 80% or higher, but less
          than 90%, are presented with the Award for Conservative Achievement.
          Additionally, any lawmaker who earns a score of 10% or lower qualifies
          for the CLA's Coalition of the Radical Left.
        </p>

        <h2>State Rankings</h2>

        <p>
          The CLA also provides scores for each of the states and their
          legislative chambers, which is based upon the average scores of all
          the lawmakers within each respective chamber. A legislature's overall
          score is calculated by averaging each state's two legislative chambers
          together in order to better reflect the differences in political power
          between the chambers. (The exception being Nebraska, the nation's only
          unicameral legislature comprising a single legislative chamber, on
          which the state's overall score was based.)
        </p>

        <Spacer />

        <h2>Center for Legislative Accountability Staff</h2>

        <ul>
          <li>Fred McGrath, Director</li>
          <li>Francis Finnegan, Data Analyst and Manager</li>
          <li>Zoe Reese, Operations Associate</li>
          <li>Bryan Axler, Policy Analyst</li>
          <li>Larry Hart, Senior Fellow</li>
          <li>Joshua Hutt, Web Developer</li>
          <li>Andy Barr, Web Developer</li>
        </ul>

        <Spacer></Spacer>
      </div>

      <Footer />
    </Wrapper>
  );
};
