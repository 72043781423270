import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import mapRating from "../util/mapRating";

import Loading from "../icons/loading";
import Link from "./Link";
import RatingBar from "./RatingBar";
import ScrollableContainer from "./ScrollableContainer";
import stateAbbrMap from "../util/stateAbbrMap";
import partyMap from "../util/partyMap";
import PersonRow from "./PersonRow";
import Select from "./Select";
import SupportsIcon from "../icons/supports";
import OpposesIcon from "../icons/opposes";

import colorScale from "../util/colorScale";
import Bill from "../pages/Bill";
import { separateOperations } from "graphql";

const GET_BILL_QUERY = gql`
  query ($id: String!) {
    ratings_articles(where: { id: { _eq: $id } }) {
      id
      title
      description
      status
      acuVote
      year

      votes(where: { articleId: { _eq: $id } }) {
        peopleId
        value
        withAcu
        person {
          id
          name
          lastName
          party
          role
          state
          from
          district

          history {
            party
            district
            state
            from
          }

          acuRatings {
            rating
            year
          }

          acuLifetimeRatings(limit: 1, order_by: { year: desc_nulls_last }) {
            rating
            year
          }

          lifetimeRating
          imageUrl
          thumbnailUrl
        }
      }
    }
  }
`;

const Title = styled("div")`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    color: #636e72;
    margin-left: 24px;
    margin-top: 4px;
  }

  span {
    font-family: Roboto, sans-serif;

    color: #636e72;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const LoadingWrapper = styled("div")`
  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  background: white;
  /* padding: 24px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BillOverviewWrapper = styled("div")`
  background: white;
  padding: 24px;

  h2 {
    margin-top: 0;
    margin-bottom: 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* color: #2D3436; */
  }
`;

function getTitle(role) {
  switch (role) {
    case "Sen":
      return "Senator";
    case "Rep":
      return "Representative";
    default:
      return "";
  }
}

const BillIconWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  svg {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.5;
    /* or 18px */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #636e72;
    margin: 0;
  }

  p:last-child {
    font-weight: normal;
  }
`;

function BillIcon({ bill: { acuVote, status } }) {
  if (acuVote === 1) {
    return (
      <BillIconWrapper>
        <SupportsIcon />
        <p>Conservatives Support</p>
        <p>{status}</p>
      </BillIconWrapper>
    );
  }

  if (acuVote === -1) {
    return (
      <BillIconWrapper>
        <OpposesIcon />
        <p>Conservatives Oppose</p>
        <p>{status}</p>
      </BillIconWrapper>
    );
  }

  return null;
}

const sortByOptions = [
  {
    label: "Lifetime rating",
    prop: "lifetimeRating",
    direction: -1,
  },
  {
    label: "Lifetime rating (low to high)",
    prop: "lifetimeRating",
    direction: 1,
  },
  {
    label: "Current rating",
    prop: "rating",
    direction: -1,
  },
  {
    label: "Current rating (low to high)",
    prop: "rating",
    direction: 1,
  },
  {
    label: "Last name",
    prop: "lastName",
    alternate: "name",
    direction: 1,
    isString: true,
  },
  {
    label: "Last name (Z to A)",
    prop: "lastName",
    alternate: "name",
    direction: -1,
    isString: true,
  },
  {
    label: "Party (A to Z)",
    prop: "party",
    direction: 1,
    isString: true,
  },
  {
    label: "Party (Z to A)",
    prop: "party",
    direction: -1,
    isString: true,
  },
];

sortByOptions.forEach((o) => (o.value = `${o.prop}-${o.direction}`));

const Separator = styled("div")`
  height: 24px;
`;

const Header = styled("h3")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #636e72;
  text-transform: uppercase;

  padding-bottom: 8px;
  border-bottom: 4px solid #f0f3f5;
`;

const NotVotingLink = styled("span")`
  cursor: pointer;
  text-decoration: underline;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #636e72;
`;

const Disclaimer = styled("span")`
  color: #b2bec3;
`;

const SidePinDiv = styled("div")`
  margin-top: 16px;
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SortBy = styled("p")`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
    margin-left: 8px;
  }
`;

export default function BillOverview(props) {
  const { data, loading, error } = useQuery(GET_BILL_QUERY, {
    variables: {
      id: props.id,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.id]);

  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [showNotVoting, setShowNotVoting] = useState(false);

  let bill = {};
  let inFavor = [];
  let opposed = [];
  let notVoting = [];

  function getRatingAndDetail(person) {
    const rating = (person.acuRatings || []).reduce(
      (acc, n) => {
        if (!acc || !acc.rating) {
          return { ...n };
        }

        // Keep the rating aligned with the bill year
        if (bill.year && acc.year === bill.year) {
          return acc;
        }

        if (n.year > acc.year) {
          acc.rating = n.rating;
          acc.year = n.year;
        }

        return acc;
      },
      { rating: null, year: bill.year }
    );

    rating.details = `${rating.year} rating`;

    return rating;
  }

  const ratingsByPersonId = {};

  // TODO: Finish
  if (data) {
    bill = { ...data.ratings_articles[0] };

    // TODO: Fix this mess
    const votes = (bill.votes || []).slice();

    // TODO: Fix
    votes.forEach((v) => {
      const { person } = v;
      if (person) {
        ratingsByPersonId[person.id] = getRatingAndDetail(person);
      } else {
        console.warn({
          action: "labelPersonAsFederal",
          details: "person not found",
          vote: v,
        });
      }
    });

    votes.sort((a, b) => {
      const aValue =
        (a.person && a.person[sortBy.prop]) ||
        ratingsByPersonId[a.person.id][sortBy.prop] ||
        ratingsByPersonId[a.person.id][sortBy.alternative] ||
        a.person[sortBy.alternate] ||
        -Infinity;
      const bValue =
        (b.person && b.person[sortBy.prop]) ||
        ratingsByPersonId[b.person.id][sortBy.prop] ||
        ratingsByPersonId[b.person.id][sortBy.alternative] ||
        b.person[sortBy.alternate] ||
        -Infinity;

      if (sortBy.isString) {
        if (sortBy.direction == 1) {
          return (aValue || "")
            .toString()
            .localeCompare((bValue || "").toString());
        }
        return (bValue || "")
          .toString()
          .localeCompare((aValue || "").toString());
      }

      return (aValue - bValue) * sortBy.direction;
    });

    inFavor = votes.filter((n) => n.person && n.value > 0);
    opposed = votes.filter((n) => n.person && n.value < 0);
    notVoting = votes.filter((n) => n.person && n.value === 0);
  }

  function nextSort() {
    const i = sortByOptions.findIndex((n) => n === sortBy);
    setSortBy(sortByOptions[(i + 1) % sortByOptions.length]);
  }

  function toggleNotVoting() {
    setShowNotVoting(!showNotVoting);
  }

  if (loading) {
    return (
      <BillOverviewWrapper>
        <Title>
          <h2>Votes</h2>
        </Title>
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </BillOverviewWrapper>
    );
  }

  function renderPerson({ person }) {
    return (
      <PersonRow
        showAsterisk={true}
        hideVoteDetails={true}
        {...person}
        federal={person.state === "US"}
        rating={ratingsByPersonId[person.id]?.rating}
        detail={ratingsByPersonId[person.id]?.detail}
        year={ratingsByPersonId[person.id]?.year}
        lifetimeRating={person.acuLifetimeRatings[0]?.rating}
      />
    );
  }

  return (
    <BillOverviewWrapper>
      <Title>
        <h2>Votes</h2>
        {/* <p>Sort by: <span onClick={nextSort}>{sortBy.label}</span></p> */}
        <SortBy>
          Sort by:
          <Select
            width={220}
            value={sortBy}
            options={sortByOptions}
            onChange={setSortBy}
          />
        </SortBy>
      </Title>

      <Row justify="between">
        <Col md={6}>
          <Header>Supported ({inFavor.length})</Header>
          <ScrollableContainer height={300}>
            {inFavor.map(renderPerson)}
          </ScrollableContainer>
        </Col>

        <Col md={6}>
          <Header>Opposed ({opposed.length})</Header>
          <ScrollableContainer height={300}>
            {opposed.map(renderPerson)}
          </ScrollableContainer>
        </Col>
      </Row>

      <Separator />

      {showNotVoting && (
        <Row justify="between">
          <Col md={12}>
            <Header>Not Voting ({notVoting.length})</Header>
            <ScrollableContainer height={200}>
              <Row>
                {notVoting.map((item) => (
                  <Col md={6}>{renderPerson(item)}</Col>
                ))}
              </Row>
            </ScrollableContainer>
          </Col>
        </Row>
      )}

      {showNotVoting && <Separator />}

      <SidePinDiv>
        {notVoting.length > 0 && (
          <NotVotingLink onClick={toggleNotVoting}>
            {showNotVoting
              ? "Hide not voting"
              : `Show ${notVoting.length} not voting`}
          </NotVotingLink>
        )}
        <Disclaimer>*Indicates rating at the time vote was cast</Disclaimer>
      </SidePinDiv>
    </BillOverviewWrapper>
  );
}
