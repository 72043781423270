import React, { useState, useEffect } from "react";
import toPostgresArray from '../util/toPostgresArray';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { cacheClient } from "./client";


const STATE_RATINGS_QUERY = gql`
query(
  $categories: _text
  $group: String
  $year: Int
) {

  ratings: ratings_getStateRatings(args: {
    categories: $categories,
    group: $group,
    year: $year
  }) {
    rating,
    chamber,
    state,
    year
    name,
    supported,
    opposed,
    outcome,
    votes
  }
}
`;


export default function useStateRatings({ year, issues, group, useCache=false }) {

  const { data, loading, error } = useQuery(STATE_RATINGS_QUERY, {
    variables: {
      year,
      categories: issues && issues.length ? toPostgresArray(issues) : null,
      group,
    },
    client: useCache ? cacheClient : null,
  });

  const [result, setResult] = useState(data);


  useEffect(() => {
    const ratings = data && data.ratings;

    if (loading || error || !ratings) {
      setResult({ loading, data: ratings, error });
      return;
    }

    const byState = {};

    for (const rating of ratings) {
      byState[rating.state] = byState[rating.state] || {};
      byState[rating.state][rating.year] = byState[rating.state][rating.year] || {
        state: rating.state,
        name: rating.name,
        ratingSum: 0,
        ratingCount: 0,
        supported: 0,
        opposed: 0,
        outcome: 0,
        votes: 0
      };

      const thisYear = byState[rating.state][rating.year];


      thisYear.ratingSum += rating.rating;
      thisYear.ratingCount++;
      thisYear.supported += rating.supported;
      thisYear.opposed += rating.opposed;
      thisYear.outcome += rating.outcome;
      thisYear.votes += rating.votes;

      thisYear[rating.chamber] = rating;

      // console.log(thisYear, rating);

    }

    const final = {};

    for (const state in byState) {

      const data = {
        state,
        ratingSum: 0,
        ratingCount: 0,
        supported: 0,
        opposed: 0,
        senateCount: 0,
        houseCount: 0,
        assemblyCount: 0,
        outcome: 0,
        votes: 0,
      };

      for (const year in byState[state]) {
        const yearData = byState[state][year];

        if (yearData.senate) {
          data.senate = (data.senate || 0) + yearData.senate.rating;
          data.senateCount++;
        }

        if (yearData.house) {
          data.house = (data.house || 0) + yearData.house.rating;
          data.houseCount++;
        }

        if (yearData.assembly) {
          data.assembly = (data.assembly || 0) + yearData.assembly.rating;
          data.assemblyCount++;
        }

        data.ratingSum += yearData.ratingSum;
        data.ratingCount += yearData.ratingCount;
        data.supported += yearData.supported;
        data.opposed += yearData.opposed;
        data.outcome += yearData.outcome;
        data.votes += yearData.votes;
        data.name = yearData.name;
      }

      data.rating = data.ratingSum / data.ratingCount;

      final[state] = {
        state,
        name: data.name,
        senate: fixRating(data.senate, data.senateCount),
        house: fixRating(data.house, data.houseCount),
        assembly: fixRating(data.assembly, data.assemblyCount),
        rating: 100 * data.ratingSum / data.ratingCount,
        supported: data.supported,
        opposed: data.opposed,
        outcome: data.outcome,
        votes: data.votes,
      };

    }

    setResult(final);

  }, [loading, data]);

  return {
    error,
    loading,
    data: result,
  };
}

function fixRating(ratingSum, count) {
  if (typeof ratingSum !== 'number') {
    return null;
  }
  return (100 * ratingSum / count).toFixed();
}
