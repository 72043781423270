import React from "react";
import styled from "styled-components";

const Wrapper = styled("div")`
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.width || 1200}px;
  margin: ${(props) => props.margin || "24px auto 24px"};
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.pin
      ? `
    position: fixed;
    bottom: 0;
  `
      : ""}
`;

const Address = styled("p")`
  font-size: 14px;
  margin: 4px 0;
  color: #636e72;
`;

const InfoWrapper = styled("div")`
  p {
    text-align: center;
  }
`;

function EnvironmentInfo() {
  const APP_VERSION = process.env.APP_VERSION;
  const NODE_ENV = process.env.NODE_ENV;
  const HASURA_HOST = process.env.HASURA_HOST;

  const prodHosts = [
    "production.ratings.conservative.org",
    "ratings.conservative.org",
  ];

  if (NODE_ENV === "production" || prodHosts.includes(location.hostname)) {
    return null;
  }

  return (
    <InfoWrapper>
      <Address>
        <strong>Version:</strong> {APP_VERSION}
      </Address>
      <Address>
        <strong>Environment:</strong> {NODE_ENV}
      </Address>
      <Address>
        <strong>Hasura:</strong>{" "}
        <a href={HASURA_HOST} target="_blank">
          {HASURA_HOST}
        </a>
      </Address>
    </InfoWrapper>
  );
}

export default function Footer(props) {
  let date = new Date().getFullYear();

  return (
    <Wrapper {...props}>
      <div>
        <Address>Phone: (202) 347-9388 / Fax: (202) 347-9389</Address>
        <Address>1199 N Fairfax St, Suite 500</Address>
        <Address>Alexandria, VA 22314</Address>
      </div>
      <EnvironmentInfo />
      <Address>
        © {date} American Conservative Union. All Rights Reserved.
      </Address>
    </Wrapper>
  );
}
