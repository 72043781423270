import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg {...props} width="14" height="11" viewBox="0 0 14 11"  xmlns="http://www.w3.org/2000/svg">
    <path d="M2.0949 5.15603L1.95455 5.01777L1.81419 5.15603L0.859646 6.09633L0.715006 6.23881L0.859646 6.38129L4.67783 10.1425L4.81818 10.2807L4.95854 10.1425L13.1404 2.08278L13.285 1.9403L13.1404 1.79782L12.1858 0.857519L12.0455 0.71926L11.9051 0.857519L4.81818 7.83866L2.0949 5.15603Z" fill="#2D3436" stroke="#2D3436" strokeWidth="0.4" />
  </Svg>
);
