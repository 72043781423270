import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg width="53" height="53" viewBox="0 0 53 53" xmlns="http://www.w3.org/2000/svg" {...props} fill="#0984E3">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M53 26.5C53 41.1355 41.1355 53 26.5 53C11.8645 53 0 41.1355 0 26.5C0 11.8645 11.8645 0 26.5 0C41.1355 0 53 11.8645 53 26.5ZM33.6681 16.707C34.0586 16.3165 34.6918 16.3166 35.0823 16.7071L36.293 17.9179C36.6835 18.3084 36.6835 18.9416 36.293 19.3321L29.8327 25.7929C29.4422 26.1834 29.4422 26.8166 29.8327 27.2071L36.293 33.6679C36.6835 34.0584 36.6835 34.6916 36.293 35.0821L35.0823 36.2929C34.6918 36.6834 34.0586 36.6835 33.6681 36.293L27.2062 29.832C26.8157 29.4415 26.1825 29.4416 25.792 29.8321L19.3319 36.2928C18.9414 36.6834 18.3082 36.6834 17.9176 36.2928L16.707 35.0821C16.3165 34.6916 16.3165 34.0584 16.707 33.6679L23.1673 27.2071C23.5578 26.8166 23.5578 26.1834 23.1673 25.7929L16.707 19.3321C16.3165 18.9416 16.3165 18.3084 16.707 17.9179L17.9176 16.7072C18.3082 16.3166 18.9414 16.3166 19.3319 16.7072L25.792 23.1679C26.1825 23.5584 26.8157 23.5585 27.2062 23.168L33.6681 16.707Z" fill="#0984E3" />
  </Svg>
);
