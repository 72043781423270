import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';

export default (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="12" cy="12" r="11.5" fill="white" stroke="#818181"/>
    <path d="M15.0305 16.0406L12 13.0102L8.96954 16.0406L7.95939 15.0305L10.9898 12L7.95939 8.96957L8.96954 7.95941L12 10.9899L15.0305 7.95941L16.0406 8.96957L13.0102 12L16.0406 15.0305L15.0305 16.0406Z" fill="#818181"/>
  </svg>
);
 