import styled from 'styled-components';
import { Link as BrowserLink } from 'react-router-dom';

const Slider = styled.div`
  margin-bottom: 30px;

  .slider-wrapper {
    position: relative;
    height: 70vh;
    overflow: hidden;
  }

  .slide {
    height: 70vh;
    background-size: cover !important;
  }

  @media only screen and (orientation: portrait) {
    .slider-wrapper {
      height: 30vh;
    }
  
    .slide {
      height: 30vh;
    }
  }

  .slide::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
    bottom: 0;
    left: 0;
  }

  .previousButton, .nextButton {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 10;
		width: 32px;
		height: 32px;
		cursor: pointer;
  }

  .previousButton svg polygon,
  .nextButton svg polygon {
    fill: #FFFFFF;
    text-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }

  .previousButton {
    left: 0;
    text-align: right;
  }

  .nextButton {
    right: 0;
  }

  .slider-content {
    font-size: 16px;
  }

  .slider-content .inner {
    position: absolute;
    bottom: 36px;
    left: 0;
    padding-left: 42px;
    padding-right: 42px;
  }

  /*  
  .slider-content .inner {
    box-sizing: border-box;
    left: 50%;
    padding: 0 70px;
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  */
  
  .slider-content .inner button {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
  }
  
  .slider-content .inner h2 {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 0;
    text-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }
  
  .slider-content .inner p {
    color: #FFFFFF;
    line-height: 1.2;
    margin-top: 0.3em;
    max-width: 640px;
    text-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }

  .slider-content .inner ul li {
    line-height: 1;
    color: #FFFFFF;
    text-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }

  .slide-0 .inner {
    text-align: left;
  }

  @media (min-width: 576px) { ... }

  @media (min-width: 768px) {
    .slider-content {
      font-size: 16px;
    }

    .slider-content .inner {
      bottom: 20%;
    }
  
    .slider-content .inner h2 {
      font-size: 28px;
      font-weight: 600;
    }
  }

  @media (min-width: 992px) {
    .slider-content {
      font-size: 21px;
    }  
  
    .slider-content .inner h2 {
      font-size: 36px;
      font-weight: 600;
    }
  }

  @media (min-width: 1200px) { ... }
`;

const Tagline = styled('h1')`
  margin-top: -12px;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: normal;
  /* font-style: italic; */
  background-color: white;
  padding: 24px;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0,0,0,.25);
`;

export default {
  Slider,
  Tagline,
}
