const partyMap = {
  D: 'Democrat',
  R: 'Republican',
  G: 'Green',
  I: 'Independent',
};

export default function getParty(party) {
  return partyMap[party] || party;
};
