import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import Select from 'react-select'
import RatingBar from './RatingBar';
import colorScale from '../util/colorScale';


const RatingWrapper = styled('div')`

  margin: 0 24px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>p:first-child {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 0 8px 0;

    color: ${props => props.rating == null ? '#DFE6E9' : colorScale(props.rating) };
  }
  
  &>p:last-child {
    margin: 8px 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    text-align: center;

    color: #636E72;
  }
`;

function fix(n, includeDecimal=false) {
  if (isNaN(+n)) {
    return 'n/a';
  }
  return (+n).toFixed(includeDecimal ? 2 : 0); //.replace(/(\.0)?0$/g, '');
}

export default function Rating(props) {
  return (
    <RatingWrapper rating={props.rating}>
      <p>{fix(props.rating, props.decimal)}</p>
      <RatingBar hideNumber rating={props.rating} />
      <p>{props.detail}&nbsp;</p>
    </RatingWrapper>
  )
}
