import { useEffect } from "react";


// TODO: Replace this with a single hook at the router level
// https://stackoverflow.com/a/54343182
export default function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
