import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colorScale from '../util/colorScale';
import mapRating from '../util/mapRating';


// const Component = styled.div.attrs(props => ({
//   style: {
//     background: props.background,
//   },
// }))`width: 100%;`

const ImageContainer = styled('div')`
  width: 50px;
  margin: 0 16px;
  position: relative;

  img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;



const RatingSpan = styled('span').attrs(props => ({ style: { background: colorScale(props.lifetimeRating || props.rating) } }))`
  position: absolute;
    display: block;
    bottom: -4px;
    right: -6px;
    font-size: 12px;
    padding: 2px 6px;
    border-radius: 100px;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0,0,0,.25);
    color: white;

    span {
      color: white;
      padding-left: 1px;
      font-size: 10px;
    }
`;

export default function ProfileImage({ id, rating, src, className }) {
  return (
    <ImageContainer rating={rating} className={className} style={{ cursor: 'pointer' }}>
      <RoundCenteredImage src={src} />
      {rating != null && <RatingSpan rating={rating}>{mapRating(rating)}<span>%</span></RatingSpan>}
    </ImageContainer>
  )
}

const Rating = styled('div')`
  @media (max-width: 800px) {
    display: none;
  }

  margin-left: auto;

  &>p {
    text-align: right;
    color: #636E72;
    margin: 4px 0;
    font-size: 14px;
  }
`;


const RoundCenteredImage = styled('div').attrs(props => ({
  style: { background: `url('${props.src}'), url('https://theprobatepro.com/wp-content/uploads/2015/02/FACEHOLDER.jpeg')` }
}))`
  height: 50px;
  width: 50px;
  background-size: cover;
  background-position: top center;
  border-radius: 100px;
  -webkit-transition: background-image 350ms ease;
  transition: background-image 350ms ease;
`;
