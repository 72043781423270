import React from "react";
import { Row, Col } from "react-grid-system";
import Slider from "react-animated-slider";
import "react-animated-slider/build/horizontal.css";
import Styles from "./styles";

function LandingFeature() {
  const slides = [
    {
      title: "50 Years of CPAC Ratings",
      description:
        "The fastest and strongest way to evaluate the voting records of 15,000 Historic and Active Lawmakers",
      background:
        "https://media.conservative.org/wp-content/uploads/2022/09/28122408/ratings_2-scaled.jpeg",
      backgroundAlign: "center",
    },
    {
      title: "Conduct in-depth lawmaker research",
      bullets: [
        "Compare lawmakers head-to-head",
        "Analyze trends over time",
        "Enhanced lawmaker profiles for deep dives",
      ],
      background:
        "https://media.conservative.org/wp-content/uploads/2022/10/19120418/lawmakers_2-scaled.jpeg",
      backgroundAlign: "top",
    },
    {
      title: "The entire CPAC ratings catalog at your fingertips",
      bullets: [
        "Every Member of Congress since 1971",
        "Every state lawmaker since 2015",
        "Every bill ever rated by the CPAC",
      ],
      background:
        "https://acu-ratings-assets.s3.amazonaws.com/fl-legislators2.jpg",
      backgroundAlign: "center",
    },
    {
      title: "Evaluate lawmakers and legislatures by the issue",
      bullets: [
        "19 policy areas, 186 sub-issues",
        "35,000 unique combinations of policy issues",
        "Find and rank top lawmakers by issue area",
      ],
      background:
        "https://acu-ratings-assets.s3.amazonaws.com/mike-lee-cpac.jpg",
      backgroundAlign: "center",
    },
  ];

  return (
    <Row>
      <Col>
        <Styles.Slider>
          <Slider className="slider-wrapper box-shadow" autoplay={3000}>
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`slider-content slide-${index}`}
                style={{
                  background: `url('${slide.background}') no-repeat ${slide.backgroundAlign} center`,
                }}
              >
                <div className="inner">
                  <h2>{slide.title}</h2>
                  <p>{slide.description}</p>
                  {slide.bullets && slide.bullets.length && (
                    <ul>
                      {slide.bullets.map((bullet, index) => (
                        <li key={index}>{bullet}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ))}
          </Slider>
        </Styles.Slider>
      </Col>
    </Row>
  );
}

export default LandingFeature;
