import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQueryParam, NumberParam, StringParam, ArrayParam, JsonParam, BooleanParam, withDefault } from 'use-query-params';

import constants from '../../constants.json';
import ClearableInput from './ClearableInput';
import getIssues from '../data/getIssues';
import Triangle from '../icons/triangle';
import CheckboxFull from '../icons/checkbox-full';
import CheckboxPartial from '../icons/checkbox-partial';
import CheckboxEmpty from '../icons/checkbox-empty';

const IssueSelectorContainer = styled('div')`
  h3 {
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 0;

    @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

const Group = styled('p')`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #5C5C5C;

  padding-left: 8px;
  border-left: ${props => props.selected ? '4px solid #D63031' : '4px solid transparent'};
  cursor: pointer;
`;

const Link = styled('p')`
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 24px;
`;

const Header = styled('h3')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectAllAndClear = styled("div")`
  font-size: 14px;
  margin-top: 24px;
  span {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  span:first-of-type {
    margin-right: 5px;
  }

  span:last-of-type {
    margin-left: 5px;
  }

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    margin-top: 8px;

    span:first-of-type {
      margin-right: initial;
    }

    span:last-of-type {
      margin-left: initial;
    }
  }
`;

const PARTIAL_SELECT = 'PARTIAL_SELECT';


let getSelectedIndex = 0;


export default function IssueSelector(props) {

  const [isMultiselect, setIsMultiSelect] = useQueryParam('multiselect', BooleanParam);
  // const [isMultiselect, setIsMultiSelect] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState({});
  const [expanded, setExpanded] = useState({});

  function getSelected(issues, categories) {
    const selected = {};

    const groups = {};

    for (const issue of issues) {
      selected[issue] = true;
      groups[issue[0]] = categories.find(c => c.id === issue[0]);
    }

    for (const group of Object.values(groups)) {
      if (group && group.subcategories.find(sc => !selected[sc.id])) {
        selected[group.id] = PARTIAL_SELECT;
      } else {
        selected[group.id] = true;
      }
    }

    // selected.getSelectedIndex = getSelectedIndex++;

    return selected;
  }


  function onToggleMultiSelect() {
    setIsMultiSelect(!isMultiselect);

    // We're switching _from_ multiselect
    if (isMultiselect) {
      props.onIssuesSelected([]);
      props.onGroupSelected(selectedGroup);
    } else {
      props.onIssuesSelected(Object.keys(selected).filter(c => selected[c] && c.length > 1));
      props.onGroupSelected(null);
    }
  }

  function onSelect(item) {

    // Case 1: It's a group
    if (item.subcategories) {

      if (selected[item.id]) {
        selected[item.id] = false;
        item.subcategories.forEach(sc => selected[sc.id] = false);
      } else {
        selected[item.id] = true;
        item.subcategories.forEach(sc => selected[sc.id] = true);
      }

      // Case 1A: Not selected (totally empty)
    } else {

      selected[item.id] = !selected[item.id];

      const group = categories.find(c => c.id === item.group);

      let groupState = null;
      for (const issue of group.subcategories) {
        const state = !!selected[issue.id];
        if (groupState === null) {
          groupState = state;
        } else if (groupState !== state) {
          groupState = PARTIAL_SELECT;
          break;
        } else {
          // Do nothing
        }
      }

      selected[group.id] = groupState;
    }

    setSelected({ ...selected });
    props.onIssuesSelected(Object.keys(selected).filter(c => selected[c] && c.length > 1));
  }

  function selectAll() {
    for (const category of categories) {
      selected[category.id] = true;
      for (const issue of category.subcategories) {
        selected[issue.id] = true;
      }
    }
    setSelected({ ...selected });
    props.onIssuesSelected(Object.keys(selected).filter(c => selected[c] && c.length > 1));
  }

  function clear() {
    setSelected({});
    props.onIssuesSelected([]);
  }

  useEffect(() => {

    getIssues().then(categories => {
      setCategories(categories);
      const selected = getSelected(props.issues, categories);

      const newExpanded = { ...expanded };

      // TODO: Clean this up or extract it. We want to default to showing the first category, unless we have a selection – in that case, expand all the selected categories.
      let openFirstOne = true;
      for (const key in selected) {
        if (key.length === 1) {
          openFirstOne = false;
          newExpanded[key] = !!selected[key];
        }
      }

      if (openFirstOne) {
        newExpanded[categories[0].id] = true;
      }

      setExpanded(newExpanded);
      setSelected({ ...selected, });
    });

  }, []);


  if (props.multiSelectOnly || isMultiselect) {
    return (
      <IssueSelectorContainer className={props.className} style={props.style}>
        <Header>Select policy areas</Header>
        <SelectAllAndClear><span onClick={selectAll}>Select all</span> | <span onClick={clear}>Clear Selection</span></SelectAllAndClear>
        {!props.multiSelectOnly ? <Link onClick={onToggleMultiSelect}>Single-select</Link> : <div style={{ padding: 4 }} />}
        {categories.map(c =>
          <MultiSelectGroup
            {...c}
            onSelect={onSelect}
            selected={selected}
            expanded={expanded[c.id]}
            onExpanded={() => setExpanded({ ...expanded, [c.id]: !expanded[c.id] })}
          />
        )}
      </IssueSelectorContainer>
    );
  }



  const selectGroup = (group) => {
    setSelectedGroup(group);
    props.onGroupSelected && props.onGroupSelected(group);
  };



  const issueList = categories.map(group =>
    <Group
      selected={selectedGroup === group}
      onClick={() => selectGroup(group)}
    >{group.name}</Group>
  );

  return (
    <IssueSelectorContainer>
      <h3>Select a policy area</h3>
      <Link onClick={onToggleMultiSelect}>Multi-select</Link>
      {issueList}
    </IssueSelectorContainer>
  )
}


const Line = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 20px;
    cursor: pointer;
  }

  span {
    flex: 1;
    cursor: pointer;
  }

  margin-top: 24px;
  margin-left: 10px;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    margin-top: 12px;
    margin-left: initial;

    svg {
      margin-right: 8px;
    }
  }
`;

const GroupLine = styled(Line)`
`;

const IssueLine = styled(Line)`
  svg {
    margin-left: 48px;
  }
`;


function MultiSelectGroup(props) {

  const {
    onSelect,
    selected,
    expanded,
    onExpanded,
  } = props;

  const selectGroup = () => onSelect(props);

  let icon;
  if (selected[props.id] === PARTIAL_SELECT) {
    icon = <CheckboxPartial onClick={selectGroup} />;
  } else if (selected[props.id]) {
    icon = <CheckboxFull onClick={selectGroup} />;
  } else {
    icon = <CheckboxEmpty onClick={selectGroup} />;
  }

  return (
    <div>
      <GroupLine>
        <Triangle fill="gray" rotate={expanded ? 0 : -90} onClick={onExpanded} />{icon} <span onClick={selectGroup}>{props.name}</span>
      </GroupLine>
      {expanded && <div>
        {props.subcategories.map(sc =>
          <IssueLine onClick={() => onSelect(sc)}>{props.selected[sc.id] ? <CheckboxFull /> : <CheckboxEmpty />} <span>{sc.name}</span></IssueLine>
        )}
      </div>}
    </div>
  )
}