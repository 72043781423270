import { useState, useEffect } from 'react';
import querySql from './sql';

export default function useSql(sql) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    querySql(sql)
      .then(setData)
      .catch(setError)
      .then(() => setLoading(false));
  }, [sql]);

  return {
    loading,
    data,
    error,
  };
}
