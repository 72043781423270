import React from "react";
import styled from "styled-components";
import usePDFUrl from "../data/usePDFUrl";
import usePDFPreviewUrl from "../data/usePDFPreviewUrl";
import PdfIcon from "../icons/pdf";

const LinkWrapper = styled("a")`
  position: absolute;
  top: 12px;
  right: 12px;
  text-align: center;
  z-index: 1;
`;

const PDFPreview = styled("img")`
  height: auto;
  width: 100px;
`;

const PDFCta = styled("p")`
  margin: 2px 0 0 0;
  font-size: 12px;
`;

export default function PDFLink(props) {
  const pdfUrl = usePDFUrl(props.id, props.year);
  const pdfPreviewUrl = usePDFPreviewUrl(props.id, props.year);

  if (!pdfUrl) {
    return null;
  }

  return (
    <LinkWrapper
      href={pdfUrl}
      title={`View the ${props.id} ${props.year} PDF`}
      target="_blank"
    >
      {pdfPreviewUrl ? (
        <PDFPreview src={pdfPreviewUrl} />
      ) : (
        <PdfIcon height={40} />
      )}
      <PDFCta>View the PDF</PDFCta>
    </LinkWrapper>
  );
}
