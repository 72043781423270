import React from 'react';
import styled from 'styled-components';
import Loading from './loading';

const Block = styled('div')`
  height: ${p => p.height || 300}px;
  width: ${p => p.fullWidth ? '100%' : 'auto'};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default (props) => <Block {...props}><Loading/></Block>;
