import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Visible } from 'react-grid-system';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import ReactTooltip from "react-tooltip";
import { groupsById } from '../data/getIssues';

import mapRating from '../util/mapRating';
import stateIsAssembly from '../util/stateIsAssembly';

import Link from './Link';
import RatingBar from './RatingBar';
import ScrollableContainer from './ScrollableContainer';
import stateAbbrMap from '../util/stateAbbrMap';
import partyMap from '../util/partyMap';

import colorScale from '../util/colorScale';

import StateBorder from './StateBorder';
import Loading from './Loading';
import PDFLink from './PDFLink';
import { cacheClient } from '../data/client';


const GET_STATE_QUERY = gql`
query getStateOverview (
  $state: String!
  $year: Int
) {
  
  lifetimeRating: ratings_stateRatings_aggregate(
    where: { state: { _eq: $state } }
  ) {
    aggregate {
      avg {
        rating
      }
    }
  }
  
  lifetimeVotes: ratings_votes_aggregate(
    where: { person: { state: { _eq: $state } } year: { _eq: $year } }
  ) {
    aggregate {
      count
    }
  }
  
  issueRatings: ratings_stateIssueRatings(
    where: { state: { _eq: $state }, year: { _eq: $year } }
  ) {
    rating
    category
    categoryId
    year
    group
    acuVotes
    votes: total
  }

  members: ratings_people(where: {
    history: {
      year: { _eq: $year }
      state: { _eq: $state }
    }
  }) {
    acuRatings(where: { year: {_eq: $year}}) {
      rating
    }
    history(where: { year: {_eq: $year}}) {
      chamber
      year
      party
    }
  }
}
`;
// session: ratings_sessions(where: {
//   state: { _eq: $state },
//   startDate: { _lte: $date },
//   endDate: { _gte: $date },
// }) {
//   status
//   startDate
//   endDate
// }


const StateOverviewWrapper = styled('div')`
  position: relative;
  background: white;
  padding: 24px;
  height: ${props => props.height ? `${props.height}px` : ''};

  ${Loading} {
    margin-top: 100px;
  }

  h2 {
    /* padding: 16px 0 0 16px; */
    padding-top: 16px;
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2D3436;
  }
`;

const OverviewWrapper = styled('div')`
  margin: 16px 0;
`;

const Stats = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  color: #636E72;
  margin: 0 0 24px 0;
`;


const PicContainer = styled('div')`
  /* max-height: 300px; */
  position: relative;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
`;


const RatingBadge = styled('div')`
  background-color: ${ /** @param {{ rating: number }} props */ ({ rating }) => rating ? colorScale(rating) : '#B5B5B5'};
  position: absolute;
  top: -12px;
  left: -12px;
  padding: 6px 16px 8px;
  border-radius: 1000px;
  line-height: 1;
  color: white;
  text-shadow: 1px 1px 1px rgba(0,0,0,.5);
  font-size: 24px;

  p {
    text-align: center;
    color: white;
    margin: 0;
  }

  p:nth-of-type(2) {
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
  }
`;

const StrengthsAndWeaknesses = styled('div')`

  /* padding-top: 32px; */

  h3 {
    &:first-of-type {
      margin-top: 6px;
    }

    margin-top: 24px;
    font-size: 16px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-family: 'Helvetica Neue';
    font-size: 14px;
    color: #636E72;
    margin-bottom: 8px;
  }

  margin-bottom: 24px;
`;

const MemberTable = styled('table')`
  padding: 0 0 16px;
  width: 100%;
  font-size: 16px;

  th, td {
    font-family: Roboto, sans-serif;
    padding: 2px 16px 2px 0;
  }

  th {
    padding-top: 4px;
    line-height: 20px;
    font-size: 16px;
    color: #2D3436;
  }

  td {
    font-size: 14px;
    color: #636E72;
  }

  thead {
    th {
      font-weight: 700;
      text-align: right;
    }

    th:first-child {
      text-align: left;
    }
  }

  tbody {
    td {
      text-align: right;
    }

    td:first-child {
      text-align: left;
    }
  }
`;

const NoDataMessage = styled('p')`
/* margin-left: 16px; */
`;

const IssueItem = styled('li')`
  cursor: pointer;
  text-decoration: underline;
`;

const Tooltip = styled(ReactTooltip)`
  padding: 4px 8px !important;
  margin: 0;

  p {
    color: white;
    margin: 0;
    line-height: 1.5;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      color: white;
      margin: 4px;
    }
  }
`;

const PercentSign = styled('span')`
  color: white;
  font-size: 16px;
  display: inline-block;
  transform: translate(2px, -2px);
  font-size: 18px;
`;

function getPartyName(partyInitial) {
  switch (partyInitial) {
    case 'U':
      return 'Unaffiliated';
    case 'L':
      return 'Libertarian';
    case 'D':
      return 'Democrat';
    case 'R':
      return 'Republican';
    case 'I':
      return 'Independent';
    case 'G':
      return 'Green';
    default:
      return partyInitial;
  }
}

function getTitle(role) {
  switch (role) {
    case 'Sen':
      return 'Senator';
    case 'Rep':
      return 'Representative';
    default:
      return '';
  }
}

function extractData(data) {
  try {
    const { issueRatings, ratingsByParty, members } = data;
    const lifetimeRating = data.lifetimeRating.aggregate.avg.rating.toFixed(0);
    const lifetimeVotes = data.lifetimeVotes.aggregate.count;

    const { strongIssues, weakIssues } = getStrongAndWeak(data.issueRatings);

    const {
      senateRatings,
      houseRatings,
    } = getRatingsForChambers(members.map(m => ({ ...m })));

    return {
      loaded: true,
      issueRatings,
      lifetimeRating,
      lifetimeVotes,
      strongIssues,
      weakIssues,
      senateRatings,
      houseRatings,
    };
  } catch (e) {
    console.error(e);
    return {};
  }
}

const today = new Date().toISOString().split('T')[0];


export default function StateOverview(props) {

  const isAssembly = stateIsAssembly(props.id)

  const [defaultYear, setDefaultYear] = useState(2020);

  const { data, loading, error } = useQuery(GET_STATE_QUERY, {
    variables: {
      state: props.id,
      year: props.year || defaultYear,
      // date: today,
    },
    client: cacheClient,
  });

  useEffect(() => {
    if (!loading && (!data || !data.members || !data.members.length) && !error && defaultYear !== 2019) {
      props.setYear(2019);
      setDefaultYear(2019);
    }
  }, [data, loading, error, setDefaultYear, props.setYear]);

  const isLoading = loading || (!data?.members?.length && !props.year && defaultYear === 2020);

  if (isLoading) {
    return (
      <StateOverviewWrapper height={300}>
        <RatingBadge>
          <p>n/a</p>
        </RatingBadge>
        <h2>Overview</h2>
        <Loading />
      </StateOverviewWrapper>
    )
  }

  const {
    loaded,
    issueRatings,
    lifetimeRating,
    lifetimeVotes,
    strongIssues,
    weakIssues,
    senateRatings,
    houseRatings,
  } = extractData(data);

  if (!loaded || error || !data?.members?.length) {
    console.warn(error);
    return (
      <StateOverviewWrapper>
        <RatingBadge>
          <p>n/a</p>
        </RatingBadge>
        <h2>Overview</h2>
        <NoDataMessage>Data not available. Please check back later.</NoDataMessage>
      </StateOverviewWrapper>
    );
  }

  function selectIssues(issues) {
    if (props.onSelectIssues) {
      props.onSelectIssues(issues);
    }
  }

  const session = (data.session || '')[0]?.status;

  return (
    <StateOverviewWrapper>

      <PDFLink year={props.year || defaultYear} id={props.id} />

      <RatingBadge rating={lifetimeRating}>
        <p>{lifetimeRating}<PercentSign>%</PercentSign></p>
        {/* {props.lifetimeRank && <p>Rank {props.lifetimeRank}</p>} */}
      </RatingBadge>

      <h2>Overview</h2>

      <Row justify="between">
        <Col lg={4}>
          <StateBorder height={240} state={props.id} rating={lifetimeRating} />
          {/* <Flag src={flags[props.id]} /> */}
        </Col>

        <Col lg={4} md={6}>
          <MemberTable>
            <thead>
              <tr>
                <th>{props.id === 'NE' ? 'Unicameral' : 'Senate'}</th>
                <th>Members</th>
                <th>{props.year || defaultYear} rating</th>
              </tr>
            </thead>
            <tbody>
              {senateRatings.map(r => (
                <tr key={r.party}>
                  <td>{r.party}</td>
                  <td>{r.members}</td>
                  <td>{r.rating}</td>
                </tr>
              ))}
            </tbody>
          </MemberTable>

          {props.id != 'NE' && <MemberTable>
            <thead>
              <tr>
                <th>{isAssembly ? 'Assembly' : 'House'}</th>
                <th>Members</th>
                <th>{props.year || defaultYear} rating</th>
              </tr>
            </thead>
            <tbody>
              {houseRatings.map(r => (
                <tr key={r.party}>
                  <td>{r.party}</td>
                  <td>{r.members}</td>
                  <td>{r.rating}</td>
                </tr>
              ))}
            </tbody>
          </MemberTable>}
          <Session session={data.session} />
        </Col>


        <Col lg={4} md={6}>
          <StrengthsAndWeaknesses>
            <h3>Strongest issues</h3>
            <IssueList issues={strongIssues} onSelectIssues={selectIssues} />


            <h3>Weakest issues</h3>
            <IssueList issues={weakIssues} onSelectIssues={selectIssues} />

          </StrengthsAndWeaknesses>
        </Col>


      </Row>

      <Tooltip is multiline={true} getContent={getTooltipContent} id="statestrongweak" />
    </StateOverviewWrapper>
  )
}

function Session(props) {
  const session = (props.session || '')[0];
  if (!session) {
    return null;
  }

  const startDate = new Date(session.startDate);
  const endDate = new Date(session.endDate);

  return <p style={{ fontStyle: 'italic' }}>{session.status} from {startDate.toLocaleDateString()} to {endDate.toLocaleDateString()}.</p>
}

function getTooltipContent(data) {
  if (!data) return null;
  // const [rating, total, rank] = data.split(',');

  try {
    const {
      rating,
      votes,
      rank,
      acuVotes,
      allTimeAcuVotes,
      allTimeVotes,
    } = JSON.parse(data);

    return (
      <div>
        <p>
          <span>Rating:</span>
          <span>{fix(rating, true)}%</span>
        </p>
        <p>
          <span>Most recent votes:</span>
          <span>{acuVotes} / {votes}</span>
        </p>
        <p>
          <span>All time votes:</span>
          <span>{allTimeAcuVotes} / {allTimeVotes}</span>
        </p>
      </div>
    );
  } catch (e) {
    return '';
  }
}


function IssueList({ issues, onSelectIssues = (issue) => { } }) {

  let issueElements = null;

  if (issues.length === 0) {
    issueElements = <li><em>n/a</em></li>;
  } else {

    issueElements = issues.map(issue => (
      <IssueItem key={issue.group} onClick={() => onSelectIssues(issue.issues)} data-tip={JSON.stringify(issue)} data-for="statestrongweak">{issue.groupName}</IssueItem>
    ));
  }

  return (
    <ul>
      {issueElements}
    </ul>
  )
}

function fix(n) {
  try {
    return n.toFixed();
  } catch (e) {
    return n;
  }
}


// A category must receive this many votes to be considered for inclusion
const STRONG_WEAK_THRESHOLD = 10;

// "Strong" categories for R's have a rating of 80 or better
const STRONG_RATING_THRESHOLD = 52.5;

// "Weak" categories have a rating of less than 65
const WEAK_RATING_THRESHOLD = 47.5;

// How many votes to consider, starting with the most recent year
const STRONG_WEAK_VOTES_TO_CONSIDER = 300;

// How many to include in each section
const STRONG_WEAK_COUNT = 5;

function getStrongAndWeak(issueRatings) {

  const aggregates = (issueRatings.map(n => ({ ...n })))
    .sort((a, b) => b.year - a.year)
    .reduce((acc, n) => {

      acc[n.group] = (acc[n.group] || {
        allTimeAcuVotes: 0,
        allTimeVotes: 0,
        votes: 0,
        acuVotes: 0,
        groupName: groupsById[n.group],
        categoryIds: {}
      });

      if (acc[n.group].votes <= STRONG_WEAK_VOTES_TO_CONSIDER) {
        acc[n.group].categoryIds[n.categoryId] = true;
        acc[n.group].votes += n.votes;
        acc[n.group].acuVotes += n.acuVotes;
      }

      acc[n.group].allTimeAcuVotes += n.acuVotes;
      acc[n.group].allTimeVotes += n.votes;

      return acc;
    }, {});

  // TODO: Only pull the most recent ~20ish votes

  const sorted = Object.keys(aggregates).map(key => {
    const n = aggregates[key];
    return {
      allTimeAcuVotes: n.allTimeAcuVotes,
      allTimeVotes: n.allTimeVotes,
      group: key,
      groupName: n.groupName,
      rating: 100 * n.acuVotes / n.votes,
      votes: n.votes,
      acuVotes: n.acuVotes,
      // rank: n.acuVotes,
      rank: 100 * n.acuVotes / n.votes,
      issues: Object.keys(n.categoryIds),
    };
  }).sort((a, b) => {
    const diff = b.rank - a.rank;

    if (diff === 0) {
      return b.allTimeAcuVotes - a.allTimeAcuVotes;
    }

    return diff;
  });

  return {
    strongIssues: sorted.filter(n => n.votes >= STRONG_WEAK_THRESHOLD && n.rating >= STRONG_RATING_THRESHOLD).slice(0, STRONG_WEAK_COUNT), //.map(n => n.category),
    weakIssues: sorted.filter(n => n.votes >= STRONG_WEAK_THRESHOLD && n.rating < WEAK_RATING_THRESHOLD).slice(-STRONG_WEAK_COUNT), //.map(n => n.category),
  }
}

function getRatingsForChambers(members) {
  const flattened = members.map(m => ({
    ...m.history[0],
    ...m.acuRatings[0],
  }));

  const latestYear = flattened.reduce((max, n) => Math.max(max, n.year), 1900);
  const senateRatings = Object.values(flattened.reduce((acc, n) => {

    if (n.year === latestYear && n.chamber === 'senate' && n.rating !== null) {

      if (!acc[n.party]) {
        acc[n.party] = {
          party: getPartyName(n.party),
          rating: 0,
          members: 0,
        };
      }

      acc[n.party].members++;
      acc[n.party].rating += n.rating;
    }

    return acc;
  }, {}));

  const houseRatings = Object.values(flattened.reduce((acc, n) => {

    if (n.year === latestYear && ['house', 'assembly'].includes(n.chamber) && n.rating !== null) {
      if (!acc[n.party]) {
        acc[n.party] = {
          party: getPartyName(n.party),
          rating: 0,
          members: 0,
        };
      }

      acc[n.party].members++;
      acc[n.party].rating += n.rating;
    }

    return acc;
  }, {}));

  senateRatings.forEach(n => n.rating = (n.rating / n.members).toFixed(0));
  houseRatings.forEach(n => n.rating = (n.rating / n.members).toFixed(0));

  senateRatings.sort((a, b) => b.rating - a.rating);
  houseRatings.sort((a, b) => b.rating - a.rating);

  return {
    senateRatings,
    houseRatings,
  }
}