import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import IssueSelector from "./IssueSelector";
import constants from "../../constants.json";
import SimpleTooltip from "./Tooltip";

import ExpandedTriangleImage from "../../images/expanded-triangle-dark.svg";

const PaddedIssueSelector = styled(IssueSelector)`
  padding: 16px;
`;

const Wrapper = styled("div")`
  display: inline-block;

  span {
    font-weight: normal;
    font-size: 16px;

    &.simple-tooltip {
      font-weight: inherit;
      font-size: inherit;
    }
  }

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    position: relative;
  }
`;

const ExpandTriangle = styled("img")`
  margin-bottom: ${(props) => (props.expanded ? "1px" : "2px")};
  margin-left: 4px;
  ${(props) => (!props.expanded ? "transform: rotate(-90deg);" : "")};
`;

const IssueFilter = styled("a")`
  text-decoration: underline;
  margin-right: 20px;
  cursor: pointer;
  outline: none;
  /* color: ${constants.COLORS.GRAY}; */
`;

const IssueSelectorWrapper = styled("div")`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #f0f3f5;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.125);
  z-index: 2000;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    position: absolute;
    right: unset;
    height: 500px;
    width: 300px;
    top: 48px;
  }
`;

const CloseIssueSelectorButton = styled("button")`
  width: 50px;
  height: 50px;
  font-size: 26px;
  position: fixed;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

export default function FloatingIssueSelector(props) {
  const [showIssueSelector, setShowIssueSelector] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState(props.issues || []);
  const selectorRef = useRef();

  useEffect(() => {
    function hideIssueFilterOnBodyClick(e) {
      let el = e.target;
      while (el.parentElement) {
        if (el === selectorRef.current) {
          return;
        }
        el = el.parentElement;
      }
      setShowIssueSelector(false);
    }
    document.body.addEventListener("click", hideIssueFilterOnBodyClick);
    return () =>
      document.body.removeEventListener("click", hideIssueFilterOnBodyClick);
  }, []);

  function onIssuesSelected(issues) {
    if (props.onIssuesSelected) {
      props.onIssuesSelected(issues);
    }
    setSelectedIssues(issues);
  }

  const issues = props.issues || selectedIssues;

  const label =
    props.label ||
    `Filter By Issue ${issues.length ? `(${issues.length})` : ""}`;

  const wrapperStyle = props.alignRight ? { ...props.selectorStyle, right: 16, left: 'unset' } : props.selectorStyle;

  return (
    <Wrapper className={props.className} style={props.style} ref={selectorRef}>
      {showIssueSelector && (
        <IssueSelectorWrapper style={wrapperStyle}>
          <PaddedIssueSelector
            issues={issues}
            onIssuesSelected={onIssuesSelected}
            multiSelectOnly={true}
          />

          <CloseIssueSelectorButton onClick={() => setShowIssueSelector(false)}>
            &#x2715;
          </CloseIssueSelectorButton>
        </IssueSelectorWrapper>
      )}

      <SimpleTooltip
        title="Click here to filter to specific issue areas"
        showFirst={true}
        delay={1000}
      >
        <IssueFilter
          style={props.labelStyle}
          onClick={() => setShowIssueSelector(!showIssueSelector)}
        >
          {label}
          {!props.hideTriangle && (
            <ExpandTriangle
              expanded={showIssueSelector}
              src={ExpandedTriangleImage}
            />
          )}
        </IssueFilter>
      </SimpleTooltip>
    </Wrapper>
  );
}
