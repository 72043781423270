import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 0L11.4545 -2.22531e-07L11.4545 7.2L14 7.2L14 0ZM4.72083e-07 6.6C4.14384e-07 7.26 0.572729 7.8 1.27273 7.8L5.28818 7.8L4.68364 10.542L4.66455 10.734C4.66455 10.98 4.77273 11.208 4.94455 11.37L5.61909 12L9.80636 8.046C10.0418 7.83 10.1818 7.53 10.1818 7.2L10.1818 1.2C10.1818 0.54 9.60909 -3.83865e-07 8.90909 -4.45061e-07L3.18182 -9.45756e-07C2.65364 -9.91931e-07 2.20182 0.299998 2.01091 0.731998L0.089091 4.962C0.031818 5.1 5.90628e-07 5.244 5.7699e-07 5.4L4.72083e-07 6.6Z" fill="white" />
  </Svg>
);
