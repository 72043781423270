import styled from 'styled-components';

const Awards = styled.div`
  margin-bottom: 30px;
  text-align: center;
  background: #b80000;
  border-radius: 6px;
  padding: 24px 12px;
  color: #fff;

  a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const AwardPhoto = styled.div`
  text-align: center;
  border: 1px solid #ccc;
  padding: 18px 6px;
  border-radius: 6px;
  margin-bottom: 24px;

  &:hover {
    background-color: #eee;
  }

  a {
    font-weight: bold;
    text-decoration: none;
  }
`;

const ImageContainer = styled('div')`
  height: 210px;
  margin: 0 16px 16px 16px;
  position: relative;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: top center;
  -webkit-transition: background-image 350ms ease;
  transition: background-image 350ms ease;
`;

const Wrapper = styled('div')`
  .inner {
    max-width: 1200px;
    margin: 0 auto;
  }

  h1 {
    font-size: 32px;
    font-family: "Helvetica Neue";
    margin-top: 0;
  }

  h2 {
    font-size: 24px;
    font-family: "Helvetica Neue";
  }
  
  p, li {
    line-height: 1.5;
  }
`;

const Spacer = styled('div')`
  margin: 24px;
`;

export default {
  Awards,
  AwardPhoto,
  ImageContainer,
  Wrapper,
  Spacer,
};
