import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Hidden, Container, Row, Col } from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import CheckMark from "../icons/checkmark";
import Cross from "../icons/cross";
import Clock from "../icons/clock";
import ThumbsUp from "../icons/thumb-up";
import ThumbsDown from "../icons/thumb-down";

import Loading from "../icons/loading";
import YearSelector from "./YearSelector";
import SearchInput from "./SearchInput";
import Select from "./Select";

import { COLORS } from "../../constants.json";

import stateList from "../util/stateList.json";

const RoundIcon = styled("div")`
  background-color: ${(props) =>
    props.good === null
      ? COLORS.GRAY
      : props.good
      ? COLORS.CONSERVATIVE
      : COLORS.LIBERAL};
  height: ${(props) => props.height || 15}px;
  /* border-radius: 100px; */
  padding: 6px 8px 8px;
  display: inline-block;
  line-height: 1;
  text-transform: uppercase;

  span {
    color: white;
    white-space: nowrap;

    svg {
      padding-right: 4px;
    }
  }
`;

export default function WithAgainst(props) {
  let content;

  if (props.with === null) {
    content = <span>No Vote</span>;
  } else if (props.with) {
    content = (
      <span>
        <ThumbsUp fill="white" /> With Conservatives
      </span>
    );
  } else {
    content = (
      <span>
        <ThumbsDown fill="white" /> Against Conservatives
      </span>
    );
  }

  return (
    <RoundIcon good={props.with === null ? null : props.with}>
      {content}
    </RoundIcon>
  );
}
