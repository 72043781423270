import React from 'react';
import styled from 'styled-components';
import flags from '../../images/flags';

const Flag = styled('img')`
  width: 100%;
  border: 1px solid #e0e0e0;
`;


const FlagInline = styled('img')`
  height: 36px;
  margin-left: 16px;
  margin-bottom: -6px;
  border: 1px solid #e0e0e0;
`;


export default (props) => props.inline ? <FlagInline src={flags[props.state]} /> : <Flag src={flags[props.state]} />;
