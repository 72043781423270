import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import StateMap from '../../components/StateMap';
import { VictoryChart, VictoryTheme, VictoryLine, VictoryAxis, VictoryTooltip } from 'victory';
import Leaderboard from '../../components/Leaderboard';

const IssueStatsWrapper = styled('div')`
  background: white;
  /* padding: 24px; */

  h2 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    /* font-size: 16px; */
    /* line-height: 20px; */

    color: #2D3436;

    img {
      /* position: relative; */
      /* bottom: -1px; */
      /* height: 7px; */
    }
  }
`;

export default function IssueLeaderboard(props) {

  return (
    // <IssueStatsWrapper>
      <Leaderboard {...props} />
    // </IssueStatsWrapper>
  )
}
