import React, { useCallback, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-grid-system';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import Rating from './Rating';
import MultiRatingGraph from './MultiRatingGraph';
import RatingGraph from "./RatingGraph";
import FloatingIssueSelector from './FloatingIssueSelector';
import Loading from './Loading';
import { COLORS } from "../../constants.json";
import toPostgresArray from "../util/toPostgresArray";
import { cacheClient } from '../data/client';
import stateIsAssembly from '../util/stateIsAssembly';

const GET_STATE_QUERY = gql`
  query getStateRatings($state: String, $issues: _text) {
    ratings: ratings_getStatePerformance(args: { stateId: $state, categories: $issues }) {
      rating
      acuVotes
      year
      state
      total
      role
      body
      party
    }
  }
`;

const Wrapper = styled('div')`
  background-color: white;
  padding: 24px;
`;

const Title = styled('h2')`
  margin-top: 0;
  font-size: 20px;
  font-weight: bold;
`;

const SubTitleContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SubTitle = styled('h3')`
  font-family: Roboto, sans-serif;
  color: #2D3436;
  font-size: 16px;
  font-weight: 500;
`;

const ToggleRatingsButton = styled("button")`
  border: 0;
  padding: 8px;
  height: 40px;
  color: #fff;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  color: ${COLORS.GRAY};
  text-decoration: underline;
  background: none;
`;

const Details = styled('p')`
  font-family: Roboto, sans-serif;
  color: #636E72;
  font-size: 14px;
`;

const Disclaimer = styled('p')`
  color: #B2BEC3;
  font-size: 14px;
`;

const NoData = styled('p')`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #666;
  margin: 64px 0;
`;

const Placeholder = styled('div')`
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Underline = styled('span')`
  text-decoration: underline;
`;

const Labels = {
  ALL_UNICAMERAL_SEATS: "All Seats",

  ALL_HOUSE_SEATS: "All House",
  ALL_SENATE_SEATS: "All Senate",
  HOUSE_REPUBLICANS: "House Republicans",
  HOUSE_DEMOCRATS: "House Democrats",
  ALL_ASSEMBLY_SEATS: "All Assembly",
  ASSEMBLY_REPUBLICANS: "Assembly Republicans",
  ASSEMBLY_DEMOCRATS: "Assembly Dems",
  SENATE_REPUBLICANS: "Senate Republicans",
  SENATE_DEMOCRATS: "Senate Democrats",
};

const senateLabels = [
  Labels.ALL_SENATE_SEATS,
  Labels.SENATE_REPUBLICANS,
  Labels.SENATE_DEMOCRATS,
];

const houseLabels = [
  Labels.ALL_HOUSE_SEATS,
  Labels.HOUSE_REPUBLICANS,
  Labels.HOUSE_DEMOCRATS,
];

const assemblyLabels = [
  Labels.ALL_ASSEMBLY_SEATS,
  Labels.ASSEMBLY_REPUBLICANS,
  Labels.ASSEMBLY_DEMOCRATS,
];

const getDataColor = (label) => {
  switch (label) {
    case Labels.ALL_UNICAMERAL_SEATS:
    case Labels.ALL_ASSEMBLY_SEATS:
    case Labels.ALL_HOUSE_SEATS:
      return COLORS.GRAY;
    case Labels.ALL_SENATE_SEATS:
      return COLORS.GRAY;
    case Labels.ASSEMBLY_REPUBLICANS:
    case Labels.HOUSE_REPUBLICANS:
      return COLORS.CONSERVATIVE;
    case Labels.ASSEMBLY_DEMOCRATS:
    case Labels.HOUSE_DEMOCRATS:
      return COLORS.LIBERAL;
    case Labels.SENATE_REPUBLICANS:
      return COLORS.CONSERVATIVE;
    case Labels.SENATE_DEMOCRATS:
      return COLORS.LIBERAL;
  }
};

function getLabelForData(d, state) {
  if (d.role === 'CHAMBER') {
    switch (d.body) {
      case 'A':
        return Labels.ALL_ASSEMBLY_SEATS;
      case 'H':
        return Labels.ALL_HOUSE_SEATS;
      case 'S':
        return state === 'NE' ? Labels.ALL_UNICAMERAL_SEATS : Labels.ALL_SENATE_SEATS;
      default:
        return '';
    }
  } else if (d.role === 'PARTY_AND_CHAMBER') {
    switch (d.body + d.party) {
      case 'HR':
        return Labels.HOUSE_REPUBLICANS;
      case 'AR':
        return Labels.ASSEMBLY_REPUBLICANS;
      case 'HD':
        return Labels.HOUSE_DEMOCRATS;
      case 'AD':
        return Labels.ASSEMBLY_DEMOCRATS;
      case 'SR':
        return Labels.SENATE_REPUBLICANS;
      case 'SD':
        return Labels.SENATE_DEMOCRATS;
      default:
        return '';
    }
  }
  return '';
}

const filterDataByLabel = (data, label) => {
  switch (label) {
    case Labels.ALL_UNICAMERAL_SEATS:
      return data.filter((d) => d.body === "S" && d.role === "CHAMBER");

    case Labels.ALL_HOUSE_SEATS:
      return data.filter((d) => d.body === "H" && d.role === "CHAMBER");
    case Labels.ALL_SENATE_SEATS:
      return data.filter((d) => d.body === "S" && d.role === "CHAMBER");
    case Labels.ALL_ASSEMBLY_SEATS:
      return data.filter((d) => d.body === "A" && d.role === "CHAMBER");
    case Labels.HOUSE_REPUBLICANS:
      return data.filter(
        (d) =>
          d.body === "H" && d.party === "R" && d.role === "PARTY_AND_CHAMBER"
      );
    case Labels.HOUSE_DEMOCRATS:
      return data.filter(
        (d) =>
          d.body === "H" && d.party === "D" && d.role === "PARTY_AND_CHAMBER"
      );

    case Labels.ASSEMBLY_REPUBLICANS:
      return data.filter(
        (d) =>
          d.body === "A" && d.party === "R" && d.role === "PARTY_AND_CHAMBER"
      );
    case Labels.ASSEMBLY_DEMOCRATS:
      return data.filter(
        (d) =>
          d.body === "A" && d.party === "D" && d.role === "PARTY_AND_CHAMBER"
      );


    case Labels.SENATE_REPUBLICANS:
      return data.filter(
        (d) =>
          d.body === "S" && d.party === "R" && d.role === "PARTY_AND_CHAMBER"
      );
    case Labels.SENATE_DEMOCRATS:
      return data.filter(
        (d) =>
          d.body === "S" && d.party === "D" && d.role === "PARTY_AND_CHAMBER"
      );
  }
};

export default function StatePerformance(props) {
  const lastToggled = useRef(0);
  const [toggledRatingsData, setToggledRatingsData] = useState([]);
  const [showRatingsBreakdowns, setShowRatingsBreakdowns] = useState(true);
  const { state, year } = props;

  const isAssembly = stateIsAssembly(state);

  if (!state) {
    return;
  }

  const [selectedIssues, setSelectedIssues] = useState(props.issues || []);
  function onSelectIssues(issues) {
    if (props.onSelectIssues) {
      props.onSelectIssues(issues);
    }
    setSelectedIssues(issues);
  }

  const issues = props.issues || selectedIssues;

  const { data, loading, error } = useQuery(GET_STATE_QUERY, {
    variables: {
      state,
      issues: toPostgresArray(issues)
    },
    client: cacheClient,
  });

  console.log(data);

  function getRatingsData(data, label) {
    return {
      data: filterDataByLabel(data.ratings, label),
      label: label,
      color: getDataColor(label),
      fill: assemblyLabels.includes(label) || houseLabels.includes(label) ? "#fff" : getDataColor(label),
    }
  }

  // initialize graph data
  useEffect(() => {
    if (data && showRatingsBreakdowns) {

      if (state === 'NE') {
        setToggledRatingsData([
          getRatingsData(data, Labels.ALL_UNICAMERAL_SEATS),
        ]);
      } else if (isAssembly) {
        setToggledRatingsData([
          getRatingsData(data, Labels.ALL_ASSEMBLY_SEATS),
          getRatingsData(data, Labels.ALL_SENATE_SEATS),
          getRatingsData(data, Labels.SENATE_REPUBLICANS),
          getRatingsData(data, Labels.ASSEMBLY_REPUBLICANS),
          getRatingsData(data, Labels.SENATE_DEMOCRATS),
          getRatingsData(data, Labels.ASSEMBLY_DEMOCRATS),
        ]);
      } else {
        setToggledRatingsData([
          getRatingsData(data, Labels.ALL_HOUSE_SEATS),
          getRatingsData(data, Labels.ALL_SENATE_SEATS),
          getRatingsData(data, Labels.SENATE_REPUBLICANS),
          getRatingsData(data, Labels.HOUSE_REPUBLICANS),
          getRatingsData(data, Labels.SENATE_DEMOCRATS),
          getRatingsData(data, Labels.HOUSE_DEMOCRATS),
        ]);
      }


    }
  }, [data, showRatingsBreakdowns]);

  // useEffect(() => {

  //   if (data && toggledRatingsData.length === 0 && lastToggled.current === 1) {
  //     setShowRatingsBreakdowns(false);
  //   }

  //   lastToggled.current = toggledRatingsData.length;
  // }, [data, toggledRatingsData]);

  const isDatasetToggled = (label) => {
    return toggledRatingsData.filter((ratingData) => ratingData.label === label).length > 0;
  };

  const getLegendData = (labels, hasFill) => {
    return labels.map((label) => {
      const color = isDatasetToggled(label) ? getDataColor(label) : "#B2BEC3";
      return {
        name: label,
        symbol: {
          fill: hasFill ? color : "#fff",
          type: "square",
          strokeWidth: 3,
          stroke: color,
        },
        labels: {
          fill: color,
        },
      };
    });
  };

  const toggleRatingsData = useCallback((toggledDataLabel) => {
    const idx = toggledRatingsData.findIndex((d) => (d.label === toggledDataLabel));

    if (idx === -1) {
      // toggle on
      setToggledRatingsData([
        ...toggledRatingsData,
        {
          label: toggledDataLabel,
          data: filterDataByLabel(data.ratings, toggledDataLabel),
          color: getDataColor(toggledDataLabel),
          fill: houseLabels.includes(toggledDataLabel)
            ? "#fff" : getDataColor(toggledDataLabel),
        },
      ]);
    } else {
      // toggle off
      setToggledRatingsData([
        ...toggledRatingsData.slice(0, idx),
        ...toggledRatingsData.slice(idx + 1),
      ]);
    }
  }, [data, toggledRatingsData]);

  if (loading) {
    return (
      <Wrapper>
        {/* <Title>Rating in <TextSelector options={issues} value={issue} onChange={setIssue} /></Title> */}
        {/* <Title>Rating in <Select options={issues} value={issue} onChange={setIssue} /></Title> */}
        <Title>Rating in <FloatingIssueSelector issues={issues} label={issues.length ? `${issues.length} categor${issues.length > 1 ? 'ies' : 'y'}` : 'All categories'} style={{ marginLeft: 8 }} onIssuesSelected={onSelectIssues} /></Title>
        <Placeholder>
          <Loading />
        </Placeholder>
      </Wrapper >
    );
  }

  if (error) {
    console.warn(error);
    return (
      <Wrapper>
        <Title>Rating in <Underline>All issues</Underline></Title>
        <p>Something went wrong. Please try again later.</p>
      </Wrapper>
    );
  }

  if (!data.ratings.length) {
    return (
      <Wrapper>
        <Title>Rating in <FloatingIssueSelector issues={issues} label={issues.length ? `${issues.length} categor${issues.length > 1 ? 'ies' : 'y'}` : 'All categories'} style={{ marginLeft: 8 }} onIssuesSelected={onSelectIssues} /></Title>
        <p>No data available.</p>
      </Wrapper>
    );
  }

  const generatePointLabelText = (datum) => {

    const label = getLabelForData(datum);
    const fraction = datum.total
      ? `${datum.acuVotes} / ${datum.total}`
      : "no votes";
    const pointRating = datum.rating ? datum.rating.toFixed(2).replace(/\.?0+$/, "") : undefined;

    return [label, `${pointRating}%`, fraction, datum.year]
      .filter((n) => n)
      .join("\n");
  };
  const msg = Math.random();
  const ratingsData = getRatings(data, issues, year, msg);

  const {
    rating,
    lifetimeRating,
    trend,
    selectedVotes,
    allVotes,
    mostRecentYear,
  } = ratingsData;

  let legendData = [
    ...getLegendData(senateLabels, true),
    ...getLegendData(isAssembly ? assemblyLabels : houseLabels, false),
  ];
  
  if (state === 'NE') {
    legendData = getLegendData([Labels.ALL_UNICAMERAL_SEATS], true);
  }

  const getRatingsGraph = () => {
    if (showRatingsBreakdowns) {
      return (
        <>
          <SubTitleContainer>
            <SubTitle>Trend: Ratings breakdown over time</SubTitle>
            <ToggleRatingsButton
              onClick={() => setShowRatingsBreakdowns(false)}
            >
              Show Rating By Year
            </ToggleRatingsButton>
          </SubTitleContainer>
          <MultiRatingGraph
            height="210"
            toggle={toggleRatingsData}
            data={toggledRatingsData}
            legendData={legendData}
            generatePointLabelText={generatePointLabelText}
          />
        </>
      );
    }

    return (
      <>
        <SubTitleContainer>
          <SubTitle>Trend: Rating over time</SubTitle>
          <ToggleRatingsButton onClick={() => setShowRatingsBreakdowns(true)}>Show Detailed Breakdown</ToggleRatingsButton>
        </SubTitleContainer>
        <RatingGraph height="210" data={trend} />
      </>
    );
  };

  function getThisYearChange() {
    // TODO: Remove
    return '';

    if (!props.year) {
      return null;
    }

    if (!props.ratingChange) {
      return `No change from ${props.year - 1}`;
    }

    return `${props.ratingChange >= 0 ? '+' : ''}${props.ratingChange} since ${props.year - 1}`;
  }

  function getLifetimeChange() {
    // TODO: Remove
    return '';

    if (!props.year) {
      return null;
    }

    if (!props.lifetimeRatingChange) {
      return `No change from ${props.year - 1}`;
    }

    return `${props.lifetimeRatingChange >= 0 ? '+' : ''}${props.lifetimeRatingChange} since ${props.year - 1}`;
  }

  function getDetails(votes, sponsored) {
    const v = votes ? `${comma(votes)} votes` : 'No votes';
    return v;
    const s = sponsored ? `${sponsored} sponsored` : 'none sponsored';
    return `${v}, ${s}`;
  }

  const currentYear = year || mostRecentYear;

  return (
    <Wrapper>
      {/* <Title>Performance in <TextSelector options={issues} value={issue} onChange={setIssue} /></Title> */}
      <Title>
        <span>Rating in </span>
        <FloatingIssueSelector
          issues={issues}
          label={
            issues.length
              ? `${issues.length} categor${issues.length > 1 ? "ies" : "y"}`
              : "All categories"
          }
          style={{ marginLeft: 2 }}
          onIssuesSelected={onSelectIssues}
        />
      </Title>
      <Row justify="between">
        <Col md={6} lg={5}>
          <Row>
            <Col sm={6}>
              <SubTitle>{currentYear} rating*</SubTitle>
              {rating !== null ? (
                <Rating rating={fix(rating)} detail={getThisYearChange()} />
              ) : (
                <NoData>No data</NoData>
              )}

              <SubTitle>{currentYear} votes</SubTitle>
              <Details>{comma(selectedVotes)} votes</Details>
            </Col>

            <Col sm={6}>
              <SubTitle>All-time rating*</SubTitle>
              <Rating
                rating={fix(lifetimeRating)}
                detail={getLifetimeChange()}
              />

              <SubTitle>All-time votes</SubTitle>
              <Details>
                {getDetails(allVotes, props.lifetimeSponsorships)}
              </Details>
            </Col>
          </Row>

          <Disclaimer>*Based on votes cast on bills rated by CPAC</Disclaimer>
        </Col>

        <Col md={6} lg={7}>
          {getRatingsGraph()}
        </Col>
      </Row>
    </Wrapper>
  );
}

function comma(n) {

  let str = '' + n;
  let result = '';
  for (let i = 1; i <= str.length; i++) {
    if (i > 1 && (i - 1) % 3 === 0) {
      result = ',' + result;
    }
    result = str[str.length - i] + result;
  }
  return result;
}

function fix(n) {
  try {
    return n.toFixed();
  } catch {
    return '';
  }
}

function average(nums) {
  const result = nums.reduce((acc, n) => acc + n, 0) / nums.length;
  if (isNaN(result)) {
    return null;
  }
  return result;
}

function getRatings({ ratings }, issues, year, msg) {

  const mostRecentYear = ratings.reduce((max, n) => Math.max(max, n.year), 0);

  let trend, rating, lifetimeRating, selectedVotes, allVotes;

  trend = averageByYear(ratings.filter(n => n.role === 'ALL'));
  lifetimeRating = average(ratings.filter(n => n.role === 'ALL').map(n => n.rating));
  rating = average(ratings.filter(n => n.year == (year || mostRecentYear) && n.role === 'ALL').map(n => n.rating));

  // if (issues && issues.length) {
  selectedVotes = ratings
    .filter((r) => r.year === (year || mostRecentYear) && r.role === "ALL")
    .reduce((total, n) => total + n.total, 0);
  // }

  allVotes = ratings
    .filter((r) => r.role === "ALL")
    .reduce((total, n) => total + n.total, 0);

  return {
    trend,
    rating,
    lifetimeRating,
    selectedVotes,
    allVotes,
    mostRecentYear,
  };
}


function averageByYear(ratings) {
  return Object.values(
    ratings.reduce((acc, n) => {
      if (!acc[n.year]) {
        acc[n.year] = {
          count: 0,
          sum: 0,
          year: n.year,
        };
      }

      acc[n.year].count++;
      acc[n.year].sum += n.rating;

      return acc;
    }, {})
  ).map(({ count, sum, year }) => ({ rating: sum / count, year }));
}