import React from 'react';
import styled from 'styled-components';


const ScrollableContainerWrapper = styled('div')`
  /* max-height: ${props => props.height}px; */
  /* overflow: scroll; */
  /* padding-bottom: 24px; */
  position: relative;
`;

const ScrollableContainerInner = styled('div')`
  max-height: ${props => props.height}px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 36px;
  position: relative;
`;

const ScrollableContainerFade = styled('div')`
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(0,121,255,0) 100%);
  width: 100%;
  height: 36px;
  display: block;
  position: absolute;
  content: ' ';
  bottom: 0;
  z-index: 2;
  pointer-events: none;
`;

export default function ScrollableContainer(props) {
  return (
    <ScrollableContainerWrapper>
      <ScrollableContainerInner {...props}>
        {props.children}
      </ScrollableContainerInner>
      <ScrollableContainerFade />
    </ScrollableContainerWrapper>
  )
}
