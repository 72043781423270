const { THRESHOLDS } = require('../../constants.json');

module.exports = function meetsThreshold(votes, state, chamberOrRole = '', year, id) {
  const threshold = getThreshold(state, chamberOrRole, year, id);
  const meets = votes >= threshold;
  return meets;
}


function matchesChamberOrRole(chamber, role, chamberOrRole='') {
  const lower = chamberOrRole.toLowerCase();
  if (!chamber && !role) {
    return true;
  }

  if (chamber && chamber === lower) {
    return true;
  }

  if (role && role === lower) {
    return true;
  }

  return false;
}

function getThreshold(state, chamberOrRole = '', year, id) {
  const specific = THRESHOLDS.find(t => {

    const startIsBefore = (!t.start || t.start <= year);
    const endIsAfter = (!t.end || t.end >= year);
    const stateEqual = (!t.state || t.state === state);

    const peopleMatch = !t.peopleId || t.peopleId === id;
    
    const chamberOrRoleEqual = matchesChamberOrRole(t.chamber, t.role, chamberOrRole);

    return (
      peopleMatch &&
      startIsBefore &&
      endIsAfter &&
      stateEqual &&
      chamberOrRoleEqual
    );
  });

  return (specific && specific.threshold) || 0;
}
