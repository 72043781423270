const { useState, useEffect } = require('react');

let listeners = [];

export default function useBackground(initial) {
  const [background, setBackground] = useState(initial);
  useEffect(() => {
    listeners.push(setBackground);

    return () => {
      listeners = listeners.filter(n => n !== setBackground);
    };
  }, [setBackground]);

  function set(value) {
    listeners.forEach(fn => fn(value));
  }

  return [background, set];
}