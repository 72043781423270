import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  withDefault,
} from "use-query-params";

import BillsList from "../components/BillsList";
import SearchInput from "../components/SearchInput";
import StateSelector from "../components/StateSelector";
import YearSelector from "../components/YearSelector";
import PartySelector from "../components/PartySelector";
import Select from "../components/Select";
import Footer from "../components/Footer";
import usePageTitle from "../util/usePageTitle";

const Wrapper = styled("div")`
  max-width: 1280px;
  margin: 0 auto;
`;

const Results = styled("div")`
  max-width: 1200px;
`;

const Title = styled("h1")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
  margin: 0;
  color: #2d3436;
`;

const Form = styled(Row)`
  padding: 16px;
  > * {
    margin-right: 24px;
  }
`;

const Options = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  p:first-child {
    font-weight: bold;
    color: #636e72;
  }
  > p {
    color: #636e72;
    display: inline-block;
    margin-right: 32px;

    span {
      color: #636e72;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const SortLabel = styled("div")`
  display: inline-block;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`;

const statusOptions = [
  {
    label: "Any status",
    value: null,
  },
  {
    label: "Passed",
    value: "Passed",
  },
  {
    label: "Defeated",
    value: "Defeated",
  },
];

const acuVoteOptions = [
  {
    label: "Any position",
    value: null,
  },
  {
    label: "Conservatives support",
    value: 1,
  },
  {
    label: "Conservatives oppose",
    value: -1,
  },
];

// TODO: Move into shared component
const chamberOptions = [
  {
    label: "All chambers",
    value: null,
  },
  {
    label: "House",
    value: "H",
  },
  {
    label: "Senate",
    value: "S",
  },
  {
    label: "Assembly",
    value: "A",
  },
];

const limitOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "250", value: 250 },
  { label: "500", value: 500 },
];

const BottomRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 16px;
  margin-bottom: 32px;
`;

export default function BillsPage(props) {
  const [search, setSearch] = useQueryParam("search", StringParam);
  const [state, setState] = useQueryParam("state", StringParam);
  const [year, setYear] = useQueryParam("year", NumberParam);

  const [chamber, setChamber] = useQueryParam("chamber", StringParam);
  const [status, setStatus] = useQueryParam("status", StringParam);
  const [acuVote, setAcuVote] = useQueryParam("acuPosition", NumberParam);

  // const [limit, setLimit] = useState(10);
  const [limit, setLimit] = useQueryParam(
    "limit",
    withDefault(NumberParam, 50)
  );
  const [loadingMore, setLoadingMore] = useState(false);
  const [bills, setBills] = useState([]);
  const [count, setCount] = useState(null);

  function getResultCount() {
    // if (loading) return null;
    // if (!count) return null;
    if (!count) return "";

    const n = count;

    return `${n} result${n === 1 ? "" : "s"}`;
  }

  usePageTitle("Bill Search");

  return (
    <Wrapper>
      <Title>Search for bills</Title>
      <Form>
        <SearchInput
          placeholder={`"HB 1234" or "plastic bags"`}
          value={search}
          onValue={setSearch}
          debounce={500}
          width={220}
        />
        <StateSelector value={state} onChange={setState} />
        <Select
          placeholder="Status"
          value={statusOptions.find((o) => !status || o.value === status)}
          options={statusOptions}
          width={140}
          onChange={(s) => setStatus(s.value)}
        />
        <Select
          placeholder="Any position"
          value={acuVoteOptions.find((o) => !acuVote || o.value === acuVote)}
          options={acuVoteOptions}
          width={170}
          onChange={(o) => setAcuVote(o.value)}
        />
        <Select
          placeholder="All chambers"
          value={chamberOptions.find((o) => !chamber || o.value === chamber)}
          options={chamberOptions}
          width={160}
          onChange={(o) => setChamber(o.value)}
        />
        <YearSelector value={year} onChange={setYear} />
      </Form>
      <Options>
        <p>{loadingMore ? "Loading..." : getResultCount()}</p>
        <SortLabel>
          Show:{" "}
          <Select
            options={limitOptions}
            value={limitOptions.find((o) => o.value === limit)}
            onChange={(o) => setLimit(o.value)}
          />
        </SortLabel>
      </Options>
      <Results>
        <BillsList
          title="Search results"
          onChange={setBills}
          year={year}
          state={state}
          search={search}
          status={status}
          acuVote={acuVote}
          chamber={chamber}
          hideControls={true}
          hideSeeAllLink={true}
          onLoading={setLoadingMore}
          onCount={setCount}
          limit={limit}
        />
        <BottomRow>
          <SortLabel>
            Show:{" "}
            <Select
              options={limitOptions}
              value={limitOptions.find((o) => o.value === limit)}
              onChange={(o) => setLimit(o.value)}
            />
          </SortLabel>
        </BottomRow>
      </Results>
      <Footer margin="24px 0 48px" />
    </Wrapper>
  );
}
