import React from 'react';
import styled from 'styled-components';
import { colors } from '../util/colorScale';

const LegendScale = styled('div')`
  margin-top: 8px;
  height: 16px;
  width: 100%;
  background: linear-gradient(90deg, ${colors.join(', ')});
`;

const Legend = styled('div')`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &>div {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      text-transform: uppercase;
      color: #636E72;
    }

    img {
      margin-top: 8px;
    }
  }
`;


export default function MapLegend() {
  return (
    <Legend>
      <div>
        <span>Liberal</span>
        <span>Conservative</span>
      </div>
      <div>
        <LegendScale />
      </div>
    </Legend>
  );
}
