import React, { useEffect, useState, useRef } from 'react';
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryLegend,
  VictoryGroup,
  VictoryScatter,
  VictoryLabel,
  VictoryVoronoiContainer,
} from 'victory';
import gql from 'graphql-tag';
import { scaleLinear } from 'd3';
import { getRole } from '../util/person';
import client from '../data/client';
import toPostgresArray from '../util/toPostgresArray';
import threshold from '../util/threshold';
import LoadingBlock from './LoadingBlock';


const GET_PERFORMANCE_QUERY = gql`
query(
  $id: String!
  $issues: _text
) {

  ratings_people(limit: 1, where: { id: { _eq: $id } }) {
    firstName
    lastName
    history {
      chamber
      state
      year
    }
  }

  performance: ratings_getPersonPerformance(args: {
    peopleId: $id,
    categories: $issues
  }) {
    peopleId
    rating
    year
    acuVotes
    votes
    total
  }
}
`;


const defaultGray = '#636E72';

const colors = [
  '#b51b62',
  '#303c4f',
  '#de703c',
  '#449186',
];

function getLabel(n) {
  let fraction = '';
  if (
    typeof n.total === 'number' &&
    typeof n.acuVotes === 'number'
  ) {
    if (n.total === 0) {
      fraction = 'no votes';
    } else {
      fraction = ` ${n.acuVotes}/${n.total}`;
    }
  }

  let rating = n.rating;
  if (typeof n.rating === 'number') {
    rating = n.rating.toFixed(2).replace(/\.?0+$/, '');
  }

  return [
    `${rating}%`,
    fraction,
    n.year,
    `${n.role}. ${n.firstName} ${n.lastName}`,
  ].filter(n => n).join('\n');
}

export default function RatingGraph(props) {

  const ref = useRef();
  const [loading, setLoading] = useState(false);
  const [fontSize, setFontSize] = useState(10);
  const [data, setData] = useState([]);

  const axisStyle = {

    axis: {
      stroke: defaultGray,
    },
    tick: {
      stroke: defaultGray,
    },
    tickLabels: {
      fill: defaultGray,
      fontSize,
    },
  };

  useEffect(() => {

    function updateSize() {
      if (ref.current) {
        const width = ref.current.offsetWidth;
        setFontSize(20 - (width / 150));
      }
    }

    updateSize();

    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [ref]);


  useEffect(() => {
    setLoading(true);
    let mounted = true;
    const results = props.ids.map(async (id) => {
      const { data } = await client.query({ query: GET_PERFORMANCE_QUERY, variables: { id, issues: toPostgresArray(props.issues) } });
      const [p] = data.ratings_people || [{ history: {} }];
      const person = { ...p };

      person.role = getRole(person);

      const mapped = data.performance.map(n => {

        const history = person.history.find(h => h.year === n.year) || {};
        const meets = threshold(n.votes / n.total, history.state, history.chamber, n.year, id);

        return {
          label: getLabel({ ...n, ...person }),
          x: n.year,
          // y: meets ? n.rating : null,
          y: n.rating,
          acuVotes: n.acuVotes || 0,
          votes: n.votes,
          total: n.total,
          meets,
          // label: `${n.year}: ${typeof n.rating === 'number' ? n.rating.toFixed(2) : n.rating}%`,
        };
      }).sort((a, b) => a.year - b.year);


      const maxAcuVotes = data.performance.reduce((total, n) => Math.max(total, n.acuVotes || 0), 0);
      const sizeScale = scaleLinear().domain([0, maxAcuVotes]).range([2, 4]);

      return {
        lastName: person.lastName,
        role: person.role,
        data: mapped.filter(n => n.meets),
        scale: sizeScale,
      };
    });

    Promise.all(results)
      .then(res => {
        if (mounted) setData(res);
        setLoading(false)
      })
      .catch(() => setLoading(false));

    return () => mounted = false;

  }, [props.ids, props.issues]);

  const filteredColors = colors.filter((_, i) => data[i]?.data?.length);

  if (!data?.length) {
    return (
      <div style={{ width: '100%' }} ref={ref}>
        {loading ? <LoadingBlock fullWidth height={300} /> : <p>Select a lawmaker to view their ratings over time.</p>}
      </div>
    )
  }

  return (


      <div style={{ width: '100%', position: 'relative' }} ref={ref}>
        <VictoryChart
          style={{ parent: { maxWidth: "100%" } }}
          // theme={VictoryTheme.material}
          height={285}
          padding={{ top: 8, left: 36, bottom: 30 + 32, right: 24 }}
        >

          <VictoryAxis
            tickFormat={(d, i) => d % 1 != 0 ? '' : '' + d}
            style={axisStyle}
          />

          <VictoryAxis
            dependentAxis
            style={axisStyle}
            domain={[-1, 101]}
          />

          <VictoryGroup colorScale={filteredColors}>
            {data.map(p => <VictoryLine
              key={p.data.label}
              interpolation="linear"
              data={p.data}
              // labels={({ datum }) => datum.y}
              labelComponent={
                < VictoryTooltip
                  cornerRadius={2}
                  flyoutStyle={{
                    stroke: 'none',
                    fill: '#f0f0f0'
                  }}
                />
              }
            />)}
          </VictoryGroup>

          <VictoryGroup colorScale={filteredColors}>
            {data.map(p => <VictoryScatter
              key={p.data.label}
              data={p.data}
              size={d => p.scale(d.datum.acuVotes || 0)}
              style={{ parent: { border: "1px solid #ccc" } }}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={2}
                  flyoutStyle={{
                    stroke: 'none',
                    fill: '#f0f0f0',
                  }}
                  labelComponent={<VictoryLabel lineHeight={1.5} backgroundPadding={6} />}
                />
              }
            />)}
          </VictoryGroup>



          <VictoryLegend
            // x={100}
            x={25}
            y={250}
            // itemsPerRow={2}

            // title="Legend"
            centerTitle
            orientation="horizontal"
            gutter={16}
            style={{ labels: { fontSize: fontSize * .9 } }}
            // style={{ border: { stroke: "black" }, title: { fontSize: 20 } }}
            colorScale={filteredColors}
            data={data.filter(n => n.data.length).map(n => ({ name: `${n.role}. ${n.lastName}` }))}
          />

        </VictoryChart>
        { loading && <LoadingBlock style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, height: '90%' }} /> }

      </div>

  )
}
