import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import stateList from '../util/stateList.json';


const Wrapper = styled(Select)`
  >div {
    border-radius: 0;
  }
`;

export default function StateMultiSelector(props) {
  
  const {onChange=()=>{}} = props;

  const defaultStateSelection = stateList[0];

  const [states, setStates] = useState([defaultStateSelection]);

  useEffect(() => {
    setStates(props.value);
  }, [props.value]);

  function onStateChange(newStates) {
    let update = newStates;
    
    if (!newStates || newStates.length === 0) {
      update = [defaultStateSelection];
    } else if (newStates.length > 1) {
      update = newStates.filter(s => s.value != defaultStateSelection.value);
    }

    setStates(update);
    onChange(update);
  }

  function removeSelectedState(state) {
    if (states.length === 1) {
      return;
    }
    const update = states.filter(n => n.id !== state.id);
    setStates(update);
    onChange(update);
  }

  return (

    <Wrapper
      isMulti
      onChange={onStateChange}
      options={stateList}
      placeholder="Select a state"
      removeSelected={removeSelectedState}
      simpleValue
      value={states}
    />
  )

}
