import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Hidden,
  Container,
  Row,
  Col,
  ScreenClassRender,
} from "react-grid-system";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  useQueryParam,
  NumberParam,
  StringParam,
  withDefault,
} from "use-query-params";
import toPostgresArray from "../util/toPostgresArray";

import CheckMark from "../icons/checkmark";
import Cross from "../icons/cross";
import Clock from "../icons/clock";
import ThumbsUp from "../icons/thumb-up";
import ThumbsDown from "../icons/thumb-down";

import Loading from "../icons/loading";
import YearSelector from "./YearSelector";
import SearchInput from "./SearchInput";
import Select from "./Select";
import Tooltip from "./Tooltip";

import { COLORS } from "../../constants.json";

import mapState from "../util/stateAbbrMap";
import stateList from "../util/stateList.json";

import gql from "graphql-tag";
import { cacheClient } from "../data/client";
import LoadingBlock from "./LoadingBlock";

const GET_BILLS_FOR_PERSON = gql`
  query billsForPerson(
    $peopleId: String!
    $year: Int
    $limit: Int = 10
    $withAcu: Boolean
    $search: String
    $group: String
    $categories: [String!]
    $billNumber: String
  ) {
    count: ratings_ratedArticles_aggregate(
      where: {
        _or: [
          { categorizations: { category: { name: { _ilike: $search } } } }
          { title: { _ilike: $search } }
          { categorizations: { category: { groupName: { _ilike: $search } } } }
          { billNumber: { _ilike: $search } }
          { description: { _ilike: $search } }
        ]
        peopleId: { _eq: $peopleId }
        year: { _eq: $year }
        acuVote: { _is_null: false }
        withAcu: { _eq: $withAcu }
        categorizations: { categoryId: { _like: $group, _in: $categories } }
      }
    ) {
      aggregate {
        count
      }
    }

    bills: ratings_ratedArticles(
      limit: $limit
      where: {
        _or: [
          { categorizations: { category: { name: { _ilike: $search } } } }
          { title: { _ilike: $search } }
          { categorizations: { category: { groupName: { _ilike: $search } } } }
          { billNumber: { _ilike: $search } }
          { description: { _ilike: $search } }
        ]
        peopleId: { _eq: $peopleId }
        year: { _eq: $year }
        acuVote: { _is_null: false }
        withAcu: { _eq: $withAcu }
        categorizations: { categoryId: { _like: $group, _in: $categories } }
      }
      order_by: { year: desc_nulls_last }
    ) {
      id
      title
      summary
      billNumber
      status
      statusDate
      inFavor
      opposed
      notVoting
      vote
      acuVote
      withAcu
      support
      peopleId
      state
      year
      chamber: body

      categorizations {
        categoryId
        category {
          name
        }
      }
    }
  }
`;

const GET_BILLS_FOR_YEAR = gql`
  query billsForYear(
    $year: Int
    $limit: Int = 10
    $search: String
    $status: String
    $chamber: String
    $group: String
    $categories: _text
    $support: Boolean
    $state: String
    $states: _text
    $billNumber: String
  ) {
    count: ratings_searchArticles_aggregate(
      args: {
        year: $year
        limit: 99999
        search: $search
        status: $status
        chamber: $chamber
        group: $group
        categories: $categories
        support: $support
        state: $state
        states: $states
        billNumber: $billNumber
      }
    ) {
      aggregate {
        count
      }
    }

    bills: ratings_searchArticles(
      args: {
        year: $year
        limit: $limit
        search: $search
        status: $status
        chamber: $chamber
        group: $group
        categories: $categories
        support: $support
        state: $state
        states: $states
        billNumber: $billNumber
      }
    ) {
      id
      title
      summary
      billNumber
      status
      year
      state
      support
      chamber: body
      categorizations {
        categoryId
        category {
          name
        }
      }
      inFavor
      opposed
      notVoting
      acuVote
    }
  }
`;

const Wrapper = styled("div")`
  background: white;
  padding: 24px;
  padding-bottom: 12px;

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    margin-top: 0;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    a {
      margin-left: 16px;
      font-size: 14px;
      color: #636e72;
      margin-bottom: 0;
      line-height: 1;
      margin-top: 6px;
      text-decoration: none;
    }
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2d3436;
  }
`;

const ColorBand = styled("span")`
  color: white;
  background: ${(props) => (props.conservative ? "#CC4748" : "#0984E3")};
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 8px;

  min-width: 120px;
  text-align: center;

  img,
  svg {
    margin-right: 2px;
  }
`;

// const IssueRowWrapper = styled('div')

const BillStatus = styled("div")`
  margin: 0;

  img {
    max-height: 13px;
    margin-right: 2px;
  }
`;

const Votes = styled("p")`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #636e72;
  margin: 8px 0 0;
`;

const Title = styled("div")`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 20px; */
  color: #2d3436;
  a {
    /* text-decoration: none; */
  }
`;

const Details = styled("p")`
  margin: 4px 0 0 0;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 1; */
  color: #636e72;
`;

const RoundIcon = styled("div")`
  background-color: ${(props) =>
    props.good === null
      ? COLORS.GRAY
      : props.good
      ? COLORS.CONSERVATIVE
      : COLORS.LIBERAL};
  height: ${(props) => props.height || 15}px;
  /* border-radius: 100px; */
  padding: 6px 8px 8px;
  display: inline-block;
  line-height: 1;
  text-transform: uppercase;

  span {
    color: white;
    white-space: nowrap;

    svg {
      padding-right: 4px;
    }
  }
`;

const OutcomeWrapper = styled("div")`
  text-align: right;

  p {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

function Outcome(props) {
  if (typeof props.support === "undefined") {
    return null;
  }

  if (typeof props.vote === "undefined") {
    const icon = props.support ? (
      <ThumbsUp fill="white" />
    ) : (
      <ThumbsDown fill="white" />
    );

    return (
      <OutcomeWrapper>
        <RoundIcon good={props.support}>
          <span>
            {icon} {props.support ? "Conservative" : "Liberal"}
          </span>
        </RoundIcon>
      </OutcomeWrapper>
    );
  }

  const good = isGood(props);

  let content;
  if (good === null) {
    content = <span>No Vote</span>;
  } else if (good) {
    content = (
      <span>
        <ThumbsUp fill="white" /> Voted conservative
      </span>
    );
  } else {
    content = (
      <span>
        <ThumbsDown fill="white" /> Voted  liberal
      </span>
    );
  }

  return (
    <OutcomeWrapper>
      <RoundIcon good={good}>{content}</RoundIcon>
      <p>{props.support ? "Conservative" : "Liberal"} bill</p>
    </OutcomeWrapper>
  );
}

function getVoteText(vote) {
  switch (vote) {
    case -1:
      return "Opposed";
    case 0:
      return "No Vote";
    case 1:
      return "In Favor";
    default:
      return "Unknown";
  }
}

function Status(props) {
  let icon;

  switch (props.status) {
    case "In Progress":
      icon = <Clock fill={COLORS.TEXT} />;
      break;
    case "Engrossed":
    case "Passed":
      icon = <CheckMark fill={COLORS.TEXT} />;
      break;
    case "Defeated":
      icon = <Cross fill={COLORS.TEXT} />;
      break;
  }

  return (
    <div>
      <BillStatus>
        {icon} {props.status}
      </BillStatus>
      <Votes>
        {props.inFavor} yea, {props.opposed} nay
      </Votes>
    </div>
  );
}

// TODO: Move this to ../util
function makeQueryString(params) {
  return Object.keys(params)
    .map((key) => (params[key] ? `${key}=${params[key]}` : null))
    .filter((n) => n)
    .join("&");
}

const Category = styled("em")`
  font-size: 13px;
  line-height: 1;
`;

function Categories(props) {
  const categorizations = (props.categorizations || []).filter(
    (n) => n.category
  );

  const params = {
    issues: categorizations.map((c) => c.categoryId).join("_"),
    multiselect: 1,
  };

  return (
    <Category>
      <Link to={`/issues?${makeQueryString(params)}`}>
        {categorizations.map((n) => n.category.name).join(", ")}
      </Link>
    </Category>
  );
}

function Descriptions(props) {
  return <Category>{(props.summary || "").slice(0, 150)}...</Category>;
}

const LoadMore = styled("div")`
  text-align: center;
  margin-top: 16px;
  margin-bottom: -8px;
  svg {
    margin: 0 auto;
  }
  span {
    text-decoration: underline;
    margin-top: 16px;
    margin-bottom: 16px;
    display: inline-block;
    cursor: pointer;
  }
`;

function getChamber({ state, chamber }) {
  if (state === "US") {
    return "";
  }
  if (state === "NE") {
    return "Unicameral";
  }

  switch (chamber) {
    case "H":
      return "House";
    case "S":
      return "Senate";
    case "A":
      return "Assembly";
    default:
      return "";
  }
}

const BillRowWrapper = styled(Row)`
  margin: 32px 24px 24px;

  &:first-of-type {
    margin-top: 24px;
  }
`;

function BillRow(props) {
  const chamber = getChamber(props) ? <>&mdash; {getChamber(props)}</> : null;
  return (
    <BillRowWrapper className={props.className} style={props.style}>
      <Col xl={4} lg={6} md={7} sm={6}>
        <Title>
          <Tooltip
            title={`Click here to view the full details for ${props.billNumber}`}
          >
            <Link to={`/bills/${props.id}`}>
              {props.billNumber} &mdash; {props.title}
            </Link>
          </Tooltip>
        </Title>
        <Details>
          {props.year} &mdash; {mapState({ ...props, body: props.chamber })}{" "}
          {chamber}
        </Details>
      </Col>

      <Hidden xs sm md lg>
        <Col xl={3}>
          {/* <Categories {...props} /> */}
          <Descriptions {...props} />
        </Col>
      </Hidden>

      <Hidden xs sm md>
        <Col xl={2} lg={2} md={2} sm={3}>
          <Status {...props} />
        </Col>
      </Hidden>

      <Col xl={3} lg={4} md={5} sm={4}>
        <Outcome {...props} />
      </Col>
    </BillRowWrapper>
  );
}

const HeaderText = styled("h3")`
  margin: 0;
  font-size: 18px;
  text-align: ${(props) => props.align || "left"};
`;

function HeaderRow(props) {
  return (
    <Row {...props}>
      <Col xl={4} lg={6} md={7} sm={6}>
        <HeaderText>Title</HeaderText>
      </Col>

      <Hidden xs sm md lg>
        <Col xl={3}>
          {/* <HeaderText>Categories</HeaderText> */}
          <HeaderText>Description</HeaderText>
        </Col>
      </Hidden>

      <Hidden xs sm md>
        <Col xl={2} lg={2} md={2} sm={3}>
          <HeaderText>Status</HeaderText>
        </Col>
      </Hidden>

      <Col xl={3} lg={4} md={5} sm={4}>
        <HeaderText align="right">Conservative Position</HeaderText>
      </Col>
    </Row>
  );
}

function isGood(props) {
  if (props.vote === 0) {
    return null;
  }

  if (props.support) {
    return props.vote > 0;
  } else if (props.support === false) {
    return props.vote < 0;
  }

  return null;
}

const TitleWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  > div {
    display: flex;
    flex-direction: row;

    > div,
    > p {
      margin-right: 16px;
      input {
        width: 300px;
      }
    }
    > :last-child {
      margin-right: 0;
    }
  }
`;

const SortLabel = styled("div")`
  display: inline-block;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`;

BillsList.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  onLoading: PropTypes.func,
  year: PropTypes.number,
  peopleId: PropTypes.number,
  status: PropTypes.string,
  acuVote: PropTypes.number,
  chamber: PropTypes.string,
  group: PropTypes.string,
  issues: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.string,
  state: PropTypes.string,
  hideControls: PropTypes.bool,
  hideSeeAllLink: PropTypes.bool,
  limit: PropTypes.number,
};

const limitOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "250", value: 250 },
  { label: "500", value: 500 },
  { label: "All", value: 99999 },
];

const Footer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 8px;
`;

function getCategories(props) {
  if (!props.issues || !props.issues.length) {
    return null;
  }

  if (typeof props.peopleId !== "undefined") {
    return props.issues;
  }

  return toPostgresArray(props.issues);
}

export default function BillsList(props) {
  let {
    useCache,
    year,
    peopleId,
    bills,
    status,
    withAcu,
    acuVote,
    chamber,
    group,
    issues,
    state,
    states,
    hideSeeAllLink,
  } = props;

  const isPerson = typeof peopleId !== "undefined";

  const [withOrAgainst, setWithOrAgainst] = useState();
  // const [search, setSearch] = useState('');
  const [search, setSearch] = useQueryParam("search", StringParam);
  // const [limit, setLimit] = useState(props.limit);
  const [limit, setLimit] = useQueryParam(
    "bills-limit",
    withDefault(NumberParam, props.limit || 50)
  );
  const QUERY = props.peopleId ? GET_BILLS_FOR_PERSON : GET_BILLS_FOR_YEAR;

  const billNumber = getBillNumber();

  function getBillNumber() {
    try {
      const [, billChamber, billNumber] = (props.search || search || "").match(
        /([A-Za-z]{1,2})\.? ?(\d+)/
      ) || [null, null];
      return `${billChamber.toUpperCase()} ${billNumber}`;
    } catch (e) {
      return null;
    }
  }

  function getSearch() {
    if (billNumber) return null;
    if (props.search) {
      return `%${props.search}%`;
    } else {
      return search ? `%${search}%` : null;
    }
  }

  function getGroup() {
    if (group) {
      return `${group}%`;
    }
    return null;
  }

  let withOrAgainstOptions;

  const variables = {
    peopleId,
    year,
    limit: props.limit || limit,
    search: getSearch(),
    status,
    chamber,
    group: getGroup(),
    categories: getCategories(props),
    state,
  };

  if (states && states.length && states[0]) {
    variables.states = toPostgresArray(states);
  }

  if (isPerson) {
    withOrAgainstOptions = [
      { label: "With or against Conservatives", value: null },
      { label: "With Conservatives", value: true },
      { label: "Against Conservatives", value: false },
    ];
    variables.withAcu = withOrAgainst && withOrAgainst.value;
  } else {
    // Strangely, if you set a variable, and then don't include it in the next call, it's stil included!
    // That is, if we don't set to null, and it's undefined, it'll keep the last used value!! What the heck!?
    variables.billNumber = billNumber || null;

    if (typeof withAcu !== "undefined") {
      variables.support = withAcu || (withOrAgainst && withOrAgainst.value);
    } else if (typeof acuVote !== "undefined") {
      variables.support = acuVote > 0 ? true : acuVote < 0 ? false : null;
    } else {
      variables.support = withOrAgainst ? withOrAgainst.value : null;
    }

    withOrAgainstOptions = [
      { label: "Any position", value: null },
      { label: "Conservatives support", value: true },
      { label: "Conservatives oppose", value: false },
    ];
  }

  const { data, loading, error } = useQuery(QUERY, {
    variables,
    // client: useCache ? cacheClient : null,
  });

  bills = ((data || props).bills || []).map((b) => ({ ...b }));
  // bills = ((data || props).bills || []).filter(n => !props.hideId || props.hideId != n.id);

  bills.forEach((n) => {
    n.support = n.acuVote > 0 ? true : n.acuVote < 0 ? false : null;

    if (n.votes) {
      const { vote } = n.votes[0];
      n.vote = vote === "Yea" ? 1 : vote === "Nay" ? -1 : 0;
    }

    // TODO: Fix
    // if (n.state) {

    //   const state = stateList.find(n => n.value === n.state);
    //   console.log({ state, n, "n.state": n.state });
    //   n.state = state ? state.name : n.state;
    // }
  });

  const dedupedBills = bills.filter(
    (n) => props.hideId == null || props.hideId != n.id
  );
  // const didHide = dedupedBills.length != bills.length;

  let results;
  if (loading) {
    results = <LoadingBlock height={Math.min(limit * 50, 500)} />;
  } else if (dedupedBills.length) {
    results = dedupedBills.map((p) => <BillRow key={p.id} {...p} />);
  } else {
    results = (
      <p>
        <em>No results</em>
      </p>
    );
  }

  useEffect(() => {
    if (props.onChange) {
      if (loading) {
        props.onChange(null);
      } else {
        props.onChange(bills);
      }
    }
  }, [data, loading, props.onChange]);

  useEffect(() => {
    if (props.onCount) {
      if (loading) {
        props.onCount(null);
      } else {
        try {
          props.onCount(data.count.aggregate.count);
        } catch (e) {
          // props.onCount(0);
        }
      }
    }
  }, [data, props.onCount, loading]);

  useEffect(() => {
    if (props.onLoading) {
      props.onLoading(loading);
    }
  }, [props.onLoading, loading]);

  function getIssueIds(bills) {
    const ids = {};
    for (const bill of bills) {
      try {
        for (const category of bill.categorizations) {
          ids[category.categoryId] = true;
        }
      } catch (e) {}
    }
    return Object.keys(ids);
  }

  function getBillsLink() {
    // Based on issue
    if (props.hideId) {
      const params = {
        issues: getIssueIds(bills).join("_"),
        year,
        state, // TODO: Store states in params in issue explorer
        multiselect: 1,
      };
      return `/issues?${makeQueryString(params)}`;
    } else {
      const params = {
        limit: props.limit || limit,
        search,
        status,
        chamber,
        year,
        state,
      };
      return `/bills?${makeQueryString(params)}`;
    }
  }

  // TODO: Add loading indicator from People.js
  // TODO: Extract LoadMore functionality into HoC
  // TODO: Extract data list functionality into HoC
  function renderLoadMore() {
    return null;

    if (data && data.count.aggregate.count > data.bills.length) {
      return (
        <LoadMore onClick={() => setLimit(limit + 10)}>
          <span>Load More</span>
        </LoadMore>
      );
    }
    return null;
  }

  function renderSearch() {
    if (props.hideControls) {
      return null;
    }

    return (
      <SearchInput
        debounce={500}
        placeholder={`"HB1225" or "plastic bags"`}
        onValue={setSearch}
        value={search || ""}
      />
    );
  }

  function renderPositionFilter() {
    if (props.hideControls) {
      return null;
    }

    return (
      <Select
        placeholder={
          isPerson ? "With or against Conservatives" : "Any position"
        }
        options={withOrAgainstOptions}
        width={210}
        onChange={setWithOrAgainst}
      />
    );
  }

  function renderLimit() {
    if (props.hideControls) {
      return null;
    }
    return (
      <SortLabel>
        Show:{" "}
        <Select
          options={limitOptions}
          value={limitOptions.find(
            (o) => o.value === props.limit || o.value === limit
          )}
          onChange={(o) => setLimit(o.value)}
          placeholder="5"
        />
      </SortLabel>
    );
  }

  return (
    <Wrapper>
      <TitleWrapper>
        <h2>
          {props.title || "Bills"}{" "}
          {!hideSeeAllLink && (
            <Link to={getBillsLink()}>See all bills &gt;</Link>
          )}
        </h2>

        <div>
          {renderLimit()}
          {renderSearch()}
          {renderPositionFilter()}
        </div>
      </TitleWrapper>

      <HeaderRow />

      <div>{results}</div>

      <Footer>{renderLimit()}</Footer>
    </Wrapper>
  );
}
