import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-grid-system";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

import mapRating from "../util/mapRating";

import { Link } from "react-router-dom";
import RatingBar from "./RatingBar";
import ScrollableContainer from "./ScrollableContainer";
import stateAbbrMap from "../util/stateAbbrMap";
import partyMap from "../util/partyMap";

import SupportsIcon from "../icons/supports";
import OpposesIcon from "../icons/opposes";

import colorScale from "../util/colorScale";
import Bill from "../pages/Bill";

const GET_BILL_QUERY = gql`
  query ($id: String!) {
    ratings_articles(where: { id: { _eq: $id } }) {
      id
      title
      description
      status
      acuVote
      state
      body
      year

      votes(where: { articleId: { _eq: $id } }) {
        peopleId
        value
        person {
          id
          name
          party
          role
        }
      }

      categorizations {
        categoryId
        category {
          name
        }
      }
    }
  }
`;

const BillOverviewWrapper = styled("div")`
  background: white;
  padding: 24px;

  h2 {
    margin-top: 0;
    margin-bottom: 8px;
    font-family: Roboto, sans-serif;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2d3436;
  }
`;

const OverviewWrapper = styled("div")`
  margin: 16px 0;
`;

const Description = styled("p")`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  color: #636e72;
`;

const Info = styled(Description)`
  font-style: italic;
`;

function getTitle(role) {
  switch (role) {
    case "Sen":
      return "Senator";
    case "Rep":
      return "Representative";
    default:
      return "";
  }
}

const BillIconWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  svg {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.5;
    /* or 18px */

    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;

    color: #636e72;
    margin: 0;
  }

  p:last-child {
    font-weight: normal;
  }
`;

function BillIcon({ bill: { acuVote, status } }) {
  if (acuVote > 0) {
    return (
      <BillIconWrapper>
        <SupportsIcon />
        <p>Conservatives Support</p>
        <p>{status}</p>
      </BillIconWrapper>
    );
  }

  if (acuVote < 0) {
    return (
      <BillIconWrapper>
        <OpposesIcon />
        <p>Conservatives Oppose</p>
        <p>{status}</p>
      </BillIconWrapper>
    );
  }

  return null;
}

const VotesByPartyWrapper = styled("div")`
  margin-top: 32px;

  h3 {
    /* font-weight: bold; */
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2d3436;
  }
  p,
  h3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    margin: 4px 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #636e72;
  }
`;

const VotesByPartyTable = styled("table")`
  width: 100%;
  border-spacing: 0;
  th,
  td {
    color: #2d3436;
    text-align: right;
    &:first-of-type {
      text-align: left;
    }
  }

  th {
    padding-bottom: 8px;
  }

  td {
    padding-bottom: 6px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }
`;

function VotesByParty({ votes }) {
  if (!votes) {
    return null;
  }

  const votesByParty = Object.values(
    votes.reduce((acc, vote) => {
      try {
        const { party } = vote.person;
        const { value } = vote;
        if (!acc[party]) {
          acc[party] = {
            party,
            inFavor: 0,
            opposed: 0,
            notVoting: 0,
          };
        }

        switch (value) {
          case 1:
            acc[party].inFavor++;
            break;
          case -1:
            acc[party].opposed++;
            break;
          default:
            acc[party].notVoting++;
            break;
        }
      } catch (e) {
        console.warn({
          action: "votesByParty",
          error: e.stack,
          vote,
        });
      }
      return acc;
    }, {})
  );

  return (
    <VotesByPartyWrapper>
      <h3>Roll Call Vote</h3>
      <VotesByPartyTable>
        <thead>
          <tr>
            <th>Party</th>
            <th>Yea</th>
            <th>Nay</th>
            <th>Not Voting</th>
          </tr>
        </thead>
        <tbody>
          {votesByParty.map((v) => (
            <tr>
              <td>{partyMap(v.party)}</td>
              <td>{v.inFavor}</td>
              <td>{v.opposed}</td>
              <td>{v.notVoting}</td>
            </tr>
          ))}
        </tbody>
      </VotesByPartyTable>
    </VotesByPartyWrapper>
  );

  return (
    <VotesByPartyWrapper>
      <h3>
        <span>Roll Call Vote</span> <span>(yea/nay/not voting)</span>
      </h3>
      {votesByParty.map((v) => (
        <p>
          <span>{partyMap(v.party)}</span>{" "}
          <span>
            {v.inFavor}/{v.opposed}/{v.notVoting}
          </span>
        </p>
      ))}
    </VotesByPartyWrapper>
  );
}

const RelatedIssuesWrapper = styled("div")`
  p {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    /* or 24px */

    color: #636e72;
  }
`;

function RelatedIssues({ bill }) {
  if (!bill || !bill.categorizations) {
    return null;
  }

  const issues = bill.categorizations
    .map((n) => n.categoryId)
    .filter((n) => n)
    .join("_");

  const categories = bill.categorizations.map((n) => {
    if (n.category) {
      return (
        <Link to={`/issues?multiselect=1&issues=${issues}`}>
          <p>{n.category.name}</p>
        </Link>
      );
    }
    console.warn({
      message: "Missing category",
      categorization: n,
      bill,
    });
  });

  return (
    <RelatedIssuesWrapper>
      <h3>Policy Area{categories.length === 1 ? "" : "s"}</h3>
      {categories}
    </RelatedIssuesWrapper>
  );
}

export default function BillOverview(props) {
  const { data, loading, error } = useQuery(GET_BILL_QUERY, {
    variables: {
      id: props.id,
    },
  });

  let bill = {};

  // TODO: Finish
  if (data) {
    bill = data.ratings_articles[0];
  }

  const body =
    bill.body === "H"
      ? "House of Representatives"
      : bill.body === "S"
      ? "Senate"
      : "";

  return (
    <BillOverviewWrapper>
      <Row justify="between">
        <Col md={6}>
          <h2>Vote Description</h2>
          <OverviewWrapper>
            <Description>{bill.description}</Description>
          </OverviewWrapper>
        </Col>

        <Col md={2}>
          <BillIcon bill={bill} />
        </Col>

        <Col md={4}>
          <RelatedIssues bill={bill} />
          <VotesByParty votes={bill.votes} />
        </Col>
      </Row>
    </BillOverviewWrapper>
  );
}
