import React from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';

import CheckboxImage from '../../images/checkbox.svg';
import ArrowImage from '../../images/arrow.svg';
import RatingLegendImage from '../../images/rating-legend.svg';
import StateMap from './StateMap';
import { Wrapper } from './Elements';
import { Row, Col, Container } from 'react-grid-system';
import RatingBar from './RatingBar';
import Link from '../components/Link';
import { Link as RouterLink } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import Loading from './Loading';
import { issuesByCategory } from '../data/getIssues';


const GET_ISSUES = gql`
fragment fields on ratings_categories {
  group
  
  stateIssueRatings_aggregate {
    aggregate {
      avg {
        rating
      }
      sum {
        acuVotes
        total
      }
    }
  }
}

query(
  $state: String
  $year: Int
) {

  ratings_categories(
    distinct_on: group
    where: {
      stateIssueRatings: {
        state: { _eq: $state },
        year: { _eq: $year }
      }
    }
    order_by: {
      group: asc,
      stateIssueRatings_aggregate: {
        avg: {
          rating: desc
        }
      }
    }
  ) {
    ...fields
  }
}
`;

// const GET_ISSUES = gql`
// fragment fields on ratings_categories {
//   id
//   name
//   group

//   stateIssueRatings_aggregate {
//     aggregate {
//       avg {
//         rating
//       }
//       sum {
//         count
//       }
//     }
//   }
// }

// query (
//   $limit: Int = 2,
//   $state: String
//   $year: Int
// ) {

//   top: ratings_categories(
//     where: {
//       stateIssueRatings: {
//         state: { _eq: $state },
//         year: { _eq: $year }
//       }
//     }
//     order_by: {
//       stateIssueRatings_aggregate: {
//         avg: {
//           total: desc
//         }
//       }
//     }
//     limit: $limit
//   ) {
//     ...fields
//   }

//   bottom: ratings_categories(
//     where: {
//       stateIssueRatings: {
//         state: { _eq: $state }
//         year: { _eq: $year }
//       }
//     }
//     order_by: {
//       stateIssueRatings_aggregate: {
//         avg: {
//           total: asc
//         }
//       }
//     }
//     limit: $limit
//   ) {
//     ...fields
//   }
// }
// `;


const IssuesToWatchWrapper = styled(Wrapper)`
  padding: 16px;
`;

const Inner = styled('div')`
  padding: 0;
`;

const Title = styled('h3')`
  margin-top: 0;
`;

const TitleRow = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  margin-bottom: 16px;

  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  a {
    font-size: 16px;
    color: #636E72;
    margin-right: 16px;
  }
`;

const IssueWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0;
  margin: 0 12px 16px 8px;

  &>div {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;

const IssueGroup = styled('p')``;
const IssueName = styled('p')`
  line-height: 1.334;
  margin: 0 8px 0 0;
`;

export default function IssuesToWatch(props) {

  const { limit = 3 } = props;

  const { data, loading, error } = useQuery(GET_ISSUES, {
    variables: {
      state: props.state || null,
      year: props.year || null,
    }
  });

  if (loading || error) {
    return (
      <IssuesToWatchWrapper>
        <TitleRow>
          <Title>Issues to watch</Title>
          <Link href="#/issues">See all issues &gt;</Link>
        </TitleRow>
        <Inner>
          {error ? <em>Couldn't find the issues. Please try again later.</em> : <Loading />}
        </Inner>
      </IssuesToWatchWrapper>
    );
  }

  function Issue(props) {
    return (
      <IssueWrapper>
        <RouterLink to={`/issues?group=${props.group}`}>
          <IssueName>
            {props.name}
          </IssueName>
        </RouterLink>
        <div>
          <RatingBar rating={fix(props.rating)} />
        </div>
      </IssueWrapper>
    );
  }

  function mapEntry({ group, stateIssueRatings_aggregate, }) {
    return {
      group,
      name: issuesByCategory[group].name,
      rating: stateIssueRatings_aggregate.aggregate.avg.rating,
      count: stateIssueRatings_aggregate.aggregate.sum.acuVotes,
      total: stateIssueRatings_aggregate.aggregate.sum.total,
    };
  }

  function renderIssues(data) {

    const issues = data.ratings_categories
      .map(mapEntry).sort((a, b) => b.total - a.total)
      .filter(i => i.rating < 100 && i.rating > 0);

    // const top = [] || issues.slice(0, limit).sort(sortIssueDesc).map(Issue);
    // issues.sort((a, b) => b.rating - a.rating);


    return pickMiddle(issues, limit)
      .sort(sortIssueDesc)
      .map(Issue);

    // const bottom = [] || issues.slice(-limit).sort(sortIssueDesc).map(Issue);
  }


  return (
    <IssuesToWatchWrapper>
      <TitleRow>
        <Title>Issues to watch</Title>
        <Link href="#/issues">See all issues &gt;</Link>
      </TitleRow>

      <Inner>
        {renderIssues(data)}
      </Inner>
    </IssuesToWatchWrapper>
  );
}

function fix(n) {
  if (typeof n === 'number') {
    return n.toFixed();
  }
  return '';
}

function sortIssueAsc(a, b) {
  return a.rating - b.rating;
}

function sortIssueDesc(a, b) {
  return b.rating - a.rating;
}

function pickMiddle(array, limit = 3) {

  // const prop = 'total';

  // const min = array[0][prop];
  // const max = array[array.length - 1][prop];
  // const half = (min + max) / 2;

  // const midpoint = array.reduce((acc, n, i) => {
  //   const diff = Math.abs(n[prop] - half);
  //   if (diff < acc.diff) {
  //     return { diff, i, };
  //   }
  //   return acc;
  // }, { diff: Infinity, i: -1 });

  // const start = midpoint.i - (limit / 2);
  // const end = midpoint.i + (limit / 2);


  // const selection = array.slice(start, end);

  // console.log({
  //   min,
  //   max,
  //   half,
  //   midpoint,
  //   start,
  //   end,
  //   array,
  //   selection
  // });

  // return selection


  const half = array.length / 2;
  const start = Math.max(0, Math.floor(half - limit / 2));
  const end = Math.min(array.length, Math.floor(half + limit / 2));
  return array.slice(start, end);
}