import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Container, Row, Col } from 'react-grid-system';

import { useQueryParam, NumberParam, StringParam, ArrayParam, JsonParam, DelimitedArrayParam, withDefault } from 'use-query-params';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import constants from '../../../constants.json';


import IssuesDetail from './IssueDetail';
// import IssueSelector from './IssueSelector';
import IssueSelector from '../../components/IssueSelector';
import getIssues from '../../data/getIssues';
import usePageTitle from '../../util/usePageTitle';
import FloatingIssueSelector from '../../components/FloatingIssueSelector';

const FullWidthContainer = styled("div")`
  /* max-width: 1600px !important; */
  max-width: 1600px !important;
  margin: -48px auto !important;
  /* max-width: 100% !important; */

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    // couldn't tell what this was for so left it
    overflow: hidden;
  }
`;

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

const height = `calc(100vh - 162px)`;

const Sidebar = styled("div")`
  display: none;
  max-height: ${height};
  overflow-y: auto;
  padding: 48px !important;
  width: 300px;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    display: block;
  }
`;

const Topbar = styled("div")`
  margin-top: 55px;
  font-weight: normal;
  font-size: 32px;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const Main = styled("div")`
  max-height: ${height};
  overflow-y: auto;
  width: 100%;
  margin-right: auto;
  max-width: 1200px;

  > div {
    max-width: 1200px;
  }

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    padding: 48px !important;
  }
`;

export default props => {

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    getIssues().then(setGroups);
  }, []);


  // const [group, setGroup] = useState(null);
  const [group, setGroup] = useQueryParam('group', StringParam);
  const [issues, setIssues] = useQueryParam('issues', withDefault(DelimitedArrayParam, []));

  // TODO: Add single issue to title if group selected
  usePageTitle('Issue Explorer');

  return (
    <FullWidthContainer>
      <ScrollToTopOnMount />

      <Topbar>
        <FloatingIssueSelector
          issues={issues}
          onIssuesSelected={setIssues}
          label={`${issues.length} selected policy area${issues.length > 1 ? 's' : ''}`}
          style={{ marginLeft: 8, marginBottom: 15 }}
        />
      </Topbar>

      <Row justify="between">
        <Sidebar width={300}>
          {/* TODO: Change this to support issue and group selection */}
          <IssueSelector
            issues={issues}
            onGroupSelected={(g) => (g ? setGroup(g.id) : setGroup(null))}
            onIssuesSelected={setIssues}
            group={group}
          />
        </Sidebar>
        <Main>
          <div>
            <IssuesDetail
              group={groups.find((g) => g.id === group)}
              issues={issues}
            />
          </div>
        </Main>
      </Row>
    </FullWidthContainer>
  );
};