import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import Select from 'react-select'
import RatingBar from '../../components/RatingBar';
import Rating from '../../components/Rating';
import ResultsBreakdown from '../../components/ResultsBreakdown';
import useStateRatings from '../../data/useStateRatings';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import Loading from '../../icons/loading';

const GET_STATE_ISSUE_RATINGS = gql`
  query getStateIssueRatings(
    $group: String
    $categories: [String!]
    $states: [String!]
    $year: Int
  ) {

    ratings: ratings_stateIssueRatings(where: {
      group: {
        _eq: $group
      }
      categoryId: {
        _in: $categories
      }
      state: {
        _in: $states
      }
      year: {
        _eq: $year
      }
    }) {
      group
      category
      total
      count: acuVotes
      year
      state
      rating
    }

    opposed: ratings_articles_aggregate(where: {
      categorizations: {
        category: {
          group: { _eq: $group }
          categoryId: { _in: $categories }
        }
      }
      year:{ _eq: $year }
      acuVote: { _eq: -1 }
      state: { _in: $states }
    }) {
      aggregate {
        count
      }
    }
    
    supported: ratings_articles_aggregate(where: {
      categorizations: {
        category: {
          group: { _eq: $group }
          categoryId: { _in: $categories }
        }        
      }
      year:{ _eq: $year }
      acuVote: { _eq: 1 }
      state: { _in: $states }
    }) {
      aggregate {
        count
      }
    }
  }
`;

const IssueOverviewWrapper = styled('div')`
  background: white;
  padding: 24px;
  height: ${props => props.height ? `${props.height}px` : 'auto'};

  ${Loading} {
    margin-top: 50px !important;
  }

  h2 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
    color: #2D3436;
  }

  strong {
    font-family: Roboto, sans-serif;
    font-weight: 700;
  }
`;

const Description = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  color: #636E72;
`;

const RatingOLD = styled('div')`

  margin: 0 24px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>p:first-child {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 1;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0;

    color: #CC4748;
  }
  
  &>p:last-child {
    margin: 8px 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    text-align: center;

    color: #636E72;
  }
`;

const ScrollableContainerWrapper = styled('div')`
  /* max-height: ${props => props.height}px; */
  /* overflow: scroll; */
  /* padding-bottom: 24px; */
  position: relative;
`;

const ScrollableContainerInner = styled('div')`
  max-height: ${props => props.height}px;
  overflow: scroll;
  padding-bottom: 36px;
  position: relative;
`;

const ScrollableContainerFade = styled('div')`
  background: linear-gradient(0deg,#FFFFFF 0%,rgba(0,121,255,0) 100%);
  width: 100%;
  height: 36px;
  display: block;
  position: absolute;
  content: ' ';
  bottom: 0;
  z-index: 2;
`;

function ScrollableContainer(props) {
  return (
    <ScrollableContainerWrapper>
      <ScrollableContainerInner {...props}>
        {props.children}
      </ScrollableContainerInner>
      <ScrollableContainerFade />
    </ScrollableContainerWrapper>
  )
}

const Center = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const Explanation = styled('p')`
  margin-bottom: 0;
  color: #B2BEC3;
  font-size: 14px;
`;


export default function IssueOverview(props) {

  const { group, year, states, issues } = props;

  const { data, loading, error } = useQuery(GET_STATE_ISSUE_RATINGS, {
    variables: {
      group: group ? group.id : null,
      categories: issues.length ? issues : null,
      year,
      states: states && states.length && states[0] ? states : null
    },
  });

  const stateRatings = useStateRatings({ year, issues, group: group ? group.id : null });

  if (loading) {
    return (
      <IssueOverviewWrapper height={186}>
        <h2>Overview</h2>
        <Row justify="between">
          <Col>
            <Center>
              <Loading />
            </Center>
          </Col>
        </Row>
      </IssueOverviewWrapper>
    );
  }



  const issue = {
    rating: calculateRating(stateRatings.data, states),
    // ratingChange: 2,
    subIssues: group ? group.subcategories.map(n => n.name) : [],
    ratingYear: year,
    supported: data.supported ? data.supported.aggregate.count : 'none',
    opposed: data.opposed ? data.opposed.aggregate.count : 'none',
  };

  function plural(n) {
    if (n === 1) {
      return '';
    }
    return 's';
  }

  let detail = 'Overall rating on selected issues';
  if (year) {
    detail = `${year} rating on selected issues`;
  }

  return (
    <IssueOverviewWrapper>
      <h2>Overview</h2>
      <Row justify="between">
        <Col lg={3}>

          <p>Conservative <strong>{issue.supported}</strong> bill{plural(issue.supported)}</p>
          <p>Liberal <strong>{issue.opposed}</strong> bill{plural(issue.opposed)}</p>

        </Col>
        <Col lg={2} md={6}>
          <Rating rating={issue.rating} detail={detail} />
        </Col>
        <Col lg={7} md={6}>
          <h3>Included sub-issues</h3>
          <ScrollableContainer height={80}>
            {issue.subIssues.map(n => <p key={n}>{n}</p>)}
          </ScrollableContainer>
        </Col>
      </Row>
      {(!issues || !issues.length) && <Explanation>To explore sub-issues in every category, use the multi-select tool on the left of the page.</Explanation>}
    </IssueOverviewWrapper>
  )
}

function calculateRating(ratings, states) {
  try {
    const all = !states || !states.length || (states.length === 1 && states[0] == null);

    if (!ratings) {
      return null;
    }

    let sum = 0;
    let count = 0;

    Object.values(ratings).forEach(n => {
      if (all || states.includes(n.state)) {
        sum += n.rating;
        count++;
      }
    });

    if (count) {
      return sum / count;
    }

    return null;
  } catch (e) {
    return null;
  }
}
