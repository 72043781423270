import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

import ExpandedTriangleImage from "../../images/expanded-triangle.svg";
import { Wrapper, Row } from "./Elements";

import ReactTooltip from "react-tooltip";

import Select from "./Select";
import RatingBar from "./RatingBar";
import PersonRow from "./PersonRow";
import PartySelector from './PartySelector';
import YearSelector from './YearSelector';
import LoadingBlock from "./LoadingBlock";
import { issuesByCategory } from "../data/getIssues";
import toPostgresArray from "../util/toPostgresArray";
import { cacheClient } from "../data/client";

export default function Leaderboard(props) {
  const { hideVoteDetails = false, year, setYear } = props;
  const [limit, setLimit] = useState(props.limit);
  const [party, setParty] = useState(null);

  const { data, loading, error } = getLeaderboardData({ ...props, limit, party });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  let top = [];
  let bottom = [];
  let count = 0;

  let leaderboard = (data?.leaderboard || []).map(p => ({ ...p }));

  if (leaderboard.length) {
    if (leaderboard && (props.group || (props.issues && props.issues))) {
      leaderboard.forEach((p) => {
        p.ratingDetail = props.year
          ? `${props.year} rating`
          : "All-time rating";
        p.year = year;
      });

      const middleIndex = getMiddle(leaderboard);

      top = leaderboard.slice(0, middleIndex);
      bottom = leaderboard.slice(middleIndex);
      count = leaderboard.length;
    } else if (data.best && data.worst) {
      count = data.count ? data.count.aggregate.count : 100000;
      top = data.best.map((p, i) =>
        mapPerson({ ...p, hideVoteDetails, year }, i + 1)
      );
      bottom = data.worst.map((p, i) =>
        mapPerson(
          { ...p, hideVoteDetails, year },
          count + i - data.worst.length
        )
      );
      bottom.reverse();
    }
  }

  const [expanded, setExpanded] = useState(true);
  const [sortBy, setSortBy] = useState(sortFields[0]);

  function renderInner() {
    if (!expanded) {
      return null;
    }

    if (loading) {
      return <LoadingBlock height={600} />;
    }

    if (!count) {
      return (
        <Inner>
          <p>No data for the selected region and year.</p>
        </Inner>
      );
    }

    if (error) {
      return (
        <Inner>
          <p>No data for the selected region and year.</p>
        </Inner>
      );
    }

    return (
      <Inner>
        <Subtitle>Top Rated</Subtitle>
        {top.map(n => <PersonRow {...n} key={n.peopleId} />)}

        <Subtitle>Lowest Rated</Subtitle>
        {bottom.map(n => <PersonRow {...n} key={n.peopleId} />)}
      </Inner>
    );
  }

  return (
    <LeaderboardWrapper>
      <TitleRow expanded={expanded}>
        <Row>
          <Title onClick={() => setExpanded(!expanded)}>
            <ExpandTriangle expanded={expanded} src={ExpandedTriangleImage} />{" "}
            {props.title || "Leaderboard"}
          </Title>
          <SeeFullLink to={getLeaderboardLink(props)}>
            See full leaderboard &gt;
          </SeeFullLink>
        </Row>
        <SortLabel>

          Show:{" "}
          <Select
            options={limitOptions}
            value={limitOptions.find(
              (o) => o.value === props.limit || o.value === limit
            )}
            onChange={(o) => setLimit(o.value)}
            placeholder="5"
          />
          <PartySelector value={party} onChange={setParty} />
          {setYear && <YearSelector onChange={setYear} value={year} />}
        </SortLabel>
      </TitleRow>

      {renderInner()}

      <SortLabel right>

        Show:{" "}
        <Select
          options={limitOptions}
          value={limitOptions.find(
            (o) => o.value === props.limit || o.value === limit
          )}
          onChange={(o) => setLimit(o.value)}
          placeholder="5"
        />
        <PartySelector value={party} onChange={setParty} />
        {setYear && <YearSelector onChange={setYear} value={year} />}
      </SortLabel>
    </LeaderboardWrapper>
  );

  // TODO: Add a disclaimer "Leaderboard displays lawmakers with 100 or more votes"
}

function getLeaderboardLink(props) {
  let params = ["state", "year"]
    .map((name) => (props[name] ? `${name}=${props[name]}` : null))
    .filter((n) => n)
    .join("&");

  if (props.state) {
    params += '&level=state';
  }

  if (props.year) {
    params += '&orderBy=rating';
  }

  return params ? `/people?${params}` : "/people";
}

// Find the index where the gap in ranking is greater than 1, which signifies the split between top and bottom
function getMiddle(people) {
  if (people.length === 0) {
    return 0;
  }

  // If the whole list is displayed, slice at the 50% mark for ratings
  if (people.length === people[people.length - 1].ranking) {
    for (let i = 0; i < people.length; i++) {
      if (!people[i + 1]) {
        if (i + 1 === people.length) {
          return people.length / 2;
        }
        return i;
      }

      // CUTOFF for "top rated", but only if the whole list is shown
      if (people[i].rating < 50) {
        return i;
      }
    }
  }

  for (let i = 0; i < people.length; i++) {
    if (!people[i + 1]) {
      if (i + 1 === people.length) {
        return people.length / 2;
      }
      return i;
    }

    if (people[i + 1].ranking > people[i].ranking + 1) {
      return i + 1;
    }
  }
}

// Styled components
const LeaderboardWrapper = styled(Wrapper)`
  padding: 16px;
`;

const Inner = styled("div")`
  padding: 0 16px 16px 16px;
`;

const Title = styled("h3")`
  margin-top: 0;
  margin-right: 16px;
`;

const TitleRow = styled(Row)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) => (props.expanded ? "0" : "4px")};
  padding: 8px 8px 0 8px;
  user-select: none;

  h3 {
    font-size: 20px;
    margin-bottom: 0;
  }

  a {
    font-size: 16px;
    color: #636e72;
  }
`;

const Subtitle = styled("h4")`
  text-transform: uppercase;
  color: #636e72;
  padding-bottom: 6px;
  border-bottom: 3px solid #f0f3f5;
  margin-top: 32px;
`;

const ExpandTriangle = styled("img")`
  margin-bottom: ${(props) => (props.expanded ? "3px" : "4px")};
  margin-right: 2px;
  ${(props) => (!props.expanded ? "transform: rotate(-90deg);" : "")};
`;

const SeeFullLink = styled(Link)`
  line-height: 24px;
`;

// TODO: Deduplicate
const SortLabel = styled("div")`
  z-index: 1000;
  display: ${p => p.right ? 'block' : 'inline-block'};
  ${p => p.right ? 'justify-content: flex-end;' : ''}
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    margin-left: 8px;
  }
`;

// Control options
const sortFields = [
  {
    name: "Last Name",
    value: "lastName",
  },
  {
    name: "Lifetime Rating",
    value: "lifetimeRating",
  },
  {
    name: "Current Rating",
    value: "rating",
  },
];

const limitOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "25", value: 25 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "250", value: 250 },
  { label: "500", value: 500 },
  { label: "All", value: 99999 },
];

// Process raw person data and annotate
function mapPerson(
  {
    id,
    name,
    state,
    party,
    role,
    rating,
    ratings,
    acuVotes,
    total,
    from,
    district,
    lifetimeRating,
    acuLifetimeRating,
    thumbnailUrl,
    imageUrl,
    hideVoteDetails,
    acuRatings,
    votes,
    year,
  },
  ranking
) {
  let ratingResult;
  let ratingDetail;

  if (acuRatings && acuRatings.length) {
    ratingResult = acuRatings[0].rating;
    ratingDetail = `${acuRatings[0].year} rating`;
  } else if (ratings) {
    ratingResult = ratings.aggregate.avg.rating;
    ratingDetail = `All-time rating`;
  } else {
    ratingResult = rating;
    ratingDetail = `All-time rating`;
  }

  let lifetimeRatingResult;

  if (lifetimeRating) {
    lifetimeRatingResult = lifetimeRating;
  } else if (acuLifetimeRating && acuLifetimeRating.length) {
    lifetimeRatingResult = acuLifetimeRating[0].rating;
  }

  return {
    id,
    name,
    role,
    federal: state === "US",
    state,
    party,
    from,
    district,
    rating: ratingResult,
    ratingDetail,
    lifetimeRating: lifetimeRatingResult,
    imageUrl,
    thumbnailUrl,
    ranking,
    hideVoteDetails,
    acuVotes,
    votes,
    year,
  };
}

// TODO: Add the originating state for federal
// const GET_PEOPLE_FOR_LEADERBOARD = gql`
// query(
//   $categories: _text
//   $year: Int
//   $states: _text
//   $limit: Int = 3
// ) {
//   leaderboard: ratings_getLeaderboardForIssues(args: {
//     categories: $categories
//     year: $year
//     states: $states
//     limit: $limit
//   }) {
//     peopleId
//     name
//     rating
//     lifetimeRating
//     imageUrl
//     state
//     from: "from"
//     party
// 		role
//     ranking
//     votes
//     acuVotes
//     thumbnailUrl
//   }
// }
// `;
const GET_PEOPLE_FOR_LEADERBOARD = gql`
  query ($categories: _text, $year: Int, $states: _text, $limit: Int = 3, $party: String) {
    leaderboard: ratings_getLeaderboardForIssues2(
      args: {
        categories: $categories
        year: $year
        states: $states
        limit: $limit
        party: $party
      }
    ) {
      peopleId
      name
      rating
      lifetimeRating
      imageUrl
      state
      from
      district
      party
      role
      ranking
      votes
      acuVotes
      thumbnailUrl
      __typename
    }
  }
`;

function getLeaderboardData({ group, issues, year, limit = 3, states, state, party, useCache }) {
  if (issues && !issues.length) {
    issues = null;
  }

  // For some reason, issues query is faster, so we're just using that for everything
  if (group) {
    issues = issuesByCategory[group].subcategories.map((n) => n.id);
  }

  const variables = {
    limit,
    categories: toPostgresArray(issues),
    year,
    party,
  };

  if (states && states.length && states[0]) {
    variables.states = toPostgresArray(states);
  } else if (state) {
    variables.states = toPostgresArray([state]);
  }


  return useQuery(GET_PEOPLE_FOR_LEADERBOARD, {
    variables,
    client: useCache ? cacheClient : null,
  });
}
