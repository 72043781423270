import React, { useDebugValue, useEffect, useState } from "react";
import styled from "styled-components";
import Search from "../icons/search";
import Cross from "../icons/cross";
import axios from "axios";

const Wrapper = styled("div")`
  position: relative;
  width: ${(props) => props.width || 300}px;

  svg {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: 10px;
    pointer-events: none;
  }
`;

const Input = styled("input")`
  padding: 9px 8px 7px 10px;
  border: 1px solid #bdbdbd;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #444444;
  width: 100%;
  box-sizing: border-box;
`;

const CrossIcon = styled(Cross)`
  height: 16px;
  width: 16px;
  padding: 11px;
  cursor: pointer;
  pointer-events: inherit !important;
  margin: 0 !important;
`;

// export default Input;

export default function ZipcodeLookup(props) {
  const {
    onChange = () => {},
    onResults = () => {},
    onLoading = () => {},
    onError = () => {},
  } = props;
  const [value, setValue] = useState(props.value || "");
  const [data, setData] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    clearTimeout(timeoutId);

    const timeout = setTimeout(() => {
      if (value && value.length === 5) {
        onLoading(true);

        // TODO: Move to config variable
        axios
          .get(
            `https://kvcfmpmz2l.execute-api.us-east-1.amazonaws.com/?code=${value}`
          )
          .then((r) => {
            setData(r.data);
            onResults(r.data || null);
            onLoading(false);
          })
          .catch((e) => {
            setData(e);
            onerror(e);
            onLoading(false);
          });
      } else {
        if (data) {
          onResults(null);
        }
      }
    }, 250);

    setTimeoutId(timeout);
  }, [value]);

  // useEffect(() => {
  //   if (value !== props.value) {
  //     setValue(props.value);
  //   }
  // }, [props.value])

  function clearInput() {
    setValue("");
    onResults(null);
    onChange("");
  }

  function update(e) {
    const val = e.target.value;
    if (!val || val.match(/^\d+$/)) {
      setValue(val);
      onChange(val);
    } else {
      // setValue(value);
    }
  }

  return (
    <Wrapper width={props.width} style={props.style} className={props.className} onChange={null}>
      <Input
        placeholder="Zip code"
        value={value}
        onChange={update}
        autoFocus={!!props.autoFocus}
      />
      {value ? <CrossIcon onClick={clearInput} fill="#BDBDBD" /> : null}
    </Wrapper>
  );
}
