import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery } from "@apollo/client";

import SearchInput from '../../components/SearchInput';
import ProfileImage from '../../components/ProfileImage';

import { getRole } from '../../util/person';
import formatDistrict from '../../util/formatDistrictName';


const ProfileImageNoMargin = styled(ProfileImage)`
  margin-left: 0;
`;

const SEARCH_PEOPLE_QUERY = gql`
query(
  $year: Int
  $search: String!
  $exclude: [String!]
) {
  results: ratings_people(limit: 20, where: {
    _or: [
      { name: { _ilike: $search } },
      { nickname: { _ilike: $search } },
    ],
    id: { _nin: $exclude }
  }, order_by: {
    lastName: asc_nulls_last
  }) {
    id
    name
    firstName
    lastName
    imageUrl
    thumbnailUrl
    state
    party
    role
    from

    history(limit: 1, where: { year: { _lte: $year } }, order_by: { year: desc_nulls_last }) {
      party
      chamber
      district
      state
      from
      year
    }

    acuLifetimeRatings(where: { rating: { _is_null: false } }, limit: 1, order_by: { year: desc_nulls_last }) {
      rating
      year
    }
  }
}
`;

const SelectRep = styled('div')`
  margin: 6px 6px 6px 5px;
  /* padding: 0 8px 0; */

  h3 {
    margin: 8px 0 16px;
  }

  position: relative;
  box-sizing: border-box;
`;

const SearchResults = styled('div')`
  margin-top: -1px;
  border: 1px solid #BDBDBD;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  z-index: 500;

max-height: ${props => props.height || 300}px;
  overflow-y: auto;
`;

const SearchResultWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  padding: 12px;
  cursor: pointer;
  ${props => props.active ? 'background-color: #deebff;' : ''}

  &:first-of-type {
    /* padding-bottom: 16px; */
    /* border-top: 1px solid #BDBDBD; */
  }

  &:last-of-type {
    /* padding-bottom: 16px; */
    /* border-bottom: 1px solid #BDBDBD; */
  }

  div {
    p {
      margin: 2px 0;
    }
    p:first-child {
      color: #2D3436;
    }
    p:last-child {
      color: #636E72;
    }
  }
`;

const UP_KEY = 38;
const DOWN_KEY = 40;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const ENTER_KEY = 13;

const ITEM_HEIGHT = 74;
const LIST_HEIGHT = ITEM_HEIGHT * 4 + 2;


function SearchResult(props) {

  const ref = useRef(null);

  // useEffect(() => {
  //   if (ref?.current && props.active) {
  //     ref.current.scrollIntoView({ block: 'nearest' });
  //   }
  // }, [ref, props.active]);
  const { history: [history] } = props;

  if (!history) {
    // Shadow profile
    return null;
  }

  const role = getRole(props);

  let chamber = '';
  try {
    chamber = history.chamber[0].toUpperCase() + history.chamber.slice(1);
  } catch (e) {
    chamber = '';
  }
  
  const district = formatDistrict(history.district, false);

  return (
    <SearchResultWrapper {...props} ref={ref}>
      <ProfileImageNoMargin id={props.id} src={props.thumbnailUrl} rating={props?.acuLifetimeRatings[0]?.rating} />
      <div>
        <p>{role}. {props.firstName} {props.lastName} ({history.party})</p>
        <p>{history.state} {chamber} {history.state === 'US' ? `– ${history.from} ` : ''}{district ? `– ${district}` : ''}</p>
      </div>
    </SearchResultWrapper>
  );
}



export default function PersonSelector(props) {

  const { onSelect = () => { } } = props;

  const [index, setIndex] = useState(null);
  const [focused, setFocused] = useState(false);

  function blur(e) {
    setTimeout(() => {
      // setFocused(false);
    }, 1);
  }

  function focus() {
    setFocused(true)
  }

  const loading = useRef(false);
  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const searchResults = useQuery(SEARCH_PEOPLE_QUERY, {
    variables: {
      search: search ? `%${search}%` : '',
      exclude: props.exclude || [],
    },
  });


  function select(person) {
    onSelect(person);
    setSearch('');
  }

  useEffect(() => {
    if (ref?.current) {
      
      loading.current = true;

      const scrollTo = index * ITEM_HEIGHT;
      const { scrollTop } = ref.current;
      
      if (scrollTo < scrollTop) {

        ref.current.scrollTo(0, scrollTo);

      } else if ((scrollTo + ITEM_HEIGHT) > (scrollTop + LIST_HEIGHT)) {

        if (scrollTo < (scrollTop + LIST_HEIGHT)) {
          ref.current.scrollTo(0, scrollTop + ITEM_HEIGHT - (scrollTop % ITEM_HEIGHT));
        } else {
          ref.current.scrollTo(0, scrollTo);
        }

      } else {
        loading.current = false;
      }

      setTimeout(() => loading.current = false, 100);
    }

  }, [index]);


  // Doesn't seem to be that useful (if the mouse is moving)
  useEffect(() => {
    if (ref?.current) {
      function onScroll(e) {
        e.stopPropagation();
      }
      ref.current.addEventListener('scroll', onScroll);
      return () => ref.current.removeEventListener('scroll', onScroll);
    }
  }, [ref]);

  function onKeyDown(e) {

    console.log(e.which)

    const length = searchResults?.data?.results?.length || 0;

    if (!length) {
      return setIndex(null);
    }

    if (e.which === UP_KEY) {

      if (index === 0 || index === null) {
        setIndex((length || 1) - 1);
      } else {
        setIndex(index - 1);
      }

    } else if (e.which === DOWN_KEY) {

      if (index >= length - 1 || index === null) {
        setIndex(0);
      } else {
        setIndex(index + 1);
      }

    } else if (e.which === ENTER_KEY) {
      select(searchResults.data.results[index]);

    } else if (e.which === RIGHT_KEY || e.which === LEFT_KEY) {

      // Don't prevent default
      return;

    } else {
      return setIndex(null);
    }

    e.preventDefault();
  }

  let results = null;
  if (focused && search && searchResults?.data?.results?.length) {
    results = (
      <SearchResults ref={ref} height={LIST_HEIGHT}>
        {searchResults.data.results.map((person, i) => <SearchResult key={person.id} onMouseMove={() => !loading.current && setIndex(i)} {...person} onClick={() => select(person)} onMouseDown={e => e.preventDefault()} active={index === i} />)}
      </SearchResults>
    );
  }
  
  return (
    <SelectRep>
      { props.prompt && <h3>{props.prompt}</h3>}
      <SearchInput width={props.embed ? '100%' : null} placeholder="Lawmaker name" value={search} onValue={setSearch} onKeyDown={onKeyDown} autoFocus={true} onBlur={blur} onFocus={focus}/>
      {results}
    </SelectRep>
  )
}
