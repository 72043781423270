import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import { VictoryChart, VictoryTheme, VictoryLine, VictoryAxis, VictoryTooltip } from 'victory';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import RatingGraph from '../../components/RatingGraph';
import StateMap from '../../components/StateMap';
import MapLegend from '../../components/MapLegend';


const GET_ISSUE_TREND = gql`
query issueTrend(
  $states: [String!],
  $group: String
  $categories: [String!]
) {
  ratings: ratings_stateIssueRatings(where: {
    state: { _in: $states }
    group: { _eq: $group }
    categoryId: { _in: $categories }
  }) {
    rating
    year
    acuVotes
    total
    category
    categoryId
  }
}
`;


const IssueStatsWrapper = styled('div')`
  background: white;
  padding: 24px;

  h2 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  h3 {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #2D3436;
  }
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
`;

const Disclaimer = styled('p')`
  font-size: 14px;
  color: #B2BEC3;
  /* margin-left: 16px; */
  text-align: center;
  margin-bottom: 32px;
`;

const RatingGraphWrapper = styled('div')`
  >div {
    height: auto;
  }
`;

export default function IssueStats(props) {

  const { group, issues, year, states } = props;

  const groupId = group ? group.id : null;

  const variables = {
    group: group && group.id,
    categories: issues.length ? issues : null,
  };

  if (states && states[0].value) {
    variables.states = states.map(s => s.abbr);
  }

  const { data, loading, error } = useQuery(GET_ISSUE_TREND, { variables });

  const ratingData = getTrendFromData(data);

  return (
    <IssueStatsWrapper>
      <h2>Ratings by state for selected issues</h2>
      <Row justify="between">
        <FlexCol lg={6}>
          <StateMap year={year} group={groupId} issues={issues} onSelect={props.onStateSelection} onAmendSelect={props.onAmendStateSelection} states={states} />
          <Disclaimer>"Conservative outcomes" are counted when a conservative bill is passed, or when a liberal bill is defeated</Disclaimer>
        </FlexCol>
        <Col lg={6}>
          <h3>Trend: Overall conservative score</h3>
          <RatingGraphWrapper>
            <RatingGraph height={220} data={ratingData} />
          </RatingGraphWrapper>
          <Disclaimer>Score based on selected issue(s) and year(s)</Disclaimer>
          <MapLegend/>
        </Col>
      </Row>
    </IssueStatsWrapper>
  )
}


function getTrendFromData(data) {

  if (!data) {
    return [];
  }

  return Object.values(data.ratings.reduce((acc, n) => {

    if (!acc[n.year]) {
      acc[n.year] = {
        year: n.year,
        count: 0,
        sum: 0,
      };
    }

    acc[n.year].sum += n.acuVotes;
    acc[n.year].count += n.total;

    return acc;

  }, {})).map(n => ({
    year: n.year,
    rating: 100 * n.sum / n.count,
    acuVotes: n.sum,
    total: n.count,
  }));
}