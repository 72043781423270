import React from 'react';
import styled from 'styled-components';
import Link from '../components/Link';

import LeftChevron from '../icons/left-chevron';


const BackLink = styled(Link)`
  color: #2D3436;
  font-size: 16px;
  display: inline-block;
  
  svg {
    margin-bottom: -1px;
    margin-right: 2px;
  }
`;

export default props => (
  <BackLink href={props.href || "#/" }>
    <LeftChevron fill="#2D3436" /> {props.children || 'Back'}
  </BackLink>
);