import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import colorScale from "../util/colorScale";
import borders from "../../images/borders";

const Border = styled("object")`
  ${inlineStyles}
  /* opacity: .334; */
  transition: opacity 500ms ease;
  opacity: ${(props) => (props.show ? 1 : 0)};
  /* visibility: ${(props) => (props.show ? "visible" : "hidden")}; */
`;

function inlineStyles(props) {
  if (props.inline) {
    return `
      width: auto;
      height: 48px;
      display: inline-block;
      margin-bottom: -10px;
      margin-left: 16px;
    `;
  }
  return `
    width: 100%;
    max-height: ${(props) => props.height || 300}px;
  `;
}


function BorderWrapper(props) {
  const [show, setShow] = useState(false);
  useEffect(setColor, [props.rating]);

  const inner = useRef(null);
  const innerRef = useCallback((node) => {
    inner.current = node;
    setTimeout(setColor);
    if (node !== null) {
      node.onload = setColor;
    }
  }, []);

  function setColor() {
    try {
      // Some states like Rhode Island have multiple, separate paths
      const paths = Array.from(
        inner.current.getSVGDocument().getElementsByTagName("path")
      );

      paths.forEach((p) => (p.style.transition = "all 300ms ease"));
      paths.forEach((p) => (p.style.fill = getFill(props.rating)));
      // path.style.opacity = 1;

      // Only show after we've managed to set the color
      setShow(true);
    } catch (e) {
      // No path
    }
  }

  return <Border ref={innerRef} {...props} show={show} />;
}

function getFill(rating) {
  return rating != null ? colorScale(rating) : "#B5B5B5";
}

export default (props) => (
  <BorderWrapper data={borders[props.state]} {...props} />
);
