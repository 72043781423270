import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';


export default (props) => (
  <Svg {...props} width="14" height="14" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9363 12.4387L7.4375 8.93987V4.25H9.5625V8.06013L12.4387 10.9363L10.9363 12.4387ZM8.5 0C6.24566 0 4.08365 0.895533 2.48959 2.48959C0.895533 4.08365 0 6.24566 0 8.5C0 10.7543 0.895533 12.9163 2.48959 14.5104C4.08365 16.1045 6.24566 17 8.5 17C10.7543 17 12.9163 16.1045 14.5104 14.5104C16.1045 12.9163 17 10.7543 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0V0ZM8.5 14.875C7.66282 14.875 6.83384 14.7101 6.06039 14.3897C5.28694 14.0694 4.58417 13.5998 3.99219 13.0078C3.40022 12.4158 2.93064 11.7131 2.61027 10.9396C2.28989 10.1662 2.125 9.33718 2.125 8.5C2.125 7.66282 2.28989 6.83384 2.61027 6.06039C2.93064 5.28694 3.40022 4.58417 3.99219 3.99219C4.58417 3.40022 5.28694 2.93064 6.06039 2.61027C6.83384 2.28989 7.66282 2.125 8.5 2.125C10.1908 2.125 11.8123 2.79665 13.0078 3.99219C14.2034 5.18774 14.875 6.80925 14.875 8.5C14.875 10.1908 14.2034 11.8123 13.0078 13.0078C11.8123 14.2034 10.1908 14.875 8.5 14.875Z" fill="#2D3436" />
  </Svg>
);
