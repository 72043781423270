import { useState, useEffect, useRef } from 'react';

export default function useDimensions() {
  const temp = useRef();
  const [dimensions, setDimensions] = useState({
    width: null,
    height: null,
  });

  useEffect(() => {

    function update() {

      if (!temp.current) {
        requestAnimationFrame(() => {
          setDimensions(temp.current);
          temp.current = null;
        });
      }

      temp.current = {
        width: window.outerWidth,
        height: window.outerHeight,
      };
    }

    setTimeout(update, 100);

    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);

  }, [setDimensions]);

  return dimensions;
}