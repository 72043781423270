import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import Select from 'react-select'
import stateList from '../../util/stateList.json';

import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import IssueOverview from './IssueOverview';
import IssueStats from './IssueStats';
import IssueLeaderboard from './IssueLeaderboard.js';
import IssueBills from './IssueBills';
import BillsList from '../../components/BillsList';
import YearSelector from '../../components/YearSelector';
import StateSelector from '../../components/StateSelector';
import StateMultiSelector from '../../components/StateMultiSelector';
import Help from '../../components/Help';
import Bill from '../Bill';
import Footer from '../../components/Footer';
import constants from '../../../constants.json';


const GET_STATE_ISSUE_RATINGS = gql`
  query(
    $group: String
    $categories: [String!]
    $states: [String!]
    $year: Int
  ) {
    ratings_stateIssueRatings(where: {
      group: {
        _eq: $group
      }
      category: {
        _in: $categories
      }
      state: {
        _in: $states
      }
      year: {
        _eq: $year
      }
    }) {
      group
      category
      total
      count
      year
      state
      rating
    }
  }
`;

stateList.forEach(state => {
  state.label = state.name;
});


const IssueOverviewWrapper = styled('div')`

  h1 {
    margin-top: 0;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    /* font-size: 48px; */
    font-size: 36px;
    line-height: 125%;
    color: #2D3436;
  }

  &>p {
    margin-top: 6px;
  }

  li {
    margin-bottom: 8px;
  }
`;

const Margin = styled('div')`
  /* margin-bottom: 40px; */
  margin-bottom: 24px;
  /* flex: 1; */
`;

const Separator = styled('div')`
  height: 24px;
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled('h1')`
  display: none;

  @media (min-width: ${constants.BREAKPOINTS.DESKTOP}px) {
    display: block;
  }
`;

export default function IssueDetail(props) {
  const { group, issues } = props;

  const currentYear = new Date().getFullYear();
  const defaultStateSelection = stateList[0];

  const [states, setStates] = useState([defaultStateSelection]);
  const [year, setYear] = useState();

  function selectState(abbr) {
    const state = stateList.find(s => s.abbr === abbr);
    setStates([state || defaultStateSelection]);
  }

  function amendSelectState(abbr) {
    const state = stateList.find(s => s.abbr === abbr);
    if (!states.includes(state)) {
      setStates([...states.filter(n => n.value), state]);
    }
  }

  const stateAbbrs = states.filter(s => s.value).map(s => s.abbr);

  if (!group && !issues.length) {

    return (
      <IssueOverviewWrapper>
        <h2>How to use the Issues page</h2>
        <ul>
          <li><strong>Explore</strong> policy trends within 19 categories by selecting a policy area.</li>
          <li><strong>Analyze</strong> how lawmakers voted across 186 issues within the multi-select tool.</li>
          <li><strong>Create</strong> your own scorecard from 1.4 million votes by selecting the issues most important to you with the multi-select tool.</li>
        </ul>
        {/* <p>Select a policy area to explore a major topics. Or, switch to multi-select and select sub-issues to explore specific issues from any category.</p> */}
        <Footer margin="calc(100vh - 255px) 0 0" />

      </IssueOverviewWrapper>
    )

  } else {

    const groupId = group ? group.id : null;

    return (
      <IssueOverviewWrapper>
        <Title>{group ? group.name : "Multiple sub-issues"}</Title>

        <div style={{ zIndex: 1000, position: "relative" }}>
          <Row align="end">
            <Col xs={9}>
              <Margin>
                <StateMultiSelector value={states} onChange={setStates} />
              </Margin>
            </Col>
            <FlexCol xs={3}>
              <Margin style={{ flex: 1 }}>
                <YearSelector fullWidth value={year} onChange={setYear} />
              </Margin>
              <Margin style={{ marginLeft: 24 }}>
                <Help>
                  Customize your own reports by selecting your own legislatures,
                  sub-issues, and time range.
                </Help>
              </Margin>
            </FlexCol>
          </Row>
        </div>

        <IssueOverview
          group={group}
          issues={issues}
          year={year}
          states={states ? states.map((n) => n.value) : null}
        />

        <Separator />

        <IssueStats
          group={group}
          issues={issues}
          states={states}
          year={year}
          onStateSelection={selectState}
          onAmendStateSelection={amendSelectState}
        />

        <Separator />

        <IssueLeaderboard
          group={groupId}
          issues={issues}
          states={stateAbbrs}
          year={year}
          hideSeeAllLink
          setYear={setYear}
        />

        <Separator />

        <BillsList
          states={stateAbbrs}
          year={year}
          group={groupId}
          issues={issues}
          hideControls={false}
          hideSeeAllLink
        />

        <Footer margin="24px 0 0" />
      </IssueOverviewWrapper>
    );

  }
}
