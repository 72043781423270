import React, { useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import ArrowImage from '../../images/arrow.svg';
import RatingLegendImage from '../../images/rating-legend.svg';
import StateMap from './StateMap';
import { Container, Col, Row } from 'react-grid-system';
import { colors } from '../util/colorScale';
import useDimensions from '../util/useDimensions';

import ZipcodeSelector from './ZipcodeSelector';

const LegendScale = styled('div')`
  margin-top: 8px;
  height: 16px;
  width: 100%;
  background: linear-gradient(90deg, ${colors.join(', ')});
`;

const MapInputLabel = styled('p')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
`;

const MapInput = styled('input')`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 8px;
  width: 200px;
`;

const Title = styled('h3')`
  margin-top: 0;
`;

const Legend = styled('div')`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  &>div {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      text-transform: uppercase;
      color: #636E72;
    }

    img {
      margin-top: 8px;
    }
  }
`;

const Disclaimer = styled('p')`
  color: #B2BEC3;
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 12px;
`;

const Wrapper = styled('div')`
  background: white;
  padding: 16px;
`;


export default withRouter(function MapWidget(props) {

  const dimensions = useDimensions();

  return (
    <Wrapper className="box-shadow">

      <Container>
        <Row>
          <Title>State Legislatures</Title>
        </Row>
        <Row>
          <Col>
            <StateMap {...props} />
          </Col>
        </Row>


        {/* TODO: Use MapLegend.js */}
        <Row align="center" >
          <Col md={dimensions.width > 1200 ? 7 : 12}>
            <Legend>
              <div>
                <span>Liberal</span>
                <span>Conservative</span>
              </div>
              <div>
                {/* <img src={RatingLegendImage} /> */}
                <LegendScale />
              </div>
            </Legend>
          </Col>

          { dimensions.width > 1200 && <Col md={5}>
            <MapInputLabel>View state details</MapInputLabel>
            <ZipcodeSelector onChange={state => props.history.push(`/states/${state}`)} />
          </Col>}
        </Row>

        <Row>
          <Col>
            <Disclaimer>*Map indicates an average of voting records of members within legislative bodies. "Conservative outcomes" are counted when a conservative bill is passed, or when a liberal bill is defeated.</Disclaimer>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
});
