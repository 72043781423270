module.exports = {
  "Awardees": [
    {"name": "Rep. Rick Allen", "link": "A000372", "photo": "IMG_0965.jpg"},
    {"name": "Rep. Kelly Armstrong", "link": "A000377", "photo": "IMG_0706.jpg"},
    {"name": "Rep. Jodey Arrington", "link": "A000375", "photo": "IMG_0628.jpg"},
    {"name": "Rep. Brian Babin", "link": "B001291", "photo": "IMG_0957.jpg"},
    {"name": "Rep. Jim Banks", "link": "B001299", "photo": "IMG_0895.jpg"},
    {"name": "Rep. Andy Barr", "link": "B001282", "photo": "IMG_0629.jpg"},
    {"name": "Rep. Andy Biggs", "link": "B001302", "photo": "IMG_0879.jpg"},
    {"name": "Rep. Dan Bishop", "link": "B001311", "photo": "IMG_0630.jpg"},
    {"name": "Sen. Marsha Blackburn", "link": "B001243", "photo": "IMG_0631.jpg"},
    {"name": "Rep. Kevin Brady", "link": "B000755", "photo": "IMG_0930.jpg"},
    {"name": "Sen. Mike Braun", "link": "B001310", "photo": "IMG_0876.jpg"},
    {"name": "Rep. Mo Brooks", "link": "B001274", "photo": "IMG_0647.jpg"},
    {"name": "Rep. Ken Buck", "link": "B001297", "photo": "IMG_0651.jpg"},
    {"name": "Rep. Ted Budd", "link": "B001305", "photo": "IMG_0825.jpg"},
    {"name": "Rep. Tim Burchett", "link": "B001309", "photo": "IMG_0632.jpg"},
    {"name": "Rep. Michael Burgess", "link": "B001248", "photo": "IMG_0633.jpg"},
    {"name": "Rep. Buddy Carter", "link": "C001103", "photo": "IMG_0634.jpg"},
    {"name": "Rep. Steve Chabot", "link": "C000266", "photo": "IMG_0834.jpg"},
    {"name": "Rep. Ben Cline", "link": "C001118", "photo": "IMG_0874.jpg"},
    {"name": "Rep. Michael Cloud", "link": "C001115", "photo": "IMG_0635.jpg"},
    {"name": "Rep. Rick Crawford", "link": "C001087", "photo": "IMG_0636.jpg"},
    {"name": "Rep. Warren Davidson", "link": "D000626", "photo": "IMG_0907.jpg"},
    {"name": "Rep. Scott DesJarlais", "link": "D000616", "photo": "IMG_0637.jpg"},
    {"name": "Rep. Ron Estes", "link": "E000298", "photo": "IMG_0882.jpg"},
    {"name": "Rep. Drew Ferguson", "link": "F000465", "photo": "IMG_0638.jpg"},
    {"name": "Rep. Virginia Foxx", "link": "F000450", "photo": "IMG_0857.jpg"},
    {"name": "Rep. Russ Fulcher", "link": "F000469", "photo": "IMG_0917.jpg"},
    {"name": "Rep. Bob Gibbs", "link": "G000563", "photo": "IMG_0880.jpg"},
    {"name": "Rep. Louie Gohmert", "link": "G000552", "photo": "IMG_0938.jpg"},
    {"name": "Rep. Lance Gooden", "link": "G000589", "photo": "IMG_0963.jpg"},
    {"name": "Rep. Morgan Griffith", "link": "G000568", "photo": "IMG_0639.jpg"},
    {"name": "Rep. Michael Guest", "link": "G000591", "photo": "IMG_0640.jpg"},
    {"name": "Rep. Jim Hagedorn", "link": "H001088", "photo": "IMG_0644.jpg"},
    {"name": "Rep. Kevin Hern", "link": "H001082", "photo": "IMG_0968.jpg"},
    {"name": "Rep. Jody Hice", "link": "H001071", "photo": "IMG_0641.jpg"},
    {"name": "Rep. Bill Huizenga", "link": "H001058", "photo": "IMG_0642.jpg"},
    {"name": "Rep. Dusty Johnson", "link": "J000301", "photo": "IMG_0745.jpg"},
    {"name": "Rep. Mike Johnson", "link": "J000299", "photo": "IMG_0885.jpg"},
    {"name": "Rep. Jim Jordan", "link": "J000289", "photo": "IMG_0852.jpg"},
    {"name": "Rep. John Joyce", "link": "J000302", "photo": "IMG_0767.jpg"},
    {"name": "Rep. Fred Keller", "link": "K000395", "photo": "IMG_0861.jpg"},
    {"name": "Rep. Trent Kelly", "link": "K000388", "photo": "IMG_0836.jpg"},
    {"name": "Rep. Mike Kelly", "link": "K000376", "photo": "IMG_0838.jpg"},
    {"name": "Rep. Darin LaHood", "link": "L000585", "photo": "IMG_0743.jpg"},
    {"name": "Rep. Doug LaMalfa", "link": "L000578", "photo": "IMG_0870.jpg"},
    {"name": "Rep. Doug Lamborn", "link": "L000564", "photo": "IMG_0886.jpg"},
    {"name": "Sen. James Lankford", "link": "L000575", "photo": "IMG_0848.jpg"},
    {"name": "Rep. Bob Latta", "link": "L000566", "photo": "IMG_0736.jpg"},
    {"name": "Sen. Mike Lee", "link": "L000577", "photo": "IMG_0892.jpg"},
    {"name": "Rep. Debbie Lesko", "link": "L000589", "photo": "IMG_0813.jpg"},
    {"name": "Rep. Barry Loudermilk", "link": "L000583", "photo": "IMG_0716.jpg"},
    {"name": "Rep. Thomas Massie", "link": "M001184", "photo": "IMG_0873.jpg"},
    {"name": "Leader Kevin McCarthy", "link": "M001165", "photo": "IMG_0714.jpg"},
    {"name": "Rep. Tom McClintock", "link": "M001177", "photo": "IMG_0867.jpg"},
    {"name": "Rep. Dan Meuser", "link": "M001204", "photo": "IMG_0799.jpg"},
    {"name": "Rep. Alex Mooney", "link": "M001195", "photo": "IMG_0750.jpg"},
    {"name": "Rep. Greg Murphy", "link": "M001210", "photo": "IMG_0726.jpg"},
    {"name": "Rep. Ralph Norman", "link": "N000190", "photo": "IMG_0830.jpg"},
    {"name": "Rep. Steven Palazzo", "link": "P000601", "photo": "IMG_0829.jpg"},
    {"name": "Rep. Gary Palmer", "link": "P000609", "photo": "IMG_0890.jpg"},
    {"name": "Rep. Greg Pence", "link": "P000615", "photo": "IMG_0752.jpg"},
    {"name": "Rep. Scott Perry", "link": "P000605", "photo": "IMG_0741.jpg"},
    {"name": "Rep. Tom Rice", "link": "R000597", "photo": "IMG_0734.jpg"},
    {"name": "Sen. Jim Risch", "link": "R000584", "photo": "IMG_0943.jpg"},
    {"name": "Rep. John Rose", "link": "R000612", "photo": "IMG_0722.jpg"},
    {"name": "Rep. David Rouzer", "link": "R000603", "photo": "IMG_0748.jpg"},
    {"name": "Whip Steve Scalise", "link": "S001176", "photo": "IMG_0788.jpg"},
    {"name": "Sen. Rick Scott", "link": "S001217", "photo": "IMG_0702.jpg"},
    {"name": "Rep. Jason Smith", "link": "S001195", "photo": "IMG_0969.jpg"},
    {"name": "Rep. Adrian Smith", "link": "S001172", "photo": "IMG_0626.jpg"},
    {"name": "Rep. Lloyd Smucker", "link": "S001199", "photo": "IMG_0805.jpg"},
    {"name": "Rep. Bryan Steil", "link": "S001213", "photo": "IMG_0704.jpg"},
    {"name": "Rep. Glenn Thompson", "link": "T000467", "photo": "IMG_0898.jpg"},
    {"name": "Rep. Tim Walberg", "link": "W000798", "photo": "IMG_0709.jpg"},
    {"name": "Rep. Randy Weber", "link": "W000814", "photo": "IMG_0801.jpg"},
    {"name": "Rep. Daniel Webster", "link": "W000806", "photo": "IMG_0816.jpg"},
    {"name": "Rep. Brad Wenstrup", "link": "W000815", "photo": "IMG_0913.jpg"},
    {"name": "Rep. Bruce Westerman", "link": "W000821", "photo": "IMG_0780.jpg"},
    {"name": "Rep. Roger Williams", "link": "W000816", "photo": "IMG_0763.jpg"},
    {"name": "Rep. Joe Wilson", "link": "W000795", "photo": "IMG_0809.jpg"},
    {"name": "Rep. Rob Wittman", "link": "W000804", "photo": "IMG_0711.jpg"}    
  ],
  "Freshmen": [
    {"name": "Rep. Jerry Carl", "photo": "IMG_0919.jpg"},
    {"name": "Rep. Robert Good", "photo": "IMG_0955.jpg"},
    {"name": "Rep. Diana Harshbarger", "photo": "IMG_0822.jpg"},
    {"name": "Rep. Ronny Jackson", "photo": "IMG_0923.jpg"},
    {"name": "Rep. Julia Letlow", "photo": "IMG_0720.jpg"},
    {"name": "Rep. Tracey Mann", "photo": "IMG_0896.jpg"},
    {"name": "Rep. Barry Moore", "photo": "IMG_0846.jpg"}
  ]
};