import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const AnchorLink = styled('a')`
  color: #636E72;
  text-decoration: none;
  font-size: 14px;
`;

const LinkLink = styled(Link)`
  color: #636E72;
  text-decoration: none;
  font-size: 14px;
`;


export default (props) => {
  if (props.to) {
    return <LinkLink {...props} />;
  }
  return <AnchorLink {...props} />;
}