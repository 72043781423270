import { useState, useEffect } from 'react';
import axios from 'axios';


// Fetch the keys from 
async function getPDFList() {
  const { data: listXml } = await axios({
    method: 'GET',
    url: `http://acu-ratings-pdf-previews.s3.amazonaws.com`,
    // url: `${process.env.CACHE_HOST}/pdf-preview`,
    headers: {
      'content-type': 'application/json'
    }
  });

  const newestByState = {};

  const keys = listXml.match(/\<Key\>([^<]+)\<\/Key\>/g)
    .reduce((acc, keyEl) => {
      try {
        const key = keyEl.match(/\<Key\>([^<]+)\<\/Key\>/)[1];

        const [, state] = key.match(/[^A-Z]([A-Z]{2})[^A-Z]/);
        const [, year] = key.match(/\D(\d{4})\D/);

        acc[`${state}:${year}`] = key;

        newestByState[state] = Math.max(year, newestByState[state] || 0);

        return acc;

      } catch (e) {

      }
      return acc;
    }, {});

  for (const state in newestByState) {
    const year = newestByState[state];
    keys[`${state}`] = keys[`${state}:${year}`];
  }

  return keys;
}

const pdfPreviewList = getPDFList();


export default function usePDFPreviewUrl(state, year) {
  const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
  useEffect(() => {
    let canceled = false;
    pdfPreviewList.then(list => {
      if (canceled) return;
      const key = year ? list[`${state}:${year}`] : list[state];
      if (key) {
        // TODO: Make a config variable
        setPdfPreviewUrl(`https://acu-ratings-pdf-previews.s3.amazonaws.com/${key}`);
      } else {
        setPdfPreviewUrl(null);
      }
    });
    return () => canceled = true;
  }, [pdfPreviewList, state, year]);

  return pdfPreviewUrl;
}