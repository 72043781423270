import stateList from './stateList.json';

const map = {};

for (const state of stateList) {
  map[state.abbr] = state.name;
}

// TODO: Get from history, not from person
export default function getState(props) {
  const { state, role, body, from } = props;

  const fromText = from ? ' - ' + from : '';

  if (props.state === 'US') {
    if (role) {


      if (role === 'Sen') {
        return 'US Senate' + fromText;
      } else if (role === 'Rep') {
        return 'US House' + fromText;
      }
    } else if (body) {
      if (body === 'S') {
        return 'US Senate' + fromText;
      } else if (body === 'H') {
        return 'US House' + fromText;
      }
    }
  }

  return map[state] ? map[state] : state;
};
