import AL from './al.svg';
import AK from './ak.svg';
import AS from './as.svg';
import AZ from './az.svg';
import AR from './ar.svg';
import CA from './ca.svg';
import CO from './co.svg';
import CT from './ct.svg';
import DE from './de.svg';
import DC from './dc.svg';
import FL from './fl.svg';
import GA from './ga.svg';
import GU from './gu.svg';
import HI from './hi.svg';
import ID from './id.svg';
import IL from './il.svg';
import IN from './in.svg';
import IA from './ia.svg';
import KS from './ks.svg';
import KY from './ky.svg';
import LA from './la.svg';
import ME from './me.svg';
import MD from './md.svg';
// import MH from './mh.svg';
import MA from './ma.svg';
import MI from './mi.svg';
// import FM from './fm.svg';
import MN from './mn.svg';
import MS from './ms.svg';
import MO from './mo.svg';
import MT from './mt.svg';
import NE from './ne.svg';
import NV from './nv.svg';
import NH from './nh.svg';
import NJ from './nj.svg';
import NM from './nm.svg';
import NY from './ny.svg';
import NC from './nc.svg';
import ND from './nd.svg';
// import MP from './mp.svg';
import OH from './oh.svg';
import OK from './ok.svg';
import OR from './or.svg';
// import PW from './pw.svg';
import PA from './pa.svg';
import PR from './pr.svg';
import RI from './ri.svg';
import SC from './sc.svg';
import SD from './sd.svg';
import TN from './tn.svg';
import TX from './tx.svg';
import UT from './ut.svg';
import US from './us.svg';
import VT from './vt.svg';
import VA from './va.svg';
import VI from './vi.svg';
import WA from './wa.svg';
import WV from './wv.svg';
import WI from './wi.svg';
import WY from './wy.svg';

export default {
  AL,
  AK,
  AS,
  AZ,
  AR,
  CA,
  CO,
  CT,
  DE,
  DC,
  FL,
  GA,
  GU,
  HI,
  ID,
  IL,
  IN,
  IA,
  KS,
  KY,
  LA,
  ME,
  MD,
  // MH,
  MA,
  MI,
  // FM,
  MN,
  MS,
  MO,
  MT,
  NE,
  NV,
  NH,
  NJ,
  NM,
  NY,
  NC,
  ND,
  // MP,
  OH,
  OK,
  OR,
  // PW,
  PA,
  PR,
  RI,
  SC,
  SD,
  TN,
  TX,
  UT,
  US,
  VT,
  VA,
  VI,
  WA,
  WV,
  WI,
  WY,
};
