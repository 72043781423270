import React from 'react';
import styled from 'styled-components';
import color from '../util/colorScale';


const RatingsBarWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;

  &>div {

    background-color: #DFE6E9;
    height: 15px;
    width: 110px;
    margin-right: ${props => props.hideNumber ? 0 : '8px'};

    &>div {
      height: 15px;
      width: ${props => Math.min(props.rating * 1.1, 100)}%;
      background-color: ${props => color(props.rating)};

    }
  }

  p {
    color: #2D3436;
    width: ${props => props.decimal ? 40 : 25}px;
    text-align: right;
    margin: 0;
  }

`;

export default function RatingBar({ rating, hideNumber=false, className='', showAsterisk, decimal=false }) {
  return (
    <RatingsBarWrapper rating={rating} className={className} hideNumber={hideNumber} decimal={decimal}>
      <div>
        <div />
      </div>
      { !hideNumber && <p>{rating}{showAsterisk ? '*' : ''}</p> }
    </RatingsBarWrapper>
  )
}
