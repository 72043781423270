import React from 'react';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer';
import usePageTitle from '../../util/usePageTitle';
import Members from './members.json';
import Styles from './styles';

const thisPhoto = (photo) => {
  if (photo) {
    const photoUrl = `https://images.conservative.org/foundation/cla/2021/ratings-event/${photo}`;
    return photoUrl;
  }
  return 'https://theprobatepro.com/wp-content/uploads/2015/02/FACEHOLDER.jpeg';
};

const Awards = (props) => {
  usePageTitle('Awards');
  const { Awardees, Freshmen } = Members;
  return (
    <Styles.Wrapper>
      <div className="inner">
        <h1>2021 Congressional Awards Reception</h1>
        <h3>Award Recipients</h3>
        <Row>
          {Awardees.map((awardee) => (
            <Col sm={4} key={awardee.link}>
              <Styles.AwardPhoto>
                <a href={`http://ratings.conservative.org/people/${awardee.link}`} target="_blank" rel="noopener noreferrer">
                  <Styles.ImageContainer src={thisPhoto(awardee.photo)} />
                  {awardee.name}
                </a>
              </Styles.AwardPhoto>
            </Col>
          ))}
        </Row>
        <h3>Freshmen</h3>
        <Row>
          {Freshmen.map((awardee) => (
            <Col sm={4} key={awardee.link}>
              <Styles.AwardPhoto>
                <Styles.ImageContainer src={thisPhoto(awardee.photo)} />
                {awardee.name}
              </Styles.AwardPhoto>
            </Col>
          ))}
        </Row>
      </div>
      <Footer  />
    </Styles.Wrapper >
  )
};

export default Awards;
