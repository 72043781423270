import React from 'react';
import styled from 'styled-components';
import Svg from '../components/Svg';

const RotatableSvg = styled(Svg)`
  transform: rotate(${props => props.rotate || 0}deg);
`;

export default (props) => (
  <RotatableSvg  width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0L8 8L16 0" fill="#BDBDBD"/>
  </RotatableSvg>
);
