import React from 'react';
import styled from 'styled-components';

export const Container = styled('div')`
  /* background-color: #F7F9FA; */
`;

export const Content = styled('div')`
  max-width: 1440;
  margin: auto;
`;

export const Card = styled('div')`
  background-color: white;
  padding: 16px;
`;

export const Wrapper = styled('div')`
  padding: 16px;
  background-color: white;
`;


export const Row = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const Cell = styled('div')`
  flex: ${props => typeof props.flex === 'number' ? props.flex : 1};
  margin: 16px;
  /* display: flex; */
  
  display: ${props => {

    return props.centerVertically ? 'flex' : '';
  }};
  flex-direction: ${props => props.centerVertically ? 'column' : ''};
  justify-content: ${props => props.centerVertically ? 'center' : ''};
`;
