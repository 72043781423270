import React, { useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';


const Wrapper = styled(Select)`
  width: ${props => props.width || 100}px;

  > div {
    border-radius: 0;
    border-color: #BDBDBD;
  }
`;

export default Wrapper;
