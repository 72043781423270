import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';


const Wrapper = styled(Select)`
  width: 180px;
  margin-right: 16px;

  > div {
    border-radius: 0;
    border-color: #BDBDBD;
  }
  
  div {
    border-color: #BDBDBD;
  }
`;


function last(arry) {
  return arry[arry.length - 1];
}

function getInitialOption(value) {
  if (typeof value === 'string') {
    return options.find(s => s.value === value) || options[0];
  }
  return options[0];
}

const options = [{
  label: 'State & Federal',
  value: 'both',
},{
  label: 'State',
  value: 'state',
},{
  label: 'Federal',
  value: 'federal',
}];


// TODO: Handle value from props
export default function FederalSelector(props) {

  const [state, setState] = useState(getInitialOption(props.value));

  useEffect(() => {
    if (props.value !== state) {
      setState(getInitialOption(props.value));
    }
  }, [props.value])

  function onChange(option) {

    const {value} = (option || options[0]);

    if (props.onChange) {
      props.onChange(value);
    }
    setState(option || options[0]);
  }

  return (
    <Wrapper
      {...props}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      options={options}
      simpleValue
      clearable={false}
      name="is-federal"
      value={state}
      onChange={onChange}
      disabled={true}
    />
  );
}
