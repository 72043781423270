import React from 'react'
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';

import Styles from './styles';

const Alert = ({ noBorderRadius }) => {
  return (
    <Row>
      <Col>
        <Styles.Alert noBorderRadius={noBorderRadius}>
          Support our work to defend conservative champions!{' '}
          <a href="https://secure.foundation.conservative.org/ratings" target="_blank" rel="noopener noreferrer">Donate Today</a>.
        </Styles.Alert>
      </Col>
    </Row>
  );
};

Alert.defaultProps = {
  noBorderRadius: false,
};

Alert.propTypes = {
  noBorderRadius: PropTypes.bool,
};

export default Alert;
